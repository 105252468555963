import React, { useState } from 'react'
import InputFormGroup from '../../components/Form/InputFormGroup'
import GeneralServices from '../../services/GeneralServices'

function WalletPage() {

    const [paymentData, setPaymentData] = useState({
        amount: '',
        name: '',
        number: '',
        cvc: '',
        month: '',
        year: '',
    })

    const [validation, setValidation] = useState([])

    const service = new GeneralServices;


    const handelPayment = async () => {
        setValidation([])
        try {
            const res = await service.post('wallet/topup', paymentData)
            console.log('res', res)
        } catch (error) {
            if (error.validationData) {
                setValidation(error.validationData)
            }
        }
    }




    return (
        <div>
            <div className='m-2 rounded shadow'>
                <p className='text-3xl font-semibold'>شحن المحفظه</p>

                <div className='h-[1px] bg-gray-100 my-3' />


                <InputFormGroup
                    placeholder='المبلغ'
                    value={paymentData.amount}
                    onChange={(e) => setPaymentData({ ...paymentData, amount: e.target.value })}
                    col={12}
                    error={validation?.amount}
                />

                <hr className='my-2' />

                <div className='row'>
                    <div className='col-md-6 p-1'>
                        <InputFormGroup
                            placeholder='الاسم'
                            value={paymentData.name}
                            onChange={(e) => setPaymentData({ ...paymentData, name: e.target.value })}
                            col={12}
                            error={validation?.name}
                        />
                    </div>

                    <div className='col-md-6 p-1'>
                        <InputFormGroup
                            placeholder='الرقم'
                            type='number'
                            value={paymentData.number}
                            onChange={(e) => setPaymentData({ ...paymentData, number: e.target.value })}
                            col={12}
                            error={validation?.number}
                        />
                    </div>
                    <div className='col p-1'>
                        <InputFormGroup
                            placeholder='cvc'
                            value={paymentData.cvc}
                            onChange={(e) => setPaymentData({ ...paymentData, cvc: e.target.value })}
                            col={12}
                            error={validation?.cvc}
                        />
                    </div>
                    <div className='col p-1'>
                        <InputFormGroup
                            placeholder='الشهر'
                            type='number'
                            value={paymentData.month}
                            onChange={(e) => setPaymentData({ ...paymentData, month: e.target.value })}
                            col={12}
                            error={validation?.month}
                        />
                    </div>
                    <div className='col p-1'>
                        <InputFormGroup
                            placeholder='السنه'
                            type='number'
                            value={paymentData.year}
                            onChange={(e) => setPaymentData({ ...paymentData, year: e.target.value })}
                            col={12}
                            error={validation?.year}
                        />
                    </div>
                </div>


                <hr className='my-2' />

                <button onClick={handelPayment} className='p-[10px] rounded bg-rose-500 text-white m-2'>شحن</button>
            </div>
        </div >
    )
}

export default WalletPage