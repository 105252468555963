import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import GeneralServices from "../../services/GeneralServices";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContextProvider";
import ProductHome from "./components/ProductHome";
import ProductHomeLg from "./components/ProductHomeLg";
import { showSuccessToast } from "../../utils/toastUtils";
import SlideshowAhmed from "../../components/slide/SlideshowAHmed";
import ProductComponent from "../List/components/ProductComponent";
import TabbyPromo from "../../components/TabbyPromo";

function HomeDesktop() {
  useEffect(() => {
    document.title = "سهول - الرئيسية";
  }, []);

  // return <Mobile />
  return (
    <div>
      {/* catogeris */}
      <Categories />

      {/* products */}
      <FeaturedProducts />

      <MostSalsProducts />

      <div
        class="w-full h-[600px] "
        style={{
          backgroundImage: "url('/imges/slide.webp')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      {/* about */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img src="imges/about.png" />
          </div>
          <div className="col-md-6 col-sm-12 py-2 ">
            <h1 className="font-bold">عن لحمه سهول</h1>
            <h1 className="md:text-7xl sm:text-xl font-bold my-3">
              نحن ننتج اجود اللحوم المتخاره لك
            </h1>

            <p className="font-medium">
              افضل امنواع الحوم المتواجد و جميع انواع اللحوم التي تحتاجة مثل لحم
              الغذال و البقر و الجمال
            </p>

            <div className="my-6">
              <ul className=" space-y-2">
                <li>
                  <div className="flex">
                    <img src="icons/shield-tick.png" />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex">
                    <img src="icons/shield-tick.png" />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex">
                    <img src="icons/shield-tick.png" />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
              </ul>
            </div>
            <button className="py-2 border bg-primaryColor text-white rounded px-8">
              <span>شاهد اكثر</span>
            </button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-6"></div>
        </div>
      </div>

      {/* most sals */}

      <div className="container mt-4">
        <div className="row justify-between">
          <div className="col-md-6 col-sm-12 row justify-between row">
            <img className="col-3" src="imges/video-list.png" />
            <img className="col-9" src="imges/video.png" />
          </div>
          <div className="col-md-6 col-sm-12 py-2">
            <h1>فيديوهات سهول</h1>
            <h1 className="md:text-7xl sm:text-2xl font-bold my-2">
              نعرض لك طريقة الذبح وشكل اللحوم
            </h1>

            <p className="font-medium">
              افضل طريقة للذبح الحلال و اجود طريقة لتغليف المنتجات التي تصل لك و
              اجود خدامات التوصيل
            </p>

            <div className="my-6">
              <ul className=" space-y-2">
                <li>
                  <div className="flex">
                    <img src="icons/shield-tick.png" />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex">
                    <img src="icons/shield-tick.png" />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
                <li>
                  <div className="flex">
                    <img src="icons/shield-tick.png" />
                    <span className="mx-1">لحم طازج 100%</span>
                  </div>
                </li>
              </ul>
            </div>
            <button className="py-2 border bg-primaryColor text-white rounded px-8">
              <span>شاهد اكثر</span>
            </button>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundImage: "imges/contact-bg.png" }}
        className="w-full"
      >
        <img src="imges/contact-bg.png" className="w-full" />
      </div>
    </div>
  );
}

const FeaturedProducts = () => {
  const [products, setProducts] = useState([]);

  const service = new GeneralServices();

  const fetchProducts = async () => {
    try {
      const { data } = await service.get("products");
      setProducts(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="my-4 container">
      <div className="row justify-between container">
        <h1 className="text-3xl my-2 text-bold">المنتجات</h1>
        <Link to="list" className="row items-center">
          <h1 className="text-primaryColor">شاهد اكثر</h1>
          <img className="h-8" src="icons/more.png" />
        </Link>
      </div>
      <div className="row">
        {products && (
          <>
            <div className="row col-md-6 col-sm-12">
              {products[0] && (
                <ProductComponent
                  key={products[1].id}
                  col={`col-6 col-md-6 col-sm-6 col-md-6 xl:col-sx-6`}
                  data={products[0]}
                />
              )}
              {products[1] && (
                <ProductComponent
                  key={products[2].id}
                  col={`col-6 col-md-6 col-sm-6 col-sx-6`}
                  data={products[1]}
                />
              )}
              {products[2] && (
                <ProductComponent
                  key={products[3].id}
                  col={`col-6 col-md-6 col-sm-6 col-sx-6`}
                  data={products[2]}
                />
              )}
              {products[3] && (
                <ProductComponent
                  key={products[0].id}
                  col={`col-6 col-md-6 col-sm-6 col-sx-6`}
                  data={products[3]}
                />
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {products[4] && <ProductHomeLg data={products[4]} />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const MostSalsProducts = () => {
  const numberOfProducts = 6;

  const [products, setProducts] = useState([]);

  const service = new GeneralServices();

  const fetchProducts = async () => {
    try {
      const { data } = await service.get("products");

      setProducts(data);
    } catch (error) {
      console.log("res", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div style={{ backgroundColor: "rgba(1,1,1,0.8)" }} className="w-full py-3">
      <div className="container">
        <h1 className="text-bold text-2xl text-white">الاكثر مبيعا</h1>
      </div>

      <div className="row overflow-x-scroll scroll-smooth scrollbar-thin scrollbar-hidden justify-evenly p-2">
        {products.map((product, index) => {
          if (index > 4) return;
          return <MostSalsProduct data={product} key={index} />;
        })}
      </div>
      <div className="row justify-center mt-3">
        <img src="icons/right-arrow.png" />
        <img src="icons/left-arrow.png" />
      </div>
    </div>
  );
};
const MostSalsProduct = ({ data }) => {
  const { card, setCard } = useContext(AuthContext);

  const handleAddToCard = () => {
    const isProductExist =
      card.products && card.products.some((product) => product.id === data.id);

    if (isProductExist) {
      const updatedProducts = card.products.map((product) =>
        product.id === data.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    } else {
      const updatedCard = {
        ...card,
        products: card.products
          ? [...card.products, { ...data, quantity: 1 }]
          : [{ ...data, quantity: 1 }],
      };
      setCard(updatedCard);
    }

    // show toast
    showSuccessToast("تمت اضافة المنتج");
  };

  // check if prodect in

  return (
    <div
      className="col-md-2 col-ms-12 sm:m-2  relative rounded-lg overflow-hidden ml-2 mt-3"
      style={{ backgroundColor: "rgba(225,225,225,0.1)" }}
    >
      <div className="bg-rose-500 max-w-fit p-1 absolute left-0 rounded-br-lg text-white block">
        {" "}
        {data.label}
      </div>
      <div className="justify-center px-1 py-2 flex w-full">
        <img
          className="w-full h-72  rounded-lg object-cover"
          // src="https://www.eatthis.com/wp-content/uploads/sites/4/2023/05/MEAT.jpg"
          src={data?.image ?? "/imges/slide-4.jpg"}
          alt={data.name}
        />
      </div>

      <div className="text-white container">
        <Link to={`/products-details/${data.id}`}>
          <h className="text-[20px] text-bold">{data.name}</h>
        </Link>
        {data.description
          .split("\n")
          .slice(0, 2)
          .join("\n")
          .split("\n")
          .map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        <h className="text-bold text-[20px]">
          {((data.price || 0) * 1.15).toFixed(0)}
          ريال
        </h>
        <div className="row justify-between p-2">
          <div
            onClick={() => handleAddToCard(data)}
            className=" p-1 rounded flex items-center cursor-pointer bg-rose-500 text-base text-white"
          >
            <img
              width="25"
              height="25"
              className="mx-1"
              src="https://img.icons8.com/ios-filled/50/ffffff/add-shopping-cart--v1.png"
            />
            <span> اضافة للسله</span>
          </div>
          <div className="row items-center justify-center">
            <span className="font-semibold mt-1 mx-1">{data.rating}</span>
            <img src="icons/star_rating.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

const Categories = () => {
  const [categories, setCategories] = useState([]);

  const service = new GeneralServices();

  const fetchCategories = async () => {
    try {
      const data = await service.get("categories");
      setCategories(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="items-center overflow-x-scroll flex container mt-5 p-2">
      {categories &&
        categories.map((category, index) => (
          <Category data={category} key={index} />
        ))}
    </div>
  );
};
const Category = ({ data }) => {
  return (
    <Link
      to={`list`}
      className="bg-gray-400  h-52  rounded  mx-2"
      // return <Link to={`catogery/${data.id}`} className='bg-gray-400  h-52  rounded  mx-2'
      style={{
        backgroundImage: `url('${data.image}')`,
        backgroundSize: "cover",
      }}
    >
      <div
        className=" w-52 h-52 flex flex-col justify-center items-center rounded"
        style={{ backgroundColor: "rgba(47, 72, 88, 0.8)" }}
      >
        <div className="bg-primaryColor opacity-50  flex justify-center items-center p-1 rounded-xl">
          <img className="w-20" src={data.image} />
        </div>
        <p className="text-white">{data.name}</p>
      </div>
    </Link>
  );
};

export default HomeDesktop;
