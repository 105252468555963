import { useState, useEffect, useCallback } from "react";
import GeneralServices from "../services/GeneralServices";

const useFetchData = (endpoint) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const service = new GeneralServices();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setData([]);
    try {
      const response = await service.get(endpoint);
      if (response.data) setData(response.data);
      else {
        setData(response);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message || "حدث خطأ أثناء جلب البيانات");
      if (err.state === 401) {
        window.location.href = "/login";
      }
    } finally {
      setIsLoading(false);
    }
  }, [endpoint]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetch = () => {
    fetchData();
  };

  return { data, isLoading, error, refetch };
};

export default useFetchData;
