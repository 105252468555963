import React, { useContext, useEffect, useState } from "react";
import GeneralServices from "../../../services/GeneralServices";
import { showErrorToast } from "../../../utils/toastUtils";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import { calculateDiscountAmount } from "../../../utils/utility";

const Coupon = () => {
  // return <CouponComp />;
  const { card, setCard } = useContext(AuthContext);

  const [couponStatus, setCouponStatus] = useState(null);
  const [copon, setCopon] = useState({
    code: null,
    status: null,
    message: null,
  });

  const [couponData, setCouponData] = useState({});

  const service = new GeneralServices();

  const handleCoupon = async () => {
    setCopon({
      ...copon,
      status: null,
      message: null,
    });

    setCard({
      ...card,
      coupon: null,
      discountAmount: 0,
    });
    try {
      const foundCoupon = await service.post("coupon", { code: copon.code });
      setCopon({
        ...copon,
        code: foundCoupon.code,
        status: true,
      });

      const discountAmount = calculateDiscountAmount(
        card.productTotal,
        foundCoupon
      );
      console.log("discountAmount", discountAmount);
      // Update cart state
      setCard({
        ...card,
        coupon: foundCoupon,
        discountAmount: discountAmount,
      });
    } catch (error) {
      if (error.state == 400) {
        showErrorToast(error.message);
        setCopon({
          ...copon,
          message: error.message,
        });
      } else if (error.state == 422) {
        setCopon({
          ...copon,
          message: error.validationData?.code?.[0] ?? null,
          status: false,
        });
      }
    } finally {
    }
  };

  useEffect(() => {
    if (card.copon) {
      setCouponStatus(true);
      setCode(card.copon.code);
    }
  }, []);

  return (
    <>
      <div className="p-4 text-[#5C6678] group rounded-lg border-dashed border-[1.8px] mt-2">
        <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 border-stone-100 ">
          <div>
            <input
              onChange={(e) => setCopon({ ...copon, code: e.target.value })}
              value={copon.code}
              placeholder="ادخل كود الخصم"
              className={`w-full h-10 rounded-lg px-4  ${
                copon.status == false &&
                "border-red-500 border-[1.5px] text-red-600 focus:outline-none focus:ring-1 focus:ring-red-300"
              } 
                        
                        ${
                          copon.status == true &&
                          "border-green-500 border-[1.5px] text-green-600 focus:outline-none focus:ring-1 focus:ring-green-300"
                        }
                        
                        ${
                          copon.status == null &&
                          "border border-gray-300 text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                        }
                        `}
            />
          </div>
          <div className="w-4" />
          <div
            onClick={handleCoupon}
            className="cursor-pointer text-center flex-grow  bg-[#5C6678] h-10 text-white border-dashed py-1 rounded-lg flex justify-center items-center"
          >
            <p className="px-2">تطبيق</p>
          </div>
        </div>
        <p className="text-sm text-red-500">{copon.message}</p>
      </div>
    </>
  );
};

Coupon.defaultProps = {};

export default Coupon;

// كوبون
const CouponComp = () => {
  const { card, setCard } = useContext(AuthContext);
  const [copon, setCopon] = useState({
    code: null,
    status: null,
    message: null,
  });

  const service = new GeneralServices();

  const handleCoupon = async () => {
    setCopon({
      ...copon,
      status: null,
      message: null,
    });

    setCard({
      ...card,
      coupon: null,
      discountAmount: 0,
    });
    try {
      const res = await service.post("coupon", { code: copon.code });

      setCopon({
        ...copon,
        code: res.code,
        status: true,
      });
      const discountAmount = calculateDiscountAmount(card.productTotal, res);
      // Update cart state
      console.log("discountAmount ");
      // console.log("coupon card", card);
      setCard({
        ...card,
        coupon: res,
        discountAmount: discountAmount,
      });
    } catch (error) {
      if (error.state == 400) {
        showErrorToast(error.message);
        setCopon({
          ...copon,
          message: error.message,
        });
      } else if (error.state == 422) {
        setCopon({
          ...copon,
          message: error.validationData?.code?.[0] ?? null,
          status: false,
        });
      }
    } finally {
    }
  };

  return (
    <div className="p-4 text-[#5C6678] group rounded-lg border-dashed border-[1.8px] mt-2">
      <div className="flex justify-between items-center mb-2 border-b-1 border-b py-1 border-stone-100 ">
        <div className="flex-grow ">
          <input
            onChange={(e) => setCopon({ ...copon, code: e.target.value })}
            value={copon.code}
            placeholder="ادخل كود الخصم"
            className={`w-full h-10  flex-grow rounded-lg px-4  ${
              copon.status == false &&
              "border-red-500 border-[1.5px] text-red-600 focus:outline-none focus:ring-1 focus:ring-red-300"
            } 
                          
                          ${
                            copon.status == true &&
                            "border-green-500 border-[1.5px] text-green-600 focus:outline-none focus:ring-1 focus:ring-green-300"
                          }
                          
                          ${
                            copon.status == null &&
                            "border border-gray-300 text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                          }
                          `}
          />
        </div>
        <div className="w-4" />
        <div
          onClick={handleCoupon}
          className="cursor-pointer text-center  bg-[#5C6678] h-10 text-white border-dashed py-1 rounded-lg flex justify-center items-center"
        >
          <p className="px-2">تطبيق</p>
        </div>
      </div>
      <p className="text-sm text-red-500">{copon.message}</p>
    </div>
  );
};
