import React, { useEffect } from "react";
import useTitle from "../hooks/useTitle";

const PrivacyPage = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("سياسة الخصوصية");
  }, []);

  const sections = [
    {
      id: "copyright",
      title: "حقوق النشر والعلامات التجارية",
      content:
        "جميع المواد الواردة في هذا الموقع محمية بموجب حقوق النشر وقانون حماية العلامات التجارية المعمول بها.",
    },
    {
      id: "law",
      title: "القانون المنظم",
      content:
        "تخضع هذه الشروط والأحكام ويُفسرها وفقاً لقوانين المملكة العربية السعودية، وتخضع أنت كذلك وبشكل غير قابل للنقض للاختصاص القضائي لمحاكم المملكة العربية السعودية.",
    },
    {
      id: "privacy",
      title: "سياسة الخصوصية",
      content:
        'نحن في "سهول" نعتبر الخصوصية والأمان أولوياتنا القصوى. لم نقم أبداً بمشاركة أو طباعة أو بيع معلومات أي زبون لأي طرف آخر. عندما تقدم المعلومات الشخصية على موقعنا، نعمل بكل جهد على حمايتها داخلياً وخارجياً.',
    },
    {
      id: "security",
      title: "تقنيات الأمان",
      content:
        "نستخدم مجموعة متنوعة من التقنيات والإجراءات الأمنية لحماية معلوماتك الشخصية من الوصول أو الاستخدام أو الكشف غير المصرح به. تشمل هذه التدابير تخزين معلوماتك الشخصية على أنظمة كمبيوتر ذات وصول محدود.",
    },
    {
      id: "account",
      title: "خصوصية حسابك",
      content:
        "أنت مسؤول عن الحفاظ على سرية بيانات حسابك وكلمة المرور، وعن تحديد من يصل إلى جهاز الكمبيوتر الخاص بك أو التطبيق الخاص بنا أيضاً. أنت توافق على قبول المسؤولية عن جميع الأنشطة التي تتم من خلال حسابك أو كلمة المرور الخاصة بك.",
    },
    {
      id: "age",
      title: "الأعمار",
      content:
        "إذا كان عمرك أقل من 16 سنة، فلا يجوز لك استخدام خدماتنا إلا بإشراك أحد الوالدين أو ولي الأمر.",
    },
    {
      id: "cancellation",
      title: "حقوق الإلغاء",
      content:
        "نحتفظ بالحق في رفض تقديم الخدمة، أو إنهاء الحسابات، أو إزالة أو تعديل المحتوى، أو إلغاء الأوامر وفقاً لتقديرنا الخاص.",
    },
    {
      id: "warranty",
      title: "الضمان",
      content: (
        <ul className="list-disc list-inside rtl">
          <li>الضمان ضد الرائحة واللون المتغير فقط</li>
          <li>
            الضمان على طلبات التوصيل فقط وان يكون الطلب من خلال المتجر
            sehoool.com
          </li>
          <li>للحصول على تعويض عن الضمان يجب إرجاع الذبيحة خلال 24 ساعة</li>
          <li>لايسري الضمان اذا تم طبخ اكثر من 1 كيلو من الذبيحة</li>
          <li>الذبيحة المسترجعة لابد أن تكون محفوظة جيدا ومجمدة</li>
          <li>تخصم رسوم التوصيل عند استرداد المبلغ</li>
        </ul>
      ),
    },
    {
      id: "return",
      title: "سياسات الاستبدال والاسترجاع",
      content: (
        <ul className="list-disc list-inside rtl">
          <li>لا يتم استرجاع الذبيحة أو استرداد المبلغ بعد اعتماد الطلب.</li>
          <li>يحق للعميل إلغاء الطلب قبل الشروع في تجهيزه</li>
          <li>رد المبلغ للعميل قد يستغرق 2 يوم عمل كحد أقصى</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="bg-gradient-to-b from-rose-50 to-rose-100 min-h-screen font-arabic">
      <header className="bg-rose-600 text-white py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-center">
            سياسة الخصوصية - سهول
          </h1>
        </div>
      </header>

      <main className="max-w-4xr mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-xl rounded-lg p-6 space-y-8">
          {sections.map((section) => (
            <div
              key={section.id}
              className="border-b border-rose-200 pb-6 last:border-b-0"
            >
              <h2 className="text-2xl font-semibold mb-4 text-rose-700">
                {section.title}
              </h2>
              <div className="prose prose-rose max-w-none">
                {typeof section.content === "string" ? (
                  <p>{section.content}</p>
                ) : (
                  section.content
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-white shadow-lg rounded-lg p-6 text-center">
          <p className="mb-4">
            بمجرد دخولك إلى الموقع، فإنك توافق على جميع هذه الشروط والأحكام.
          </p>
          <p className="font-semibold text-rose-700">
            نحن في "سهول" نقدر ثقتكم، ونعدكم بالحفاظ على خصوصيتكم وأمان
            معلوماتكم بأقصى درجات الاهتمام
          </p>
        </div>
      </main>

      <footer className="bg-rose-700 text-white py-6 mt-12">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p>&copy; {new Date().getFullYear()} سهول. جميع الحقوق محفوظة.</p>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPage;
