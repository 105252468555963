import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContextProvider';
import GeneralServices from '../../services/GeneralServices';
import Modal from '../../components/Modal';

const Join = ({ onClose, setModelType }) => {




    const [phone, setPhone] = React.useState();
    const [password, setPassword] = React.useState();
    const [userData, setUserData] = useState({
        'name': '',
        'email': '',
        'phone': '',
        'password': '',
    });


    const { user, setUser } = useContext(AuthContext);

    const [validation, setValidation] = useState([]);
    const [loading, setLoading] = useState(false)



    const service = new GeneralServices();


    const handleRegister = async () => {
        setValidation([])
        setLoading(true)


        try {

            const res = await service.post('register', userData)
            // save user in local storage
            localStorage.setItem('user', JSON.stringify(res))
            // setUser(res)
            setModelType('otp')
        } catch (error) {
            console.log('error', error)
            if (error.validationData) {
                setValidation(error.validationData)
            }
        } finally {
            setLoading(false)
        }

    }


    return (
        <Modal onClose={onClose}>
            <div>
                <div className='justify-center items-center flex'>
                    <h1 className='text-2xl font-bold'>تسجيل جديد</h1>
                </div>



                <div className='flex'>
                    <div className='col'>
                        <label className='text-gray-500 font-medium'>الاسم </label>
                        <input

                            value={userData.name}
                            placeholder='الاسم '
                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                            className='border col p-1 rounded  font-medium' />

                        <small id="helpId" class="form-text text-danger">{validation?.name?.[0]}</small>
                    </div>

                </div>
                {/* <div className='flex'>
                            <div className='col'>
                                <label className='text-gray-500 font-medium'>البريد الالكتوني</label>
                                <input
                                    value={userData.email}
                                    placeholder='البريد الالكتوني'
                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                    className='border col p-1 rounded  font-medium' />

                                <small id="helpId" class="form-text text-danger">{validation?.email?.[0]}</small>
                            </div>

                         </div> */}



                <div className='flex items-end'>
                    <div className='col '>
                        <label className='text-gray-500 font-medium'>رقم الهاتف</label>
                        <input
                            value={userData.phone}
                            placeholder='رقم الهاتف'
                            onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                            className='border col p-1 rounded text-right font-medium' />

                        <small id="helpId" class="form-text text-danger">{validation?.phone?.[0]}</small>
                    </div>

                    <div>
                        <div className='flex items-center border rounded p-1 mb-1'>
                            <span className='font-medium'>+966</span>
                            <img className='w-8 rounded mx-1 h-5' src='https://www.worldometers.info/img/flags/sa-flag.gif' />
                        </div>
                    </div>
                </div>

                <div className='flex'>
                    <div className='col'>
                        <label className='text-gray-500 font-medium'>كلمه السر</label>
                        <input
                            value={userData.password}
                            placeholder='كلمه السر'
                            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                            className='border col p-1 rounded  font-medium' />

                        <small id="helpId" class="form-text text-danger">{validation?.password?.[0]}</small>
                    </div>

                </div>

                <div onClick={() => handleRegister()} className={`flex justify-center items-center my-2 border rounded p-1 cursor-pointer ${loading ? 'bg-gray-300' : 'bg-primaryColor'} text-white`}>
                    <span>تسجيل </span>
                </div>
                <p>
                    لدي حساب مسبقا؟ <span onClick={() => setModelType('login')} className="text-primaryColor cursor-pointer">سجل دخول</span>
                </p>

            </div>

        </Modal >
    );
};

export default Join;
