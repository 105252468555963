import React, { useContext } from 'react'
import { AuthContext } from '../../../contexts/AuthContextProvider';

function ProfilePageDesktop() {

    const { user, setUser } = useContext(AuthContext);
    return (
        <div>
            <h1 className='text-2xl font-semibold'>حسابى</h1>

            <div className='bg-green w-24 h-24 rounded-full'>
                <img className='rounded-full mt-2' src='https://via.placeholder.com/100x100' />
            </div>



            <div className='row mt-3'>
                <div className='col-md-6  mt-2'>
                    <div className='border rounded p-1 col-12'>
                        <p className='text-gray-500'>الاسم</p>
                        <input className='p-1 rounded my-1 col text-black font-medium'
                            value={user?.user?.name}
                            onChange={(e) => setUser({ ...user, ...user, name: e.target.value })}
                        />
                    </div>
                </div>
                <div className='col-md-6  mt-2'>
                    <div className='border rounded p-1 col-12'>
                        <p className='text-gray-500'>الاسم</p>
                        <input className='p-1 rounded my-1 col  text-black font-medium' />
                    </div>
                </div>
                <div className='col-md-6  mt-2'>
                    <div className='border rounded p-1 col-12'>
                        <p className='text-gray-500'>البريد الالكتورني</p>
                        <input className='p-1 rounded my-1 col  text-black font-medium' />
                    </div>
                </div>
                <div className='col-md-6  mt-2'>
                    <div className='border rounded p-1 col-12 row'>
                        <div className='col'>
                            <div className='col row rounded border p-2 text-rose-500'>
                                <input type='radio' className=' text-rose-500' />
                                <p className='mx-1'>ذكر</p>
                            </div>
                        </div>

                        <div className='col'>
                            <div className='col row rounded border p-2'>
                                <input type='radio' />
                                <p className='mx-1'>ذكر</p>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilePageDesktop