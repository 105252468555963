import React from 'react'

function ContactUsPage() {
    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div>
                            <h1 className='text-3xl font-semibold'>تواصل معنا</h1>
                            <p className='text-gray-500'>ادخل البيانات المطلوبه للاستفسار</p>
                        </div>
                        <div className='row'>
                            <div className='col-6 p-3'>
                                <div className='border rounded p-2 col-12'>
                                    <p className='text-gray-500'>الاسم الاول</p>
                                    <input className='p-1 rounded my-1 col text-black font-medium' />
                                </div>
                            </div>
                            <div className='col-6 p-3'>
                                <div className='border rounded p-2 col-12'>
                                    <p className='text-gray-500'>الاسم الثاني</p>
                                    <input className='p-1 rounded my-1 col text-black font-medium' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 p-3'>
                                <div className='border rounded p-2 col-12'>
                                    <p className='text-gray-500'>البريد االالكتروني</p>
                                    <input className='p-1 rounded my-1 col text-black font-medium' />
                                </div>
                            </div>
                            <div className='col-12 p-3'>
                                <div className='border rounded p-1 col-12'>
                                    <p className='text-gray-500'>رقم الهاتف</p>
                                    <input className='p-2 rounded my-1 col  text-black font-medium' />
                                </div>
                            </div>
                            <div className='col-12 p-3'>
                                <div className='border rounded p-1 col-12'>
                                    <p className='text-gray-500'>الرساله</p>
                                    <textarea className='p-2 rounded my-1 col  text-black font-medium' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ContactUsPage