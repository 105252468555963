import React, { useEffect, useState } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useSubmitData from "../../../hooks/useSubmitData";

const defaultCustomers = [
  { id: 1, name: "أحمد", phone: "0123456789" },
  { id: 2, name: "فاطمة", phone: "0987654321" },
  { id: 3, name: "محمد", phone: "1112223333" },
  { id: 4, name: "عائشة", phone: "4445556666" },
  { id: 5, name: "سلمان", phone: "7778889990" },
  { id: 6, name: "نورة", phone: "9990001111" },
  { id: 7, name: "يوسف", phone: "2223334444" },
  { id: 8, name: "مريم", phone: "5556667777" },
  { id: 9, name: "علي", phone: "8889990000" },
  { id: 10, name: "هند", phone: "3334445555" },
  { id: 11, name: "سعود", phone: "6667778888" },
  { id: 12, name: "ريم", phone: "9991112222" },
  { id: 13, name: "خالد", phone: "2223334444" },
  { id: 14, name: "سارة", phone: "5556667777" },
  { id: 15, name: "تركي", phone: "8889990000" },
  { id: 16, name: "جواهر", phone: "3334445555" },
  { id: 17, name: "فهد", phone: "6667778888" },
  { id: 18, name: "لطيفة", phone: "9991112222" },
  { id: 19, name: "راشد", phone: "2223334444" },
  { id: 20, name: "عزيزة", phone: "5556667777" },
  { id: 21, name: "عبدالله", phone: "8889990000" },
  { id: 22, name: "عبدالرحمن", phone: "3334445555" },
  { id: 23, name: "عبدالعزيز", phone: "6667778888" },
  { id: 24, name: "عبدالعليم", phone: "9991112222" },
  { id: 25, name: "عبدالكريم", phone: "2223334444" },
];

function Customers({ setCustomer, customer: selectedCustomer }) {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, isLoading, refetch } = useFetchData("customers");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  // const customers = defaultCustomers;
  // const customers = data || defaultCustomers;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (data) {
      setFilteredCustomers(
        data.filter(
          (c) =>
            (c.name &&
              c.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (c.phone && c.phone.includes(searchTerm))
        )
      );
    }
  }, [searchTerm, data]);

  useEffect(() => {
    setFilteredCustomers(data);
  }, [data]);

  return (
    <div className=" bg-slate-50 gap-4 overflow-x-scroll h-[500px] p-2">
      <div className="flex gap-4 justify-between items-center p-2 pt-0">
        <div className="w-full">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="ابحث بالاسم أو رقم الهاتف"
            className="border p-2 rounded-md w-full"
          />
        </div>
        <div className="w-max">
          <button
            onClick={() => setShowAddCustomer(!showAddCustomer)}
            className="mt-2 bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 text-start px-4 rounded whitespace-nowrap"
          >
            {showAddCustomer ? "الغاء" : "اضافة عميل جديد"}
          </button>
        </div>
      </div>
      {showAddCustomer ? (
        <AddCustomer
          searchTerm={searchTerm}
          setShowAddCustomer={setShowAddCustomer}
        />
      ) : (
        <div
          className={`${
            filteredCustomers.length > 0
              ? "grid grid-cols-4 gap-4 overflow-x-scroll h-[500px] p-2"
              : "flex flex-col justify-center items-center h-[500px]"
          }`}
        >
          <React.Suspense fallback={<div>Loading...</div>}>
            {filteredCustomers.length > 0 ? (
              filteredCustomers.map((customer) => (
                <CustomerCard
                  key={customer.id}
                  customer={customer}
                  setCustomer={setCustomer}
                  selectedCustomer={selectedCustomer}
                />
              ))
            ) : (
              <>
                {searchTerm ? (
                  <div className="text-center text-gray-500 font-bold w-full p-2 rounded-lg">
                    <button
                      onClick={() => setShowAddCustomer(true)}
                      className=" font-bold bg-rose-500 text-white p-2 rounded-lg"
                    >
                      اضافة عميل جديد
                    </button>
                  </div>
                ) : (
                  <div className="text-center text-gray-500">لا يوجد عملاء</div>
                )}
              </>
            )}
          </React.Suspense>
        </div>
      )}
    </div>
  );
}

const CustomerCard = ({ customer, setCustomer, selectedCustomer }) => {
  return (
    <div
      key={customer.id}
      onClick={() => setCustomer(customer)}
      className={`bg-white flex shadow-lg justify-start p-2 text-end rounded-lg items-center  gap-2 cursor-pointer ${
        customer?.id === selectedCustomer?.id
          ? "border-2 border-green-500"
          : "border border-gray-200"
      }`}
    >
      <div className="mt-4 text-start items-center">
        <div className="text-lg font-semibold text-gray-800">
          {customer?.name || "غير معروف"}
        </div>
        <div className="text-sm text-gray-600">
          <span className="font-bold">رقم الهاتف:</span>
          {customer?.phone || "رقم الهاتف غير متوفر"}
        </div>
        <div className="text-sm text-gray-600">
          <span className="font-bold">الرقم الضريبي:</span>
          {customer?.tax_number || "رقم الضريبة غير متوفر"}
        </div>
        <div className="text-sm text-gray-600">
          <span className="font-bold">الرقم التجاري:</span>
          {customer?.commercial_register || "رقم السجل التجاري غير متوفر"}
        </div>
      </div>
    </div>
  );
};

const AddCustomer = ({ setShowAddCustomer, searchTerm }) => {
  const customer = {
    name: "",
    phone: searchTerm,
    tax_number: "",
    commercial_register: "",
  };

  const {
    data: newCustomer,
    setData: setNewCustomer,
    errors,
    isLoading,
    submit,
  } = useSubmitData(customer, "customers");
  const handleAddCustomer = async () => {
    // setData(newCustomer);
    // console.log("data", data);
    console.log("newCustomer", newCustomer);
    // return;
    try {
      const response = await submit();
      console.log(response);
      // setNewCustomer({
      //   name: "",
      //   phone: "",
      //   tax_number: "",
      //   commercial_register: "",
      // });
      // setShowAddCustomer(false);
      // setShowAddCustomer(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setNewCustomer({ ...newCustomer, phone: searchTerm });
  }, [searchTerm]);
  return (
    <div className="flex flex-col text-start  h-[500px]">
      <div className=" grid grid-cols-2 gap-4 justify-between mt-4 ">
        <div>
          <input
            type="text"
            value={newCustomer.name}
            onChange={(e) =>
              setNewCustomer({ ...newCustomer, name: e.target.value })
            }
            placeholder="اسم العميل"
            className="border p-2 rounded-md w-full mb-2"
          />
          <span className="text-red-500 text-sm">{errors?.name?.[0]}</span>
        </div>
        <div>
          <input
            type="text"
            value={newCustomer.phone}
            onChange={(e) =>
              setNewCustomer({ ...newCustomer, phone: e.target.value })
            }
            placeholder="رقم الهاتف"
            className="border p-2 rounded-md w-full"
          />
          <span className="text-red-500 text-sm">{errors?.phone?.[0]}</span>
        </div>
        <div>
          <input
            type="text"
            value={newCustomer.tax_number}
            onChange={(e) =>
              setNewCustomer({ ...newCustomer, tax_number: e.target.value })
            }
            placeholder="الرقم الضريبي"
            className="border p-2 rounded-md w-full"
          />
          <span className="text-red-500 text-sm">
            {errors?.tax_number?.[0]}
          </span>
        </div>
        <div>
          <input
            type="text"
            value={newCustomer.commercial_register}
            onChange={(e) =>
              setNewCustomer({
                ...newCustomer,
                commercial_register: e.target.value,
              })
            }
            placeholder="السجل التجارى"
            className="border p-2 rounded-md w-full"
          />
          <span className="text-red-500 text-sm">
            {errors?.commercial_register?.[0]}
          </span>
        </div>
        <button
          onClick={handleAddCustomer}
          disabled={isLoading}
          className={`mt-2 text-white font-bold py-2 px-4 rounded ${
            isLoading ? "opacity-90 bg-gray-500" : "bg-rose-500"
          }`}
        >
          {isLoading ? "جارى التنفيذ" : "إضافة عميل جديد"}
        </button>
      </div>
    </div>
  );
};

export default Customers;
