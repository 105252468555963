import { CreditCard } from "lucide-react";
import React, { useState } from "react";
import BottomSheet from "../../../components/mobile/BottomSheet";
import useFetchData from "../../../hooks/useFetchData";
import Input from "../../../components/Input";

const Payments = ({
  payments,
  setPayments,
  total,
  handlePayment,
  isLoading,
}) => {
  const [showModal, setShowModal] = useState(false);

  const ToggleModal = () => {
    setShowModal(!showModal);
  };

  // get payment/receipt
  const { data: paymentReceipts, isLoading: isLoadingReceipts } =
    useFetchData("payment/receipt");

  const [paymentsList, setPaymentsList] = useState([]);

  const totalPaid = payments.reduce(
    (acc, curr) => acc + Number(curr.amount || 0),
    0
  );

  const remaining = total - totalPaid;

  const isPaymentComplete = Math.abs(remaining) < 0.01; // للتعامل مع أخطاء التقريب العشري

  const getRemainingColor = () => {
    if (remaining < 0) return "text-red-500";
    if (remaining > 0) return "text-black";
    return "text-green-500";
  };

  return (
    <>
      <button
        onClick={ToggleModal}
        className={`w-full bg-green-500 text-2xl font-bold h-[100px] text-white py-3 rounded-md mt-6 hover:bg-green-600 transition duration-200 ${
          isLoadingReceipts ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        المتابعة للدفع
      </button>
      {/* <div onClick={ToggleModal} className="text-gray-400 cursor-pointer">
        <CreditCard />
      </div> */}
      <BottomSheet
        onClose={() => ToggleModal()}
        isOpen={showModal}
        postion="center"
        title="الدفعيات"
      >
        <div className="flex flex-col space-y-4">
          <div className="flex gap-4 items-center justify-around font-bold text-2xl">
            <span
              className={`text-black cursor-pointer bg-gray-100 rounded-full  px-6 py-2 text-[26px] text-center`}
            >
              المجموع :
              {total.toLocaleString("en-EG", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span
              className={`cursor-pointer bg-gray-100 rounded-full px-6 py-2 text-[40px] text-center ${getRemainingColor()}`}
            >
              المتبقى :{remaining.toFixed(2)}
            </span>
            <span
              className={`text-black cursor-pointer bg-gray-100 rounded-full  px-6 py-2 text-[26px] text-center`}
            >
              المدفوع :{totalPaid.toFixed(2)}
            </span>
          </div>

          <div className="flex flex-col space-y-4">
            {paymentReceipts.map((receipt) => (
              <div
                key={receipt.id}
                className="flex flex-col space-y-2 border-b border-gray-200 pb-4"
              >
                <div className="flex items-center justify-between">
                  <span className="text-gray-400 w-[100px] text-start">
                    {receipt.name}
                  </span>
                  <div className="flex items-center gap-2 flex-grow">
                    <Input
                      onChange={(event) => {
                        const newAmount = Number(event.target.value);
                        setPayments((prev) => {
                          const existingPaymentIndex = prev.findIndex(
                            (payment) => payment.id === receipt.id
                          );
                          if (existingPaymentIndex !== -1) {
                            // Update existing payment
                            const updatedList = [...prev];
                            updatedList[existingPaymentIndex].amount =
                              newAmount;
                            return updatedList;
                          } else {
                            // Add new payment
                            return [
                              ...prev,
                              { id: receipt.id, amount: newAmount },
                            ];
                          }
                        });
                      }}
                      type="number"
                      className="text-2xl"
                      value={
                        payments.find((payment) => payment.id === receipt.id)
                          ?.amount || ""
                      }
                      placeholder={`المبلغ`}
                    />
                    {/* عمل زر عند الضغط عليه يتم اضافة المبلغ المتبقى فى ال Input المقابل له فى الصف        */}
                    <button
                      disabled={
                        payments.find((payment) => payment.id === receipt.id)
                          ?.amount || ""
                      }
                      onClick={() => {
                        setPayments((prev) => {
                          const updatedList = [...prev];
                          updatedList.push({
                            id: receipt.id,
                            amount: remaining,
                          });
                          return updatedList;
                        });
                      }}
                      className={`${
                        payments.find((payment) => payment.id === receipt.id)
                          ?.amount || remaining === 0
                          ? "bg-gray-300 text-gray-500"
                          : "bg-blue-500 text-white"
                      } bg-blue-500 text-white  flex items-center justify-center py-2 text-center rounded-md`}
                    >
                      <p className=" p-1 ">
                        سداد {remaining.toFixed(2)} {receipt.name}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            className={`rounded-md px-4 py-2 h-[100px] font-bold text-2xl ${
              isPaymentComplete
                ? "bg-rose-500 text-white cursor-pointer"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            disabled={!isPaymentComplete}
            onClick={() => {
              if (isPaymentComplete) {
                // قم بتنفيذ عملية الدفع هنا
                console.log("تم الدفع");
                handlePayment();
                ToggleModal();
              }
            }}
          >
            دفع
          </button>
          <button className="text-yellow-400" onClick={() => setPayments([])}>
            تصفير المبلغ
          </button>
        </div>
      </BottomSheet>
    </>
  );
};

export default Payments;
