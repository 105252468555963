import React, { useContext, useEffect, useState } from "react";
import GeneralServices from "../../services/GeneralServices";
import { AuthContext } from "../../contexts/AuthContextProvider";

import {
  Search,
  Filter,
  Settings,
  Home,
  Grid,
  ShoppingBag,
  User,
  Check,
  CheckCheckIcon,
} from "lucide-react";
import useFetchData from "../../hooks/useFetchData";
import Input from "../../components/Input";
import CartTable from "./components/CartTable";
import Modal from "../../components/Modal";
import BottomSheet from "../../components/mobile/BottomSheet";
import Customers from "./components/Customers";
import PaymentButton from "./components/PaymentButton";
import Options from "./components/Options";
import OptionTable from "./components/OptionTable";
import { Link, useNavigate } from "react-router-dom";
import Payments from "./components/Payments,";
import Receipt from "./components/Receipt";
import Invoices from "./components/Invoices";
import Logout from "./components/Logout";

export default function PosScreen() {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [options, setOptions] = useState([]);
  const [customer, setCustomer] = useState({});

  const [payments, setPayments] = useState([]);

  const [validations, setValidations] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const ToggleModal = () => setShowModal((prev) => !prev);

  const { data: categories, isLoading, refetch } = useFetchData("/categories");
  const { data: products, isLoading: isLoadingProducts } =
    useFetchData("/products");

  const resetData = () => {
    setCart([]);
    setOptions([]);
    setCustomer({});
    setPayments([]);
    setValidations([]);
  };

  const [activeCategory, setActiveCategory] = useState("all");
  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [additionalCharge, setAdditionalCharge] = useState(0);

  // open menu
  const [openMenu, setOpenMenu] = useState("products");
  const [payType, setPayType] = useState("normal"); // 'normal' for item, 'weight' for weight

  const [isAuthorized, setIsAuthorized] = useState(false);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === product.id);
      if (existingItem) {
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prevCart, { ...product, quantity: 1, weight: 0 }];
    });
  };

  const updateQuantity = (id, newQuantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id
          ? {
              ...item,
              quantity: Math.max(0, newQuantity), // Keep item even if quantity is 0
            }
          : item
      )
    );
  };

  const updateWeight = (id, newWeight) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.id === id
            ? {
                ...item,
                weight: Math.max(0, newWeight),
              }
            : item
        )
        .filter((item) => item.weight > 0)
    );
  };

  const updatePrice = (id, newPrice) => {
    // التحقق من أن السعر الجديد هو رقم صالح وليس فارغًا
    if (!isNaN(newPrice) && newPrice !== "") {
      // تقريب السعر إلى رقمين عشريين
      const roundedPrice = parseFloat(newPrice).toFixed(2);
      if (parseFloat(roundedPrice) <= 0) {
        removeFromCart(id); // حذف العنصر إذا كان السعر 0 أو أقل
      } else {
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.id === id ? { ...item, price: parseFloat(roundedPrice) } : item
          )
        );
      }
    }
  };

  const removeFromCart = (id) =>
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));

  const isInCart = (productId) => cart.some((item) => item.id === productId);

  // filter products by category
  const filteredProducts = products
    ? products.filter((product) =>
        activeCategory === "all" ? true : product.category === activeCategory
      )
    : [];

  const initialSubtotal =
    payType === "normal"
      ? cart.reduce((sum, item) => sum + item.price * item.quantity, 0)
      : cart.reduce((sum, item) => sum + item.price * item.weight, 0);

  const optionsTotal = options.reduce((sum, option) => sum + option.price, 0);

  // Calculate totalSubtotal without modifying initialSubtotal
  const subtotal =
    Number(initialSubtotal) +
    Number(shipping) +
    Number(optionsTotal) +
    Number(discount);

  const tax = subtotal * 0.15;

  const total = subtotal + tax;

  useEffect(() => {
    console.log("user ahmed", user);
    if (!user?.user || user?.user?.level != "pos") {
      navigate("/");
    } else {
      setIsAuthorized(true);
    }
  }, [user]);

  if (isAuthorized === false) return <div>Loading...</div>;
  return (
    <div className="flex h-screen bg-gray-100 text-right">
      <BottomSheet
        onClose={() => ToggleModal()}
        isOpen={showModal}
        postion="center"
        title="العملاء"
      >
        <Customers customer={customer} setCustomer={setCustomer} />
      </BottomSheet>
      {/* Sidebar */}
      <div className="w-20 bg-white shadow-lg flex flex-col items-center py-4 space-y-8">
        <Link
          to="/"
          className="w-12 h-12 bg-rose-500 rounded-xl flex items-center justify-center text-white text-2xl font-bold"
        >
          ن
        </Link>
        <Home className="text-gray-400" />
        <Grid
          onClick={() => setOpenMenu("products")}
          className={`text-gray-400 ${
            openMenu === "products" ? "text-rose-500" : "text-gray-400"
          }`}
        />
        <Grid
          onClick={() => setOpenMenu("invoices")}
          className={`text-gray-400 ${
            openMenu === "invoices" ? "text-rose-500" : "text-gray-400"
          }`}
        />
        <ShoppingBag
          onClick={() => setOpenMenu("options")}
          className={`text-gray-400 ${
            openMenu === "options" ? "text-rose-500" : "text-gray-400"
          }`}
        />
        <User
          onClick={() => ToggleModal()}
          className={
            Object.keys(customer).length > 0
              ? `text-rose-500 cursor-pointer`
              : `text-gray-400 cursor-pointer`
          }
        />

        <Receipt />
        <Logout />
      </div>

      {/* Main Content */}
      <div className="flex-1 flex">
        {/* Products Section */}
        <div className="flex-1 p-8 overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">مرحبًا، {user?.user?.name}</h1>

            <Settings className="text-gray-500" />
          </div>

          {openMenu === "options" && (
            <Options options={options} setOptions={setOptions} />
          )}
          {openMenu === "invoices" && <Invoices />}

          {openMenu === "products" && (
            <div>
              <div className="flex items-center bg-white rounded-full px-4 py-2 mb-6">
                <Search className="text-gray-400 ml-2" />
                <input
                  className="w-full outline-none"
                  placeholder="ابحث عن منتج..."
                />
                <Filter className="text-gray-400 mr-2" />
              </div>

              <div className="flex gap-2 mb-6">
                <button
                  className={` flex px-2 items-center justify-center rounded-full ${
                    activeCategory === "all"
                      ? "bg-rose-500 text-white"
                      : "bg-white text-gray-600"
                  }`}
                  onClick={() => setActiveCategory("all")}
                >
                  <span>الكل</span>
                </button>
                {!isLoading &&
                  categories.map((category, index) => (
                    <button
                      key={category.id}
                      className={`flex items-center   px-2 justify-center rounded-full ${
                        activeCategory === category.name
                          ? "bg-rose-500 text-white"
                          : "bg-white text-gray-600"
                      }`}
                      onClick={() => setActiveCategory(category.name)}
                    >
                      <span>{category.name}</span>
                    </button>
                  ))}
              </div>

              <div className="grid grid-cols-3 gap-6">
                {filteredProducts.map((product) => (
                  <div
                    key={product.id}
                    className="bg-white rounded-lg shadow-md p-4"
                  >
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-full h-40 object-cover rounded-md mb-4"
                    />
                    <h3 className="font-semibold mb-2">{product.name}</h3>
                    <p className="text-gray-600 mb-2">
                      {(product.price * 1.15).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      ريال
                    </p>

                    <button
                      className={`w-full py-2 rounded-md transition duration-200 flex items-center justify-center ${
                        isInCart(product.id)
                          ? "bg-green-500 hover:bg-green-600 text-white"
                          : "bg-rose-500 hover:bg-rose-600 text-white"
                      }`}
                      onClick={() => addToCart(product)}
                    >
                      {isInCart(product.id) ? (
                        <>
                          <Check size={18} className="mr-2" />
                          تم الإضافة
                        </>
                      ) : (
                        "إضافة للسلة"
                      )}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Cart Section */}
        <div className="w-1/2 bg-white p-8 shadow-lg">
          <div className="flex justify-between items-center mb-6">
            <div className="text-start">
              <h1 className="text-2xl font-bold">سلة التسوق</h1>
              {Object.keys(customer).length > 0 && ( // Check if customer is not empty
                <>
                  <p className="text-green-500"> العميل : {customer.name}</p>

                  <button
                    onClick={() => setCustomer({})}
                    className="text-red-500"
                  >
                    حذف العميل
                  </button>
                </>
              )}
            </div>

            <div
              onClick={() =>
                setPayType(payType === "normal" ? "weight" : "normal")
              }
              className="flex cursor-pointer items-center gap-2"
            >
              <CheckCheckIcon
                className={`w-6 h-6 ${payType == "normal" && "text-rose-500"}`}
              />
              <span className={payType == "normal" && "text-rose-500"}>
                الوزن
              </span>
            </div>
          </div>
          {/* table using talwndcss */}

          <CartTable
            payType={payType}
            setCart={setCart}
            cart={cart}
            removeFromCart={removeFromCart}
            updateQuantity={updateQuantity}
            updateWeight={updateWeight}
            updatePrice={updatePrice}
            validations={validations}
          />

          <OptionTable options={options} setOptions={setOptions} />

          <div className="border-t pt-4 mt-4">
            <div className="flex justify-between mb-2">
              <span>المجموع الفرعي</span>
              <span>{initialSubtotal.toFixed(2)} ريال</span>
            </div>
            <div className="flex justify-between mb-2 text-green-500">
              <span>الخصم</span>

              <div className="w-14">
                <Input
                  alignment="center"
                  onChange={(e) => setDiscount(e.target.value)}
                  type="number"
                  value={discount}
                />
              </div>
            </div>
            <div className="flex justify-between mb-2 text-green-500">
              <span>شحن</span>

              <div className="w-14">
                <Input
                  alignment="center"
                  onChange={(e) => setShipping(e.target.value)}
                  type="number"
                  value={shipping}
                />
              </div>
            </div>
            <div className="flex justify-between mb-2 text-green-500">
              <span>اضافات اخرى</span>

              <div className="w-14">
                <Input
                  alignment="center"
                  onChange={(e) => setAdditionalCharge(e.target.value)}
                  type="number"
                  value={additionalCharge}
                />
              </div>
            </div>
            <div className="flex justify-between mb-2">
              <span>الضريبة</span>
              <span>
                {tax.toLocaleString("en-EG", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                ريال
              </span>
            </div>
            <div className="flex  pt-4 border-t-2 border-dashed justify-between font-bold text-lg mt-4">
              <span>الإجمالي</span>
              <span>
                {total.toLocaleString("en-EG", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                ريال
              </span>
            </div>
          </div>
          <PaymentButton
            setValidations={setValidations}
            resetData={resetData}
            data={{
              products: cart,
              products: cart,
              customer: customer,
              shipping: shipping,
              additionalCharge: additionalCharge,
              discount: discount,
              total: total,
              tax: tax,
              payType: payType,
              options: options,
            }}
          />
        </div>
      </div>
    </div>
  );
}
