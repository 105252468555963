import React from "react";

const AhmedHmed = () => {
  return (
    <div>
      <h1>فاتورة الحلوم</h1>
      <h2>بيانات العميل</h2>
      <p>الاسم: محمد علي</p>
      <p>العنوان: شارع النصر، المدينة</p>
      <p>الهاتف: 0123456789</p>
      <hr />
      <h2>تفاصيل الفاتورة</h2>
      <table>
        <thead>
          <tr>
            <th>اسم المنتج</th>
            <th>الكمية</th>
            <th>سعر الوحدة (جنيه)</th>
            <th>الإجمالي (جنيه)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>جبنة بيضاء</td>
            <td>2</td>
            <td>30</td>
            <td>60</td>
          </tr>
          <tr>
            <td>زبادي</td>
            <td>5</td>
            <td>10</td>
            <td>50</td>
          </tr>
          <tr>
            <td>لبن</td>
            <td>3</td>
            <td>20</td>
            <td>60</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3" class="total">
              المجموع
            </td>
            <td class="total">170</td>
          </tr>
        </tfoot>
      </table>
      <div class="footer">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
          height="200"
          width="200"
        />
        <p>
          تاريخ الفاتورة:{" "}
          <script>document.write(new Date().toLocaleDateString());</script>
        </p>
        <p>شكرًا لتسوقكم معنا!</p>
      </div>
    </div>
  );
};

export default AhmedHmed;
