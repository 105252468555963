import React from 'react';
import { ShoppingCart, Beef } from 'lucide-react';
import { Link } from 'react-router-dom';

const EmptyCart = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-red-50">
            <div className="grid grid-cols-1 gap-4 items-center justify-center text-center">
                <div className="mb-6 flex items-center justify-center text-center">
                    <ShoppingCart className="w-24 h-24 text-red-500 " />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">سلتك فارغة</h2>
                <p className="text-gray-600 mb-6">أضف بعض اللحوم الطازجة إلى سلتك</p>
                <div className='w-full  flex items-center justify-center text-center '>

                    <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 flex items-center justify-center rounded-full transition duration-300 ease-in-out transform hover:scale-105 ">
                        <div className='flex items-center justify-center mx-3'>
                            <Beef className="w-6 h-6 text-white" />
                            <Link className='text-white' to="/">تسوق الآن</Link>
                        </div>

                    </button>
                </div>

            </div>
        </div>
    );
};

export default EmptyCart;