import React, { useContext, useEffect, useState } from "react";
import ProductsListDesktop from "./ProductsListDesktop";
import ProductCard from "../../components/mobile/ProductCard";
import GeneralServices from "../../services/GeneralServices";
import { AuthContext } from "../../contexts/AuthContextProvider";
import useTitle from "../../hooks/useTitle";
import ImageCarousel from "../../components/ImageCarousel";

function ProductsListMobile() {
  const [shadow, setShadow] = React.useState(false);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [images, setImages] = useState([]);

  const [activeCategory, setActiveCategory] = useState("all");

  const { setTitle } = useTitle();

  const service = new GeneralServices();

  const ToggleModel = (item) => {
    setProduct(item);
    setShadow(!shadow);
  };

  const fetchCategories = async () => {
    try {
      const res = await service.get("categories");
      setCategories(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const { data } = await service.get(`products/${activeCategory}`);
      setProducts(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchImages = async () => {
    try {
      const data = await service.get("slideshow");
      setImages(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //   is product added to card

  useEffect(() => {
    fetchImages();
    fetchCategories();
    setTitle("الذبائح");
    document.title = "سهول - الذبائح";
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [activeCategory]);

  return (
    <>
      <div className="mt-1 h-full">
        <div className="w-full z-30 p-1">
          <div
            className="flex overflow-x-scroll scrollbar-hide scroll-smooth snap-x snap-mandatory"
            id="categoriesContainer"
          >
            <div
              className={`flex-shrink-0 flex flex-col rounded-lg overflow-hidden items-center text-center mt-2 mr-2 snap-start `}
              onClick={() => setActiveCategory("all")}
              style={{ cursor: "pointer" }}
              id="all"
            >
              <div className="rounded-lg w-[80px] h-[80px] overflow-hidden">
                <img
                  src="/imges/all.jpg"
                  className={`w-full h-full object-cover ${
                    activeCategory === "all"
                      ? "border-[1px] border-red-500"
                      : ""
                  }`}
                  alt="All categories"
                />
              </div>
              <span className="text-[10px] text-rose-500">الكل</span>
            </div>
            {categories.map((category) => (
              <div
                className={`flex-shrink-0 flex flex-col rounded-lg overflow-hidden items-center text-center mt-2 mr-2 snap-start ${
                  activeCategory === category.id
                    ? "border-[2px] border-red-500"
                    : ""
                }`}
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                style={{ cursor: "pointer" }}
                id={`${category.id}`}
              >
                <div className="rounded-lg w-[80px] h-[80px] overflow-hidden">
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <span className="text-[12px] p-1 text-rose-500">
                  {category.name}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex-grow overflow-x-scroll">
          <div className="grid grid-cols-2 gap-4 px-1 mb-4">
            {products.map((product) => (
              <ProductCard key={product.id} product={product} {...product} />
            ))}
          </div>
          <div className="h-36" />
        </div>
      </div>
    </>
  );
}

export default ProductsListMobile;
