import React, { useEffect } from 'react'
import Map from '../../components/Map'
import AddressForm from '../../components/AddressForm'

function AboutPage() {

    useEffect(() => {

        document.title = 'سهول - من نحن'


    }, [])

    return (
        <div className='container'>
            <div></div>
            <div className='container'>

                <p className="capitalize font-bold font-tajawal text-2xl md:text-[22px] text-black-900  sm:text-xl">
                    <h1 className='text-2xl font-black'>معلومات عن سهول</h1>
                </p>

                <p className=" text-2xl md:text-[22px] text-black-900  sm:text-xl  w-full">
                    اللحم هو جزء هام من تغذيتنا ويعتبر مصدرًا غنيًا بالبروتينات والفيتامينات والمعادن. يمكن تقسيم اللحم إلى عدة أنواع رئيسية مثل اللحم الأحمر واللحم الأبيض واللحم البحري. وفيما يلي بعض المعلومات حول تقطيع اللحوم
                </p>



            </div>

            <div>
                <Section />
                <Section />
            </div>


            <div className='justify-center text-center my-4 col-md-12 col-sm-12'>
                <div className="flex flex-col  justify-center items-center  my-4">
                    <img className="h-14 md:ml-[0] ml-[97px] w-14" src="https://img.icons8.com/matisse/100/000000/gender-neutral-user--v1.png" alt="frame_Two" />
                    <p className="font-bold sm:text-4xl md:text-[38px] text-[40px] text-gray-900 text-right">
                        عملاء سهول
                    </p>
                </div>
                <div className='row'>
                    <CustomerOpinions />
                    <CustomerOpinions />
                    <CustomerOpinions />
                </div>
            </div>

        </div>
    )
}


const Section = () => {
    return (
        <>
            <div className='my-4'>
                <div className='row'>
                    <img
                        className="h-[525px] col-md-4 col-sm-12 sm:h-auto object-cover rounded-[20px]  "
                        src="https://kitchen.sayidaty.net/uploads/small/19/191430031e423714094f8522b59964f5_w750_h750.jpg"
                        alt="rectangle346754_Two"
                    />
                    <div className="col-sm-12 col-md-6 ">
                        <div className="flex flex-col items-center justify-start">
                            <p className="capitalize font-bold font-tajawal sm:text-4xl md:text-[38px] text-[40px] text-black-900 text-right">
                                تقنيات التقطيع
                            </p>
                        </div>
                        <p className=" text-2xl md:text-[22px] text-black-900  sm:text-xl  w-full">
                            أدوات التقطيع: يستخدم الجزارون والطهاة أدوات مختلفة لتقطيع اللحم، مثل السكين والمبردة والمقص والمنشار. يعتمد
                            نوع الأداة على نوع اللحم والقطعة المراد تقطيعها.
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};


const CustomerOpinions = ({ name, description }) => {
    return (

        <div className='col-md-4 col-md-4 flex flex-col items-center justify-center my-3'>
            <img
                className="h-[100px] rounded-full w-[100px]"
                src="https://img.fixthephoto.com/blog/images/gallery/news_preview_mob_image__preview_11368.png"
                alt="ellipseTen"
            />
            <div className="flex flex-col gap-4 items-center justify-start">
                <p className="capitalize font-bold font-tajawal text-2xl md:text-[22px] text-black-900 text-center sm:text-xl">
                    إبراهيم محمد
                </p>
                <p className="font-tajawal leading-[29.00px] text-black-900 text-center text-lg w-full">
                    ناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص
                    أو شكل توضع الفقرات في الصفحة
                </p>
            </div>
        </div>

    );
};

export default AboutPage