import React, { useState, useEffect } from "react";

const VisaCardReader = () => {
  const [nfcSupported, setNfcSupported] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if ("NDEFReader" in window) {
      setNfcSupported(true);
    }
  }, []);

  const readCard = async () => {
    if (!nfcSupported) {
      setError("NFC is not supported on this device.");
      return;
    }

    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      ndef.addEventListener("reading", ({ message, serialNumber }) => {
        // This is a simplified interpretation and may not work for all cards
        let cardData = {};
        for (const record of message.records) {
          if (record.recordType === "mime") {
            const textDecoder = new TextDecoder();
            const text = textDecoder.decode(record.data);

            // Attempt to parse the data (this is hypothetical and may not work)
            const parts = text.split(",");
            parts.forEach((part) => {
              const [key, value] = part.split(":");
              cardData[key.trim()] = value.trim();
            });
          }
        }

        setCardInfo({
          serialNumber,
          ...cardData,
        });
      });
    } catch (error) {
      setError(`Error: ${error.message}`);
    }
  };

  return (
    <div className="visa-card-reader">
      <h1>Visa Card Reader</h1>
      {!nfcSupported && <p>NFC is not supported on this device.</p>}
      {nfcSupported && <button onClick={readCard}>Scan Visa Card</button>}
      {error && <p className="error">{error}</p>}
      {cardInfo && (
        <div className="card-info">
          <h2>Card Information</h2>
          <p>Serial Number: {cardInfo.serialNumber}</p>
          {Object.entries(cardInfo).map(
            ([key, value]) =>
              key !== "serialNumber" && (
                <p key={key}>
                  {key}: {value}
                </p>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default VisaCardReader;
