import React, { useEffect, useState } from 'react'
import ShoppingCardMobile from './mobile/ShoppingCardMobile';
import ShoppingCardDesktop from './desktop/ShoppingCardDesktop';
import useBreakpoint from '../../hooks/useBreakpoints';

function ShoppingCard() {

    const isMobile = useBreakpoint();
    if (isMobile == null) return <div>Loading</div>

    return isMobile ? <ShoppingCardMobile /> : <ShoppingCardDesktop />;
}

export default ShoppingCard