import React from 'react';

const ShinyDiv = ({ validation, color = 'red' }) => {
    return (
        <div className={`flex items-center justify-center bg-${color} bg-opacity-500 shine p-1 rounded`}>
            <p className='text-white text-lg'>{validation}</p>
        </div>
    );
};

export default ShinyDiv;
