import { toast } from "react-hot-toast";


export const showSuccessToast = (message) => {
    toast.success(message, {
        icon: '👏',
        style: {
            borderRadius: '10px',
            color: '#fff',
            background: '#333',
        },
    });
};

export const showErrorToast = (message) => {
    toast.error(message, {
        icon: '❌',
        style: {
            borderRadius: '10px',
            color: '#fff',
            background: '#333',
        },
    });
};

// showWarningToast
export const showWarningToast = (message) => {
    toast.warn(message, {
        icon: '⚠️',
        style: {
            borderRadius: '10px',
            color: '#fff',
            background: '#333',
        },
    });
};

export const showLoadingToast = (message) => {
    return toast.loading(message, {
        icon: '👀⌚',
        style: {
            borderRadius: '10px',
            color: '#fff',
            background: '#333',
        },
    });
};
