import React from 'react';
import { AlertCircle, Search, ShoppingBag, ArrowLeft } from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';

const OrderNotFoundPage = () => {


    const navigate = useNavigate();

    const { id } = useParams();

    const defaultMessage = encodeURIComponent(`السلام عليكم ورحمة الله وبركاته،

        أنا أواجه مشكلة في العثور على طلبي. تفاصيل المشكلة:
        
        • رقم الطلب (إن وجد): ${id || '[رقم الطلب غير متوفر]'}
        • تاريخ محاولة البحث: ${new Date().toLocaleDateString('ar-SA')}
        • المشكلة: لم أتمكن من العثور على تفاصيل طلبي في حسابي
        
        هل يمكنكم مساعدتي في التحقق من حالة طلبي والتأكد من وجوده؟ إذا كان هناك أي معلومات إضافية مطلوبة، فأنا على استعداد لتقديمها.
        
        شكراً لكم على المساعدة والاهتمام.`);

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center ">
            <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
                <div className="text-center ">
                    <div className='flex justify-center'>
                        <AlertCircle className="h-16 w-16 text-yellow-500" />
                    </div>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">عذراً، لم يتم العثور على الطلب</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        لم نتمكن من العثور على الطلب الذي تبحث عنه. قد يكون هذا بسبب:
                    </p>
                </div>

                <ul className="mt-4 text-sm text-gray-600 list-disc list-inside space-y-2">
                    <li>رقم الطلب غير صحيح</li>
                    <li>تم حذف الطلب أو إلغاؤه</li>
                    <li>ليس لديك صلاحية للوصول إلى هذا الطلب</li>
                </ul>

                <div className="mt-8 space-y-4">
                    <Link
                        to="/orders"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <Search className="h-5 w-5 mr-2" />
                        البحث عن طلباتي
                    </Link>

                    <Link
                        to="/"
                        className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <ShoppingBag className="h-5 w-5 mr-2" />
                        العودة للتسوق
                    </Link>

                    <button
                        onClick={() => navigate(-1)}
                        className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        العودة للصفحة السابقة
                    </button>
                </div>

                <div className="mt-6 text-center">
                    <p className="text-sm text-gray-500">
                        إذا كنت تعتقد أن هناك خطأ، يرجى{' '}
                        <a
                            href={`https://api.whatsapp.com/send?phone=249915903708&text=${defaultMessage}`}
                            className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            الاتصال بدعم العملاء
                        </a>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OrderNotFoundPage;