import { ReceiptTextIcon, RefreshCcwIcon, XIcon } from "lucide-react";
import React, { useState } from "react";
import BottomSheet from "../../../components/mobile/BottomSheet";
import useFetchData from "../../../hooks/useFetchData";

const Receipt = () => {
  const [showReceipt, setShowReceipt] = useState(false);

  const { data: receipts, refetch, isLoading } = useFetchData("/receipts");
  const ToggleModal = () => setShowReceipt(!showReceipt);
  return (
    <>
      <ReceiptTextIcon
        className="text-gray-400 cursor-pointer"
        onClick={ToggleModal}
      />

      <BottomSheet
        onClose={ToggleModal}
        isOpen={showReceipt}
        postion="center"
        title="الفواتير"
      >
        <div className=" items-center">
          <div className="flex justify-between">
            <h3 className="text-lg font-bold">الفواتير</h3>
            <div className="flex items-center space-x-2">
              <button className="text-gray-400">
                <XIcon />
              </button>
              {/* تحديث */}
              <button onClick={refetch} className="text-gray-400">
                <RefreshCcwIcon />
              </button>
            </div>
          </div>
          <div className="flex flex-col p-4 w-full items-center">
            <div className="flex flex-col w-full">
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-right">اسم العميل</th>
                    <th className="py-3 px-6 text-right">رقم العميل</th>
                    <th className="py-3 px-6 text-right">قيمة الطلب</th>
                    <th className="py-3 px-6 text-right">رقم الطلب</th>
                    <th className="py-3 px-6 text-right">التاريخ</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {receipts.map((receipt) => (
                    <tr className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-6 text-right">
                        {receipt?.user?.name}
                      </td>
                      <td className="py-3 px-6 text-right">
                        {receipt?.user?.phone}
                      </td>
                      <td className="py-3 px-6 text-right">
                        {receipt?.total.toFixed(2)} ريال
                      </td>
                      <td className="py-3 px-6 text-right">#{receipt?.id}</td>
                      <td className="py-3 px-6 text-right">
                        {new Date(receipt?.created_at).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

export default Receipt;
