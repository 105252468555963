import React, { useEffect, useState } from "react";
import ProductCard from "../../components/mobile/ProductCard";
import GeneralServices from "../../services/GeneralServices";
import ProductHomeSkeletonLoader from "./ProductHomeSkeletonLoader";
import useTitle from "../../hooks/useTitle";
import ImageCarousel from "../../components/ImageCarousel";

function HomeMobile() {
  const [products, setProducts] = useState([]);
  const [offers, setOffers] = useState([]);

  const [images, setImages] = useState([]);

  const { setTitle } = useTitle();
  // التصنيفات
  const service = new GeneralServices();

  const fetchProducts = async () => {
    try {
      const { data } = await service.get("products");
      setProducts(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetcOffershProducts = async () => {
    try {
      const { data } = await service.get("offers");
      setOffers(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchImages = async () => {
    try {
      const data = await service.get("slideshow");
      setImages(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchImages();
    fetcOffershProducts();
    setTitle("العروض");
  }, []);

  const loading = () => (
    <div className="grid grid-cols-2 gap-4 mx-2 mb-2 mt-2">
      <ProductHomeSkeletonLoader />
      <ProductHomeSkeletonLoader />
      <ProductHomeSkeletonLoader />
      <ProductHomeSkeletonLoader />
      <ProductHomeSkeletonLoader />
      <ProductHomeSkeletonLoader />
      <ProductHomeSkeletonLoader />
    </div>
  );

  return (
    <div className="mt-[10px]">
      <div className=" rounded-xl shadow-lg mx-2 overflow-hidden">
        <ImageCarousel images={images} />
      </div>

      <div>
        <h1 className="m-2 text-rose-500  font-semibold text-2xl">العروض</h1>

        <div className="grid grid-cols-2 gap-4 flex-col flex pl-2">
          {offers.map((product) => (
            <ProductCard
              key={product.id}
              // size={90}
              offer={true}
              {...product}
              product={product}
            />
          ))}
        </div>
      </div>
      {/* {products.length === 0 ? loading() : null}
            <h1 className='m-2 text-rose-500  font-semibold border-b-2 mt-2 text-2xl'>المنتجات</h1>
            <div className="grid grid-cols-2 gap-4 mx-2 mb-2">

                {products.map((product) => (
                    <ProductCard key={product.id} {...product} product={product} />
                ))}
            </div> */}

      <div className="h-[150px]"></div>
    </div>
  );
}

export default HomeMobile;
