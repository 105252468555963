import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContextProvider';
import Modal from '../Modal';
import Login from '../../pages/Auth/Login';
import OTP from '../../pages/Auth/OTP';
import { useNavigate } from 'react-router-dom';
import Join from '../../pages/Auth/Join';
import ForgetPassword from '../../pages/Auth/ForgetPassword';


function ProfileIcon({ screen = 'md' }) {


  const navigator = useNavigate()



  const { user, setUser } = useContext(AuthContext);

  const [modelType, setModelType] = useState(null);

  const [firstRun, setFirstRun] = useState(true)


  const handleProfile = () => {



    if (user !== null) {
      console.log('user', user)
      if (user.user.otp === null) {

        navigator('/profile');
      } else {
        setModelType('otp');
      }
    } else {
      setModelType('login');
    }

    // if (user && user.otp === null) {
    //   setModelType('otp');
    // } else if (!user) {
    //   setModelType('login');
    // } else {

    //   setModelType(null);
    //   navigator('/profile');
    // }
  }

  const onClose = () => {

    setModelType(null)
  }


  useEffect(() => {
    // update data form local storage
    const localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser));
    } else {
      setUser(null)
    }
  }, [])

  return (
    <>
      {modelType == 'join' && <Join setModelType={setModelType} onClose={onClose} />}
      {modelType == 'login' && <Login setModelType={setModelType} onClose={onClose} />}
      {modelType == 'forget-password' && <ForgetPassword setModelType={setModelType} onClose={onClose} />}
      {modelType == 'otp' && <OTP onClose={onClose} />}
      {screen == 'md' && <div className='cursor-pointer w-10 mx-1 h-10 justify-center items-center flex rounded-full bg-gray-200' onClick={() => handleProfile()}>
        <img width="20" src='/icons/profile.png' />
      </div>}
      {screen == 'sm' && <div className='nav-link flex flex-col items-center' onClick={() => handleProfile()}>
        <img width="25" height="25" src="https://img.icons8.com/stickers/100/attendance-mark.png" alt="Account" />
        <p class="text-white font-light text-center">الحساب</p>
      </div>}
    </>
  )
}

export default ProfileIcon