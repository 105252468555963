import React, { useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";

function Options({ options, setOptions }) {
  const { data, isLoading } = useFetchData("/additional/options");

  /**
   * Handles the click on an option in the list of options.
   * If the option is already in the list of selected options, it is removed.
   * If the option is not in the list of selected options, it is added.
   * @param {Object} option - The option that was clicked.
   */
  const handleOptionClick = (option) => {
    if (options.includes(option)) {
      // Remove the option from the list of selected options if it is already there.
      setOptions(options.filter((o) => o.id !== option.id));
    } else {
      // Add the option to the list of selected options if it is not already there.
      setOptions([...options, option]);
    }
  };

  const handleOptionChange = (option) => {
    if (options.includes(option)) {
      setOptions(options.filter((o) => o.id !== option.id));
    } else {
      setOptions([...options, option]);
    }
  };

  useEffect(() => {
    console.log("options", options);
  }, [options]);

  // Ensure options are passed correctly from the parent component
  useEffect(() => {
    setOptions(options); // Ensure the state is updated with the latest options
  }, [options]);

  return (
    <div>
      <div className="flex flex-col space-y-2 bg-white p-4 rounded-lg shadow-lg">
        <div className="flex items-center justify-between">
          <span className="text-lg font-semibold">الخيارات</span>
        </div>
        <div>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div>
              {data.map((option) => (
                <div
                  key={option.id}
                  className="flex items-center space-x-2 bg-gray-50 p-2 rounded-lg shadow-md hover:bg-gray-200 transition duration-150 ease-in-out"
                >
                  <input
                    type="checkbox"
                    id={option.id}
                    name={option.name}
                    checked={options.some((o) => o.id === option.id)} // Ensure correct checked state
                    onChange={() => handleOptionChange(option)}
                    className="form-checkbox h-5 w-5 text-gray-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor={option.id}
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {option.name} -
                    {(option.price * 1.15).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    ريال
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Options;
