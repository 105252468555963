import React from "react";

function Button({
  onClick,
  text,
  loading,
  width = "100%",
  font = "semibold",
  bg = "rose",
  rightIcon = null,
  leftIcon = null,
  disabled = false,
}) {
  return (
    <div className="flex justify-center mt-2 select-none group">
      <div
        onClick={!loading || disabled ? onClick : undefined}
        className={`border cursor-pointer  w-[80%] p-2 rounded-xl mt-2 text-center ${
          disabled ? `cursor-not-allowed opacity-90 bg-red-400` : `bg-${bg}-500`
        } font-${font} text-white`}
        style={{
          width,
          opacity: loading ? 0.6 : 1,
          pointerEvents: loading ? "none" : "auto",
        }}
      >
        <p className="flex items-center justify-center gap-3">
          {leftIcon}
          {loading ? "جارى المعالجه ..." : text}
          {rightIcon}
        </p>
      </div>
    </div>
  );
}

export default Button;
