import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem("isAuthenticated")) || false
  );

  const [title, setTitle] = useState("");

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [card, setCard] = useState({
    products: [],
    options: [],
    idDelivery: false,
    deliveryTime: null,
    deliveryDate: null,
    paymentMethod: null,
    deliveryType: null,
    coupon: null,
    optionsTotal: 0,
    deliveryCost: 0,
    productTotal: 0,
    discountAmount: 0,
    source: [],
    total: 0,
    tax: 0,
    grandTotal: 0,
    auth: "",
  });

  useEffect(() => {
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
    localStorage.setItem("user", JSON.stringify(user));
  }, [isAuthenticated, user]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        card,
        setCard,
        title,
        setTitle,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
