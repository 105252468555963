import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContextProvider";
import { Link } from "react-router-dom";
import { Power, PowerOffIcon } from "lucide-react";
import { getProductQuantity } from "../../utils/utility";

const ProductCard = ({
  image,
  name,
  price,
  label,
  product,
  size = 48,
  offer = false,
}) => {
  const { card, setCard } = useContext(AuthContext);
  const data = product;

  const handleAddToCard = () => {
    const isProductExist =
      card.products && card.products.some((product) => product.id === data.id);

    if (isProductExist) {
      const updatedProducts = card.products.map((product) =>
        product.id === data.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    } else {
      const updatedCard = {
        ...card,
        products: card.products
          ? [...card.products, { ...data, quantity: 1 }]
          : [{ ...data, quantity: 1 }],
      };
      setCard(updatedCard);
    }
  };
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden relative">
      {offer && (
        <div className="bg-yellow-500 text-white block absolute top-10 rounded-l-lg z-10 text-center w-fit px-2">
          مميز
        </div>
      )}
      <div className="relative">
        <Link to={`/products-details/${data.id}`}>
          {image ? (
            <img
              src={image}
              alt={name}
              className={`w-full h-${size} object-cover`}
            />
          ) : (
            <div className="w-full h-48 bg-gray-300" />
          )}

          <div className="absolute top-0 right-0 bg-gray-700 text-white px-2 py-[2px] rounded-bl-lg text-sm">
            {label}
          </div>
        </Link>
      </div>
      <div className="p-1">
        <Link to={`/products-details/${data.id}`}>
          <h3 className="text-[14px] font-medium mb-2 text-left">{name}</h3>
        </Link>
        <div className="flex justify-between items-center  w-full">
          <div className="text-right">
            <p className="text-[14px] font-medium text-[#818B9D]">
              {((price || 0) * 1.15).toFixed(0)} ر.س
            </p>
          </div>

          {product.status_order !== "active" ? (
            <span
              className="bg-yellow-400 text-white px-1 cursor-pointer select-none  flex items-center gap-1 rounded-full text-[14px]"
              style={{ whiteSpace: "nowrap" }}
            >
              <PowerOffIcon className="w-4 h-4" />
              نفذت الكميه
            </span>
          ) : getProductQuantity(card, product.id) == 0 ? (
            <span
              onClick={handleAddToCard}
              className="bg-[#DD3F61] text-white px-1 cursor-pointer select-none  rounded-full text-[14px]"
              style={{ whiteSpace: "nowrap" }}
            >
              + أضف
            </span>
          ) : (
            <span
              className="bg-green-500 text-white px-1 cursor-pointer select-none  rounded-full text-[14px]"
              style={{ whiteSpace: "nowrap" }}
            >
              مضاف
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
