import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useEffect } from "react";
import { AuthContext } from "../contexts/AuthContextProvider";

const ProductModal = ({ title, children, onClose, size = null, data }) => {
  const { card, setCard } = useContext(AuthContext);

  const modalRef = useRef();

  const productInCard = card.products.find((product) => product.id === data.id);

  const handleAddToCard = () => {
    const isProductExist =
      card.products && card.products.some((product) => product.id === data.id);

    if (isProductExist) {
      const updatedProducts = card.products.map((product) =>
        product.id === data.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    } else {
      const updatedCard = {
        ...card,
        products: card.products
          ? [...card.products, { ...data, quantity: 1 }]
          : [{ ...data, quantity: 1 }],
      };
      setCard(updatedCard);
    }
  };

  const handleDecreaseQuantity = () => {
    const productInCard = card.products.find(
      (product) => product.id === data.id
    );

    if (productInCard && productInCard.quantity > 1) {
      // Decrease the quantity by 1
      handleQuantityChange(data.id, productInCard.quantity - 1);
    } else {
      // Quantity is already 1, remove the product
      const updatedProducts = card.products.filter(
        (product) => product.id !== data.id
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    }
  };

  const handleIncreaseQuantity = () => {
    const productInCard = card.products.find(
      (product) => product.id === data.id
    );

    // Increase the quantity by 1
    handleQuantityChange(
      data.id,
      productInCard ? productInCard.quantity + 1 : 1
    );
  };

  const handleQuantityChange = (productId, newQuantity) => {
    const updatedProducts = card.products.map((product) =>
      product.id === productId ? { ...product, quantity: newQuantity } : product
    );

    setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target === modalRef.current) {
        onClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
      ref={modalRef}
    >
      <div className={`modal-dialog  modal-dialog-centered`} role="document">
        <div className="modal-content rounded-lg overflow-hidden">
          <div
            className="bg-gray-500 rounded p-2 w-8 h-8 absolute left-2 top-2 text-white font-semibold close cursor-pointer"
            onClick={onClose}
          >
            X
          </div>
          <img
            className="rounded-b-2xl"
            src="https://images.unsplash.com/photo-1448907503123-67254d59ca4f?q=80&w=1738&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
          <div className=" p-3">
            <div className="justify-between row my-3">
              <div className="col">
                <h1 className="font-semibold text-2xl">{data?.name}</h1>
                <p className="text-gray-500">{data?.description}</p>
              </div>
              <div className="bg-gray-50 rounded p-2 ">
                <p>لحوم الضان</p>
              </div>
            </div>

            <div className="justify-between row my-3">
              <h1 className="font-semibold text-rose-500 text-2xl">
                {data?.price} <span className="text-[10px]">ريال</span>
              </h1>

              <div className="bg-gray-100 rounded px-4 py-2 justify-center items-center flex  ">
                <p>{data?.label}</p>
              </div>
            </div>

            <div className="justify-between row my-3">
              <div className="col">
                <h1 className="font-semibold text-2xl text-gray-600">
                  التعليقات
                </h1>
              </div>
              <div className="bg-gray-50 rounded p-2 ">
                <p>عرض الكل</p>
              </div>
            </div>

            <div className="row justify-between">
              <button
                onClick={handleAddToCard}
                className="bg-rose-500 text-white p-2 rounded"
              >
                اضافة الى سله المشتريات
              </button>
              <div className="col" />

              {productInCard && (
                <div className="row">
                  <button
                    onClick={handleDecreaseQuantity}
                    className="w-8 h-8 font-semibold bg-rose-100 rounded text-rose-500"
                  >
                    -
                  </button>

                  <input
                    className="border w-8 mx-1 text-center rounded h-8 justify-center items-center flex"
                    value={productInCard ? productInCard.quantity : 0}
                    readOnly
                  />

                  <button
                    onClick={handleIncreaseQuantity}
                    className="w-8 h-8 font-semibold bg-gray-100 rounded text-gray-500"
                  >
                    +
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProductModal;
