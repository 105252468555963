import React, { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContextProvider'
import useBreakpoint from '../../hooks/useBreakpoints';
import ProfilePageDesktop from './desktop/ProfilePageDesktop';
import UserProfileMobile from './mobile/UserProfileMobile';

function ProfilePage() {


    const { user, setUser } = useContext(AuthContext);
    // return <UserProfilePage />
    const isMobile = useBreakpoint();
    if (isMobile === null) return <div>Loading</div>

    return isMobile ? <UserProfileMobile /> : <ProfilePageDesktop />


}

export default ProfilePage