import React, { useState, useEffect } from 'react';
import HomeDesktop from './HomeDesktop';
import HomeMobile from './HomeMobile';
import useBreakpoint from '../../hooks/useBreakpoints';

function HomePage() {


    const isMobile = useBreakpoint();
    if (isMobile === null) return <div className='text-center h-screen w-full'>Loading</div>

    return isMobile ? <HomeMobile /> : <HomeDesktop />;
}

export default HomePage;