import React, { useEffect, useState } from "react";
import GeneralServices from "../../../services/GeneralServices";
import Modal from "../../../components/Modal";
import SideModal from "../../../components/SideModal";
import Address from "../../address/component/Address";
import { useNavigate } from "react-router-dom";

function DefaultAddress() {
  const navigate = useNavigate();

  const [defaultAddress, setDefaultAddress] = useState({});

  const [showModal, setShowModal] = useState(false);

  const service = new GeneralServices();

  const fetchDefaultAddress = async () => {
    try {
      const response = await service.get("default-address");

      if (!response) {
        navigate("/address/create");
      } else {
        setDefaultAddress(response);
      }
    } catch (error) {}
  };

  const handleChangeAddress = () => {
    setShowModal(true);
  };

  const [address, setAddress] = useState([]);

  const fetchAddress = async () => {
    try {
      const res = await service.get("addresses");
      setAddress(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchDefaultAddress();
    fetchAddress();
  }, []);

  useEffect(() => {
    fetchDefaultAddress();
    fetchAddress();
  }, [showModal]);

  const closeModel = () => {
    setShowModal(false);
  };
  return (
    <>
      {showModal && (
        <SideModal onClose={closeModel}>
          <div className="flex justify-between">
            <p>العنواين</p>
            <p
              className="cursor-pointer bg-rose-500 text-white p-1 w-[30px] h-[30px] flex justify-center it-c text-lg rounded-lg"
              onClick={closeModel}
            >
              x
            </p>
          </div>
          <div>
            {address &&
              address.map((item) => (
                <Address setAddress={setAddress} data={item} key={item.id} />
              ))}
          </div>
        </SideModal>
      )}

      {/* {showModal && <Model></Model>} */}

      <div>
        <div className="row justify-between my-3">
          <h1 className="font-semibold">عنوان التوصيل</h1>
          <h1
            onClick={handleChangeAddress}
            className="text-rose-500 cursor-pointer"
          >
            تغير
          </h1>
        </div>

        <div className="row items-center">
          <img src="icons/map.png" />
          <div className="mx-2">
            <h1 className="font-semibold">
              {defaultAddress?.name ?? "الرجاء اضافة عنوان"}
            </h1>
            <p>{defaultAddress?.description}</p>
          </div>
          <div className="col" />
          {/* <div className='row items-center'>
                        <img className='mx-1' src='icons/edit.png' />
                        <img className='mx-1' src='icons/delete.png' />
                    </div> */}
        </div>
      </div>
    </>
  );
}

const Model = ({ children }) => {
  const close = () => {
    const model = document.getElementById("model");
    if (model) {
      model.style.display = "none";
    }
  };

  return (
    <div
      id="model"
      onClick={() => close()}
      className="flex items-center bg-opacity-40 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black outline-none focus:outline-none"
    >
      <div className="col-md-3 bg-white flex-1 h-screen">{children}</div>
    </div>
  );
};

export default DefaultAddress;
