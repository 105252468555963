import React, { useEffect, useState } from "react";
import GeneralServices from "../../services/GeneralServices";
import Modal from "../../components/Modal";
import useTitle from "../../hooks/useTitle";

function VideosPage() {
  const [videos, setVideos] = useState([]);

  const datas = [{ id: 1, url: "", bg: "" }];

  const service = new GeneralServices();

  const fetchVideos = async () => {
    try {
      const res = await service.get("videos");
      setVideos(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("الفيدوهات");
    document.title = "سهول - الفيدوهات";
  }, []);

  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="w-full bg-black  items-center justify-center flex">
          <div className="justify-center content-center text-center p-4">
            <h1 className="text-2xl font-semibold">الفيدوهات</h1>
            <p>نعرض لكن طريقه الذبح ونوع اللحوم</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {videos?.map((video, index) => (
            <Video key={index} data={video} />
          ))}
        </div>
      </div>
    </div>
  );
}

const Video = ({ data }) => {
  const [showModel, setShowModel] = useState(false);

  const handlOpenVideo = () => {
    onClose();
    console.log("ahmd");
    console.log("data", data);
  };

  const onClose = () => setShowModel(!showModel);

  return (
    <>
      {showModel && (
        <Modal onClose={onClose}>
          <iframe
            className="rounded-md"
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${data?.url.split("v=")[1]}`} // Ensure the correct embed URL
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" // Added fullscreen
            allowFullScreen
          ></iframe>
        </Modal>
      )}

      <div className="col-md-6 col-sm-12 p-4 ">
        <div
          style={{
            backgroundImage: `url('https://img.freepik.com/premium-photo/piece-meat-black-background_265515-1047.jpg')`,
            backgroundSize: "cover", // تغيير الحجم حسب الحاجة
            backgroundPosition: "center", // تغيير الوضع حسب الحاجة
            // قم باضافة لون اسود فوق الصورة
            // add overlay color
            backgroundColor: "rgba(0, 0, 0, 0.5)", // تغيير لون الخلفية حسب الحاجة
            height: "600px", // تحديد ارتفاع الصورة
            borderRadius: "10px", // حدد الزوايا حسب الحاجة
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // إضافة ظل حسب الحاجة
          }}
          className="h-[600px] w-full bg-green-500 rounded-lg shadow text-center justify-center items-center flex"
        >
          <img
            onClick={handlOpenVideo}
            className="h-16 w-16 cursor-pointer"
            src="icons/play.png"
          />
        </div>
      </div>
    </>
  );
};
export default VideosPage;
