import React, { useState, useEffect } from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import {
  Calendar,
  CalendarDays,
  Carrot,
  CarTaxiFront,
  CodeIcon,
  CreditCardIcon,
  Key,
  User2,
} from "lucide-react";

const CardInfo = ({ card, setCard, onClose }) => {
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const validateField = (field, value) => {
    switch (field) {
      case "name":
        return value.trim().length > 0 ? "" : "اسم حامل البطاقة مطلوب";
      case "number":
        return /^\d{16}$/.test(value) ? "" : "رقم البطاقة يجب أن يكون 16 رقمًا";
      case "cvc":
        return /^\d{3,4}$/.test(value) ? "" : "CVC يجب أن يكون 3 أو 4 أرقام";
      case "month":
        return /^(0[1-9]|1[0-2])$/.test(value)
          ? ""
          : "الشهر يجب أن يكون بين 01 و 12";
      case "year":
        const currentYear = new Date().getFullYear() % 100;
        const yearValue = parseInt(value, 10);
        return yearValue >= currentYear && yearValue <= currentYear + 10
          ? ""
          : "السنة غير صالحة";
      default:
        return "";
    }
  };

  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setCard((prevCard) => ({
      ...prevCard,
      source: { ...prevCard.source, [field]: value },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: validateField(field, value),
    }));
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(card.source).every(
      (value) => value.trim() !== ""
    );
    const noErrors = Object.values(errors).every((error) => error === "");
    setIsValid(allFieldsFilled && noErrors);
  }, [card, errors]);

  const handleSubmit = () => {
    if (isValid) {
      onClose();
    }
  };

  return (
    <div className="">
      <Input
        suffix={<User2 className="text-gray-400" />}
        value={card?.source?.name || ""}
        onChange={handleInputChange("name")}
        placeholder="اسم حامل البطاقة"
        error={errors.name}
      />

      <Input
        type="number"
        suffix={<CreditCardIcon className="text-gray-400" />}
        value={card?.source?.number || ""}
        onChange={handleInputChange("number")}
        placeholder="رقم البطاقة"
        error={errors.number}
      />

      <div className="">
        <div className="w-full">
          <Input
            type="number"
            // max length 3 digits
            maxlength={3}
            suffix={<Key className="text-gray-400" />}
            value={card?.source?.cvc || ""}
            onChange={handleInputChange("cvc")}
            placeholder="CVC"
            error={errors.cvc}
          />
        </div>
        <div className="w-full">
          <div className="w-full">
            <Input
              type="number"
              suffix={<CalendarDays className="text-gray-400" />}
              value={card?.source?.month || ""}
              onChange={handleInputChange("month")}
              placeholder="MM"
              error={errors.month}
            />
          </div>
          <div className="w-full">
            <Input
              suffix={<Calendar className="text-gray-400" />}
              value={card?.source?.year || ""}
              onChange={handleInputChange("year")}
              type="number"
              placeholder="YY"
              error={errors.year}
            />
          </div>
        </div>
      </div>

      <Button onClick={handleSubmit} disabled={!isValid} text={"تأكيد"} />
    </div>
  );
};

export default CardInfo;
