import React, { useContext } from "react";
import { LogOut } from "lucide-react";
import { AuthContext } from "../../../contexts/AuthContextProvider";

const Logout = () => {
  const { setUser } = useContext(AuthContext);
  const handleLogout = async () => {
    try {
      await localStorage.removeItem("token");
      await localStorage.removeItem("user");
      setUser(null);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <LogOut onClick={handleLogout} className="text-gray-400 cursor-pointer" />
  );
};

export default Logout;
