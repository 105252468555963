// useTitle.js
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContextProvider';

const useTitle = () => {
    const { title, setTitle } = useContext(AuthContext);
    return { title, setTitle };
};

export default useTitle;
