import React, { useContext, useEffect, useState } from "react";
import {
  AlertCircle,
  Check,
  Copy,
  ChevronDown,
  ChevronUp,
  MessageCircle,
} from "lucide-react";

const BankTransferPayment = ({ card }) => {
  const [copiedInfo, setCopiedInfo] = useState(null);
  const [expandedBank, setExpandedBank] = useState(null);

  const bankAccounts = [
    {
      bank: "بنك الراجحي",
      account: "609608010943459",
      iban: "SA9380000609608010943459",
    },
    {
      bank: "بنك البلاد",
      account: "900133794780008",
      iban: "SA121500090013379478800",
    },
    {
      bank: "بنك الجزيرة",
      account: "3495019455001",
      iban: "SA18601000034950194550",
    },
  ];

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedInfo({ text, type });
      setTimeout(() => setCopiedInfo(null), 3000);
    });
  };

  const toggleExpand = (bankName) => {
    setExpandedBank(expandedBank === bankName ? null : bankName);
  };

  useEffect(() => {
    console.log("card", card);
  }, [card]);

  return card.paymentMethod == 7 ? (
    <div className=" mx-auto bg-white rounded-xl shadow-md overflow-hidden  m-4">
      <div className=" ">
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-4">
          الدفع عن طريق التحويل البنكي
        </div>
        <p className="mt-2 text-gray-500 mb-4">
          يرجى اختيار أحد الحسابات التالية لإتمام عملية التحويل
        </p>
        <div className="space-y-4">
          {bankAccounts.map((bank, index) => (
            <div key={index} className="bg-gray-100 rounded-lg overflow-hidden">
              <div
                className="flex items-center justify-between p-4 cursor-pointer"
                onClick={() => toggleExpand(bank.bank)}
              >
                <p className="text-lg font-semibold text-gray-700">
                  {bank.bank}
                </p>
                {expandedBank === bank.bank ? (
                  <ChevronUp className="w-5 h-5" />
                ) : (
                  <ChevronDown className="w-5 h-5" />
                )}
              </div>
              {expandedBank === bank.bank && (
                <div className="p-4 bg-white">
                  <div className="mb-2">
                    <p className="text-sm text-gray-600">رقم الحساب:</p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="text-gray-800">{bank.account}</p>
                      <button
                        onClick={() => copyToClipboard(bank.account, "account")}
                        className="bg-indigo-500 text-white py-1 rounded text-sm hover:bg-indigo-600 transition duration-300 flex items-center"
                      >
                        {copiedInfo?.text === bank.account &&
                        copiedInfo?.type === "account" ? (
                          <Check className="w-4 h-4 mr-1" />
                        ) : (
                          <Copy className="w-4 h-4 mr-1" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">رقم الآيبان:</p>
                    <div className="flex items-center justify-between mt-1">
                      <p className="text-gray-800">{bank.iban}</p>
                      <button
                        onClick={() => copyToClipboard(bank.iban, "iban")}
                        className="bg-indigo-500 text-white  py-1 rounded text-sm hover:bg-indigo-600 transition duration-300 flex items-center"
                      >
                        {copiedInfo?.text === bank.iban &&
                        copiedInfo?.type === "iban" ? (
                          <Check className="w-4 h-4 mr-1" />
                        ) : (
                          <Copy className="w-4 h-4 mr-1" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div
          className="mt-6 bg-yellow-100 border-s-4 border-yellow-500 text-yellow-700 p-4 rounded"
          role="alert"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-yellow-500" />
            </div>
            <div className="mr-3">
              <p className="font-medium">تنبيهات هامة:</p>
              <ul className="list-disc list-inside text-sm space-y-1 mt-2">
                <li>يرجى التأكد من إدخال رقم الطلب في تفاصيل التحويل</li>
                <li>
                  بعد إتمام التحويل، قم بإرسال إشعار التحويل مع رقم الطلب إلى
                  رقم الواتساب الخاص بنا
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-4 bg-green-100 border-s-4 border-green-500 text-green-700 p-4 rounded flex items-start">
          <MessageCircle className="h-5 w-5 text-green-500 mr-3 mt-1" />
          <div>
            <p className="font-medium">إرسال إشعار التحويل:</p>
            <p className="text-sm mt-1">
              لإتمام عملية الطلب، يرجى إرسال صورة إشعار التحويل مع رقم الطلب إلى
              رقم الواتساب:
            </p>
            <a
              href="https://wa.me/00966591144449"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-600 hover:text-green-800 font-medium mt-2 text-right inline-block"
            >
              <span>00966591144449 </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default BankTransferPayment;
