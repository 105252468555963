import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import GeneralServices from "../../services/GeneralServices";
import {
  FacebookIcon,
  Globe,
  Instagram,
  YoutubeIcon,
  Twitter,
  Share2,
  Facebook,
  Youtube,
  Save,
} from "lucide-react";
// import { QRCodeSVG } from 'qrcode.react';

import html2canvas from "html2canvas";
import { showSuccessToast } from "../../utils/toastUtils";
import QRCode from "react-qr-code";
import Button from "../../components/Button";
import OrderNotFoundPage from "./OrderNotFoundPage";

function Print() {
  return <PrintMobile />;
}

const PrintMobile = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const componentRef = useRef(null);

  const service = new GeneralServices();

  const fetchInvoice = async () => {
    setLoading(true);
    try {
      const { data } = await service.get(`order/${id}`);
      console.log("data", data);
      setInvoice(data);
    } catch (error) {
      console.error("Error fetching invoice:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, [id]);

  // const isMobile = () => {
  //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  // };

  // ifadsnrlp2024

  const shareToWhatsApp = async () => {
    if (componentRef.current) {
      try {
        const canvas = await html2canvas(componentRef.current);
        const imageData = canvas.toDataURL("image/png");
        const tempLink = document.createElement("a");
        tempLink.href = imageData;
        tempLink.download = `invoice-${invoice.id}.png`;
        tempLink.click();

        showSuccessToast("تم حفظ الصورة بنجاح!");

        const whatsappUrl = `https://api.whatsapp.com/send?text=Check out this invoice!`;
        window.open(whatsappUrl, "_blank");
      } catch (error) {
        console.error("Error sharing screenshot:", error);
      }
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <p>جارى التحميل ...</p>
      </div>
    );

  if (!invoice) return <OrderNotFoundPage />;

  return (
    <div ref={componentRef} className="flex flex-col min-h-screen">
      <div className="flex justify-center items-center p-4 ">
        <img className="h-[100px]" src="/icons/black-logo.jpg" alt="Logo" />

        {/* <button
                    onClick={shareToWhatsApp}
                    className="gap-1  bg-green-500 text-white rounded-full"
                >
                    <div className='flex items-center gap-1 mx-3'>
                        <Save size={18} />
                        <span>حفظ</span>
                    </div>
                </button> */}
      </div>

      <div className="p-4 flex-grow">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h1 className="text-2xl font-bold mb-2">
              فاتورة رقم #{invoice?.id}
            </h1>
            <p>الى السيد: {invoice?.customer?.name}</p>
            <p>جوال رقم : {invoice?.customer?.phone}</p>
            <p>العنوان : {invoice?.address?.name}</p>
          </div>

          {invoice.qrcode && (
            <QRCode value={invoice.qrcode} className="h-24 w-24" />
          )}
        </div>

        <hr className="border-gray-300 border-dashed my-4" />
        <h2 className="text-lg font-semibold mb-3">تفاصيل الطلب</h2>

        {invoice?.items?.map((item, index) => (
          <div
            key={index}
            className="flex justify-between text-gray-700 items-center mb-2"
          >
            <div>
              <p>
                <span className="font-medium">{item.product} </span>
                <span className="text-sm">x{item.quantity}</span>
              </p>
              <p className="text-sm text-gray-500">{item.CuttingType}</p>
            </div>
            <p className="font-medium">{item.total} ريال</p>
          </div>
        ))}

        <hr className="border-gray-300 border-dashed my-4" />

        {[
          // { label: 'رسوم التوصيل', value: invoice?.delivery_fee ?? 0 },
          { label: "الضريبة", value: invoice?.tax, bold: true },
          { label: "صافي الفاتورة", value: invoice?.total, bold: true },
          { label: "طريقة الدفع", value: invoice?.payment_method ?? "لا توجد" },
          //   { label: "التوصيل", value: invoice?.delivery_type ?? "لا توجد" },
          {
            label: invoice.discount > 0 ? "الخصم" : "",
            value: invoice.discount > 0 ? invoice?.discount : "",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center border-b border-gray-300 border-dashed py-2"
          >
            <p className={item.bold ? "font-semibold" : ""}>{item.label}</p>
            <p className={item.bold ? "font-semibold" : ""}>
              {item.value} {typeof item.value === "number" ? "ريال" : ""}
            </p>
          </div>
        ))}
      </div>

      <div className="bg-black text-white px-2 py-1">
        <p className="mb-2">
          <span>شركة سهول للتجارة</span>
          <span> - رقم ضريبي: </span>
          <span>310777436400003</span>
        </p>
        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-center">
            <p>sehoool @</p>
            {[
              { Icon: Instagram, href: "https://www.instagram.com/sehoool/" },
              { Icon: Twitter, href: "https://twitter.com/sehoool" },
              { Icon: Facebook, href: "https://www.facebook.com/sehoool" },
              { Icon: Youtube, href: "https://www.youtube.com/sehoool" },
            ].map(({ Icon, href }, index) => (
              <a
                key={index}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon className="text-white h-5" />
              </a>
            ))}
          </div>
          <div className="flex gap-1 items-center ">
            <a
              href="https://sehoool.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.sehoool.com
            </a>
            <Globe className="text-white h-5" />
          </div>
        </div>
      </div>
      <Link to={"/orders"}>
        <div className="p-2 bg-green-500 text-center text-md text-white">
          <p>العوده</p>
        </div>
      </Link>
    </div>
  );
};

const PrintDesktop = () => {
  const { id } = useParams();

  const [invoice, setInvoice] = useState({});

  const service = new GeneralServices();

  const fetchInvoice = async () => {
    try {
      const { data } = await service.get(`order/${id}`);
      console.log("data", data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  const invoiceData = {
    invoiceNumber: 12345,
    invoiceDate: new Date(),
    customerName: "John Doe",
    customerAddress: "123 Main Street, Anytown, USA",
    sellerName: "My Company",
    sellerAddress: "456 Elm Street, Anytown, USA",
    products: [
      {
        description: "Product 1",
        quantity: 2,
        unitPrice: 100,
        totalPrice: 200,
      },
      {
        description: "Product 2",
        quantity: 1,
        unitPrice: 50,
        totalPrice: 50,
      },
      {
        description: "Product 3",
        quantity: 3,
        unitPrice: 25,
        totalPrice: 75,
      },
    ],
    subtotal: 325,
    vatRate: 5,
    vatAmount: 16.25,
    total: 341.25,
  };

  return (
    <div className="container  p-4">
      <h1 className="text-2xl font-bold">فاتورة ضريبية</h1>

      <div className="flex justify-between mt-4">
        <div>
          <p className="font-bold">رقم الفاتورة:</p>
          <p>{invoiceData.invoiceNumber}</p>
        </div>
        <div>
          <p className="font-bold">تاريخ الفاتورة:</p>
          <p>{invoiceData.invoiceDate.toLocaleDateString()}</p>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-bold">معلومات المشتري</h2>
        <p>{invoiceData.customerName}</p>
        <p>{invoiceData.customerAddress}</p>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-bold">معلومات البائع</h2>
        <p>{invoiceData.sellerName}</p>
        <p>{invoiceData.sellerAddress}</p>
      </div>

      <div className="mt-4">
        <table className="w-full border-collapse border">
          <thead>
            <tr>
              <th className="border px-4 py-2 text-left">الوصف</th>
              <th className="border px-4 py-2 text-center">الكمية</th>
              <th className="border px-4 py-2 text-right">سعر الوحدة</th>
              <th className="border px-4 py-2 text-right">المجموع</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.products.map((product) => (
              <tr key={product.description}>
                <td className="border px-4 py-2">{product.description}</td>
                <td className="border px-4 py-2 text-center">
                  {product.quantity}
                </td>
                <td className="border px-4 py-2 text-right">
                  {product.unitPrice}
                </td>
                <td className="border px-4 py-2 text-right">
                  {product.totalPrice}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} style={{ textAlign: "right" }}>
                المجموع الفرعي:
              </td>
              <td className="border px-4 py-2 text-right">
                {invoiceData.subtotal}
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{ textAlign: "right" }}>
                ضريبة القيمة المضافة ({invoiceData.vatRate}%):
              </td>
              <td className="border px-4 py-2 text-right">
                {invoiceData.vatAmount}
              </td>
            </tr>
            <tr>
              <td colSpan={3} style={{ textAlign: "right" }}>
                الإجمالي:
              </td>
              <td className="border px-4 py-2 text-right">
                {invoiceData.total}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Print;
