import React from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useEffect } from 'react';

const Modal = ({ title, children, onClose, size = null }) => {
    const modalRef = useRef();
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target === modalRef.current) {
                onClose();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);
    return (
        <div className="modal " tabIndex="-1" role="dialog" style={{ display: 'block', "background": "rgba(0, 0, 0, 0.6)" }} ref={modalRef}>
            <div className={`modal-dialog ${size}  modal-dialog-centered`} role="document">
                <div className="modal-content rounded-2xl">
                    {/* <div className="modal-header">
                        <h4 className="modal-title"><i class="fas fa-list"></i> {title}</h4>
                        <button type="button" className="close" onClick={onClose}>
                            <span>&times;</span>
                        </button>
                    </div> */}
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Modal;
