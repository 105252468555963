import axios from "../api/axiosConfig";
import BaseService from "./BaseService";

class GeneralServices extends BaseService {
    /**
     * Perform a GET request.
     * @param {string} url - The URL to send the request to.
     * @param {Object} [config={}] - Additional Axios config options.
     * @returns {Promise<any>} The response data.
     * @throws {Error} If an error occurs during the request.
     */
    async get(url, config = {}) {
        try {
            const response = await axios.get(url, config);
            return response.data;
        } catch (error) {
            throw this.handleRequestError(error);
        }
    }

    /**
     * Perform a POST request.
     * @param {string} url - The URL to send the request to.
     * @param {Object} data - The data to send in the request body.
     * @param {Object} [config={}] - Additional Axios config options.
     * @returns {Promise<any>} The response data.
     * @throws {Error} If an error occurs during the request.
     */
    async post(url, data, config = {}) {
        try {
            const response = await axios.post(url, data, config);
            return response.data;
        } catch (error) {
            throw this.handleRequestError(error);
        }
    }

    /**
     * Perform a DELETE request.
     * @param {string} url - The URL to send the request to.
     * @param {Object} [config={}] - Additional Axios config options.
     * @returns {Promise<any>} The response data.
     * @throws {Error} If an error occurs during the request.
     */
    async delete(url, config = {}) {
        try {
            const response = await axios.delete(url, config);
            return response.data;
        } catch (error) {
            throw this.handleRequestError(error);
        }
    }

    /**
     * Perform a PUT request.
     * @param {string} url - The URL to send the request to.
     * @param {Object} data - The data to send in the request body.
     * @param {Object} [config={}] - Additional Axios config options.
     * @returns {Promise<any>} The response data.
     * @throws {Error} If an error occurs during the request.
     */
    async put(url, data, config = {}) {
        try {
            const response = await axios.put(url, data, config);
            return response.data;
        } catch (error) {
            throw this.handleRequestError(error);
        }
    }

    /**
     * Create a cancelable request.
     * @param {Function} requestMethod - The axios request method to use (e.g., axios.get, axios.post).
     * @param {string} url - The URL to send the request to.
     * @param {Object} [config={}] - Additional Axios config options.
     * @returns {Object} An object containing the promise and a cancel function.
     */
    createCancelableRequest(requestMethod, url, config = {}) {
        const source = axios.CancelToken.source();
        const promise = requestMethod(url, { ...config, cancelToken: source.token });

        return {
            promise: promise.then(response => response.data).catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                    return null;
                }
                throw this.handleRequestError(error);
            }),
            cancel: () => source.cancel('Operation canceled by the user.')
        };
    }
}

export default GeneralServices;

// import axios from "../api/axiosConfig";
// import BaseService from "./BaseService";

// class GeneralServices extends BaseService {

//     /**
//      * Retrieve data base on url.
//      * @returns {Promise<Array>} Array of data.
//      * @throws {Error} If an error occurs during the request.
//      */
//     async get(url) {
//         try {
//             const response = await axios({
//                 url: url,
//                 method: "GET",
//             });

//             return response.data;
//         }
//         catch (error) {
//             this.handleRequestError(error);
//             return [];
//         }
//     }



//     /**
//      * Create a new data.
//      * @param {Object} data - The data object to create.
//      * @returns {Promise<Object|null>} The created data object if successful, null otherwise.
//      * @throws {Error} If an error occurs during the request.
//      */
//     async post(url, data, headers = {}) {
//         try {
//             const response = await axios({
//                 url: url,
//                 method: "POST",
//                 data: data,

//                 headers: headers,
//             });
//             return response.data;
//         } catch (error) {
//             this.handleRequestError(error);
//             return null;
//         }
//     }


//     async delete(url, data) {
//         try {
//             const response = await axios({
//                 url: url,
//                 method: "DELETE",
//                 data: data
//             });
//             return response.data;

//         } catch (error) {
//             this.handleRequestError(error);
//             return [];
//         }
//     }

//     async put(url, data) {
//         try {
//             const response = await axios({
//                 url: url,
//                 method: "PUT",
//                 data: data,
//             });
//             return response.data;
//         } catch (error) {
//             this.handleRequestError(error);
//             return null;
//         }
//     }



// }

// export default GeneralServices;
