import React, { useState } from "react";
import { ChevronLeft, ChevronRight, Calendar } from "lucide-react";

const months = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

const days = [
  "السبت",
  "الأحد",
  "الاثنين",
  "الثلاثاء",
  "الأربعاء",
  "الخميس",
  "الجمعة",
];

const DatePicker = ({ onDateSelect }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const getMonthDays = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      (prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1)
    );
  };

  const handleDateClick = (day) => {
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    setSelectedDate(selectedDate);
    onDateSelect(selectedDate);
    setIsOpen(false);
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getMonthDays(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    const calendar = [];

    for (let i = 0; i < firstDay; i++) {
      calendar.push(<div key={`empty-${i}`} className="text-center p-2"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected =
        selectedDate &&
        selectedDate.getDate() === day &&
        selectedDate.getMonth() === month &&
        selectedDate.getFullYear() === year;

      calendar.push(
        <div
          key={day}
          onClick={() => handleDateClick(day)}
          className={`text-center p-2 cursor-pointer hover:bg-pink-200 rounded-full
            ${isSelected ? "bg-pink-500 text-white" : ""}`}
        >
          {day}
        </div>
      );
    }

    return calendar;
  };

  return (
    <>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="border-[1px] p-2  w-full flex items-center gap-1 rounded-lg"
      >
        <Calendar size={24} className="text-[#5C6678]" />
        {selectedDate == null ? (
          <p>حدد اليوم</p>
        ) : (
          <p>{selectedDate.toLocaleDateString()}</p>
        )}
      </div>
      {isOpen && (
        <div className="absolute mt-2 w-[80%] bg-white rounded-lg shadow-lg z-10">
          <div className="flex justify-between items-center p-2 bg-pink-500 text-white rounded-t-lg">
            <ChevronRight
              onClick={handlePrevMonth}
              className="cursor-pointer"
            />
            <span>
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </span>
            <ChevronLeft onClick={handleNextMonth} className="cursor-pointer" />
          </div>
          <div className="grid grid-cols-7 gap-1 p-2">
            {days.map((day, index) => (
              <div
                key={day}
                className={`text-center text-xs text-gray-500 ${
                  day < currentDate.getDay()
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                {day}
              </div>
            ))}
            {renderCalendar()}
          </div>
          <button
            onClick={() => setIsOpen(false)}
            className="w-full p-2 bg-green-500 text-white rounded-b-lg hover:bg-green-600"
          >
            تأكيد
          </button>
        </div>
      )}
    </>
  );
};

export default DatePicker;
