import React, { useContext, useEffect, useState } from "react";
import {
  Search,
  Menu,
  ChevronLeft,
  ShoppingBag,
  User,
  AlertCircle,
  Phone,
  LogOut,
  Award,
  Lock,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import BottomSheet from "../../../components/mobile/BottomSheet";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import GeneralServices from "../../../services/GeneralServices";
import { showErrorToast } from "../../../utils/toastUtils";
import useTitle from "../../../hooks/useTitle";

const UserProfileMobile = () => {
  const { user } = useContext(AuthContext);

  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("الحساب");
    document.title = "سهول - الحساب";
  }, []);
  return (
    <div className="flex pb-[70px] flex-col h-screen bg-gray-100  font-arabic">
      <div className="">
        <AuthCard user={user} />
      </div>
      <main className="flex-1 w-full bg-white">
        <UserInfo />
        <MenuItems />
      </main>
    </div>
  );
};

const AuthCard = () => {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(true);

  const [defaultModelType, setDefaultModelType] = useState("login");

  const onClose = () => {
    if (user) setIsOpen(false);
  };

  useEffect(() => {
    // if not user then open auth card
    // if user has otp code set model type to otp
    if (!user) {
      setIsOpen(true);
    } else {
      if (user?.user?.otp !== null) {
        setDefaultModelType("otp");
      } else {
        setDefaultModelType("login");
      }
    }
  }, [user]);

  if (user && user.user.otp === null) return null;
  return (
    <BottomSheet
      postion="center"
      isTitle={false}
      isOpen={isOpen}
      onClose={onClose}
      title="الخيارات"
    >
      {defaultModelType === "login" && (
        <LoginCard modelType={setDefaultModelType} />
      )}
      {defaultModelType === "register" && (
        <RegisterCard modelType={setDefaultModelType} />
      )}
      {defaultModelType === "otp" && (
        <OtpCard modelType={setDefaultModelType} />
      )}
      {/* نسيت كلمه السر */}
      {defaultModelType === "forget-password" && (
        <ForgetPasswordCard modelType={setDefaultModelType} />
      )}
    </BottomSheet>
  );
};

const LoginCard = ({ modelType }) => {
  const [userData, setUserData] = useState({
    phone: "",
    password: "password",
  });

  const { user, setUser } = useContext(AuthContext);
  const [validation, setValidation] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const service = new GeneralServices();

  const handelSubmit = async () => {
    setLoading(true);
    setValidation([]);

    // update phone number with prefix
    const phoneWithPrefix = `966${userData.phone}`;
    // const phoneWithPrefix = `${userData.phone}`

    try {
      const res = await service.post("login", {
        phone: phoneWithPrefix,
        password: userData.password,
      });

      localStorage.setItem("user", JSON.stringify(res));
      console.log("res");

      if (res?.user?.otp !== null) {
        modelType("otp");
      }
      setUser(res);
    } catch (error) {
      if (error.validationData) {
        setValidation(error.validationData);
      }
      if (error.state == 401) {
        showErrorToast("عفوا بيانات التسجيل خاطئه");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p className="text-2xl font-bold">تسجيل الدخول</p>
      <span className="text-sm text-gray-300">
        قم بادخال رقم الهاتف اذا كان التسجيل او مره او كان لديك حساب مسبق
      </span>
      <div>
        <Input
          placeholder="50xxxxxxx"
          onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
          value={userData.phone}
          type="number"
          align={"text-right"}
          prefix={
            <div className=" flex items-center justify-center gap-2">
              <p className="text-gray-600 text-md">966</p>
              <img
                className="w-6 rounded-lg"
                src="https://media.istockphoto.com/id/847376560/photo/saudi-arabian-flag-painted-over-brick-wall.jpg?s=170667a&w=0&k=20&c=ec2QOltIFOdha2dxAkpFG_Vwsiln2VrhWFPPkFFEUeU="
              />
            </div>
          }
          // align='text-right'
          error={validation?.phone?.[0]}
        />
      </div>
      {/* <Input
        placeholder="كلمه السر"
        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
        value={userData.password}
        type="password"
        suffix={<Lock size={16} />}
        error={validation?.password?.[0]}
      /> */}
      <Button loading={isLoading} onClick={handelSubmit} text="تسجيل الدخول" />

      {/* <div className="flex items-center justify-between text-sm group mt-4 cursor-pointer gap-4">
        <p onClick={() => modelType("register")} className="text-rose-600">
          انشاء حساب
        </p>
        <p
          onClick={() => modelType("forget-password")}
          className="text-rose-600"
        >
          نسيت كلمه السر
        </p>
      </div> */}
    </>
  );
};

const UpdateProfile = ({ modelType }) => {
  const [userData, setUserData] = useState({
    name: "",
    password: "",
  });

  const { user, setUser } = useContext(AuthContext);
  const [validation, setValidation] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const service = new GeneralServices();

  const handelSubmit = async () => {
    setLoading(true);
    setValidation([]);

    try {
      const res = await service.post("update-profile", userData);
      console.log("res", res);
      // update name only in user local storage and state
      // setUser({ ...user, name: userData.name });

      // // update user in local storage
      // localStorage.setItem(
      //   "user",
      //   JSON.stringify({ ...user, name: userData.name })
      // );
      modelType();
    } catch (error) {
      if (error.validationData) {
        setValidation(error.validationData);
      } else {
        // if (error.state == 401) {
        //   showErrorToast("عفوا بيانات التسجيل خاطئه");
        //   modelType();
        //   setUser(null);
        //   localStorage.removeItem("user");
        // } else {
        // }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p>تحديث البيانات </p>
      <div>
        <Input
          placeholder="رقم الهاتف"
          onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
          value={userData.phone}
          type="number"
          suffix={<Phone size={16} />}
          // align='text-right'
          error={validation?.phone?.[0]}
        />
      </div>
      <Input
        placeholder="كلمه السر"
        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
        value={userData.password}
        type="password"
        suffix={<Lock size={16} />}
        error={validation?.password?.[0]}
      />
      <Button loading={isLoading} onClick={handelSubmit} text="تحديث" />
    </>
  );
};

const RegisterCard = ({ modelType }) => {
  const { user, setUser } = useContext(AuthContext);

  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    password: "",
  });

  const [validation, setValidation] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const service = new GeneralServices();

  const handleRegister = async () => {
    setValidation([]);
    setLoading(true);

    try {
      const res = await service.post("register", {
        name: userData.name,
        phone: `966${userData.phone}`,
        password: userData.password,
      });
      // save user in local storage
      localStorage.setItem("user", JSON.stringify(res));
      setUser(res);
      modelType("otp");
    } catch (error) {
      console.log("error", error);
      if (error.validationData) {
        setValidation(error.validationData);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Input
        placeholder="الاسم"
        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
        type="text"
        suffix={<User size={16} />}
        error={validation?.name?.[0]}
      />
      <Input
        error={validation?.phone?.[0]}
        placeholder="000 000 0000"
        onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
        type="number"
        align={"text-right"}
        prefix={
          <div className=" flex items-center justify-center gap-2">
            <p className="text-gray-600 text-md">966</p>
            <img
              className="w-6 rounded-lg"
              src="https://media.istockphoto.com/id/847376560/photo/saudi-arabian-flag-painted-over-brick-wall.jpg?s=170667a&w=0&k=20&c=ec2QOltIFOdha2dxAkpFG_Vwsiln2VrhWFPPkFFEUeU="
            />
          </div>
        }
      />
      <Input
        error={validation?.password?.[0]}
        placeholder="كلمه السر"
        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
        type="password"
        suffix={<Lock size={16} />}
      />
      <Button loading={isLoading} onClick={handleRegister} text="تسجيل جديد " />
      <div className="flex items-center justify-between text-sm group mt-4 cursor-pointer gap-4">
        <p
          onClick={() => modelType("forget-password")}
          className="text-rose-600"
        >
          نسيت الكلمه السر
        </p>
        <p onClick={() => modelType("login")} className="text-rose-600">
          تسجيل دخول
        </p>
      </div>
    </>
  );
};

const ForgetPasswordCard = ({ modelType }) => {
  const [phone, setPhone] = useState("");
  const { user, setUser } = useContext(AuthContext);

  const [validation, setValidation] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const service = new GeneralServices();

  const handleForgetPassword = async () => {
    console.log("ahmed hmed");
    setValidation([]);
    // return;
    setLoading(true);
    try {
      const res = await service.post("forget-password", {
        phone: `966${phone}`,
      });
      console.log("ahmed hmed");
      // save user in local storage
      localStorage.setItem("user", JSON.stringify(res));
      setUser(res);
      modelType("otp");
    } catch (error) {
      console.log("error", error);
      if (error.validationData) {
        setValidation(error.validationData);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <p>نسيت كلمه السر</p>
      <Input
        error={validation?.phone?.[0]}
        placeholder="000 000 0000"
        onChange={(e) => setPhone(e.target.value)}
        type="number"
        align={"text-right"}
        prefix={
          <div className=" flex items-center justify-center gap-2">
            <p className="text-gray-600 text-md">966</p>
            <img
              className="w-6 rounded-lg"
              src="https://media.istockphoto.com/id/847376560/photo/saudi-arabian-flag-painted-over-brick-wall.jpg?s=170667a&w=0&k=20&c=ec2QOltIFOdha2dxAkpFG_Vwsiln2VrhWFPPkFFEUeU="
            />
          </div>
        }
      />

      <Button
        disabled={!phone || isLoading}
        loading={isLoading}
        onClick={handleForgetPassword}
        text="ارسال"
      />
      <div className="flex items-center justify-between text-sm group mt-4 cursor-pointer gap-4">
        <p onClick={() => modelType("register")} className="text-rose-600">
          انشاء حساب
        </p>
        <p onClick={() => modelType("login")} className="text-rose-600">
          تسجيل دخول
        </p>
      </div>
    </>
  );
};
const OtpCard = ({ modelType }) => {
  const [otp, setOtp] = React.useState();
  const { user, setUser } = useContext(AuthContext);
  const [validation, setValidation] = useState([]);
  const [loading, setLoading] = useState(false);

  const service = new GeneralServices();

  const handleOtp = async () => {
    setValidation([]);

    setLoading(true);
    try {
      const res = await service.post("otp", { otp });
      localStorage.setItem("user", JSON.stringify(res));
      setUser(res);
    } catch (error) {
      if (error.validationData) {
        setValidation(error.validationData);
      } else {
        showErrorToast(error.message);
        console.log("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Input
        placeholder="كود التاكيد"
        type="number"
        onChange={(e) => setOtp(e.target.value)}
        error={validation?.otp?.[0]}
      />
      <Button loading={loading} onClick={handleOtp} text="تاكيد الكود" />
    </>
  );
};

const UserInfo = () => {
  const { user } = useContext(AuthContext);
  return (
    <div className="bg-white p-6 shadow-sm">
      <div className="flex flex-col items-center text-center mb-4">
        <div className="w-16 h-16 bg-gray-300 rounded-full mr-4 relative">
          <div className="absolute -top-1 -right-1 w-5 h-5 bg-yellow-400 rounded-full flex items-center justify-center">
            <Award size={16} className="text-white" />
          </div>
        </div>
        <div>
          <h2 className="text-md font-bold">
            {user?.user?.name ?? "اسم العميل "}
          </h2>
          <p className="text-gray-600 text-sm">{user?.user?.phone}</p>
        </div>
        {/* <div className="bg-yellow-400 flex items-center justify-center   text-white px-4 py-1 rounded-full text-sm font-bold">
          <p>عميل ذهبي</p>
        </div> */}
      </div>
    </div>
  );
};

const MenuItem = ({ icon, title, subtitle, chevron, link, onClick }) => (
  <Link
    onClick={onClick}
    to={link}
    className="flex items-center justify-between py-4 border-b border-gray-200"
  >
    <div className="flex items-center">
      {icon}
      <div className="mr-4">
        <h3 className="font-bold">{title}</h3>
        {subtitle && <p className="text-sm text-gray-600">{subtitle}</p>}
      </div>
    </div>
    {chevron && <ChevronLeft size={20} className="text-gray-400" />}
  </Link>
);

const MenuItems = () => {
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const service = new GeneralServices();

  const logout = async () => {
    try {
      const res = await service.post("logout");
      setUser(null);
      localStorage.removeItem("user");
      navigate("/");
    } catch (error) {
      if (error.state == 401) {
        setUser(null);
        localStorage.removeItem("user");
        // console.log("user", user);
      }
    }
  };

  return (
    <div className="p-6">
      <div className="z-100">
        <BottomSheet
          title="تحديث البيانات"
          postion="center"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <UpdateProfile modelType={() => setIsOpen(false)} />
        </BottomSheet>
      </div>
      <MenuItem
        link="/orders"
        icon={<ShoppingBag size={24} className="text-gray-600" />}
        title="الطلبات"
        chevron
      />
      {/* <MenuItem icon={<User size={24} className="text-gray-600" />} title="البيانات الشخصية" chevron /> */}
      <MenuItem
        icon={<Award size={24} className="text-green-500" />}
        title="نقاط حسابي"
        subtitle="126"
        chevron
      />
      {/* <MenuItem icon={<ShoppingBag size={24} className="text-blue-500" />} title="شراء رصيد للحساب" subtitle="20 ريال" chevron /> */}
      <MenuItem
        icon={<AlertCircle size={24} className="text-gray-600" />}
        title="عناويني"
        chevron
        link={"/address"}
      />
      {/* <MenuItem icon={<Globe size={24} className="text-gray-600" />} title="لغة التطبيق" chevron /> */}
      <MenuItem
        onClick={() => setIsOpen(true)}
        icon={<User size={24} className="text-gray-600" />}
        title="تحديث البيانات"
        chevron
      />
      <MenuItem
        icon={<AlertCircle size={24} className="text-gray-600" />}
        link={"/about"}
        title="عن سهول"
        chevron
      />
      <MenuItem
        icon={<Lock size={24} className="text-gray-600" />}
        link={"/privacy"}
        title="الخصوصية"
        chevron
      />
      {/* <MenuItem icon={<Phone size={24} className="text-gray-600" />} title="تحديث رقم الجوال" chevron /> */}
      {/* <MenuItem icon={<LogOut size={24} className="text-red-500" />} title="إلغاء الاشتراك" /> */}
      <MenuItem
        onClick={() => logout()}
        icon={<LogOut size={24} className="text-orange-500" />}
        title="تسجيل الخروج"
      />
      <div className="h-[100px]"></div>
    </div>
  );
};

const FooterIcon = ({ icon, label, active }) => (
  <div
    className={`flex flex-col items-center ${
      active ? "text-pink-500" : "text-gray-500"
    }`}
  >
    {icon}
    <span className="text-xs mt-1">{label}</span>
  </div>
);

export default UserProfileMobile;
