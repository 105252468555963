import { Trash2 } from "lucide-react";
import React, { useEffect } from "react";

const OptionTable = ({ options, setOptions }) => {
  useEffect(() => {
    console.log(options);
  }, [options]);
  return (
    <div className="mt-4 border-t pt-4 border-dashed">
      <table className="w-full text-start">
        <thead>
          <tr>
            <th>الاسم</th>
            <th>السعر</th>
            <th>حذف</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option) => (
            <tr key={option.id}>
              <td>{option.name}</td>
              <td>{option.price}</td>
              <td>
                <Trash2
                  size={16}
                  className="text-red-500 cursor-pointer"
                  onClick={() =>
                    setOptions(options.filter((o) => o.id !== option.id))
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OptionTable;
