import { Menu, Search } from "lucide-react";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContextProvider";

function Header() {
  // show this im bile only

  const { title } = useContext(AuthContext);
  return (
    <header className="bg-rose-500 text-white p-2 mb-[5px] flex justify-between items-center header-mobile">
      <Search size={24} />
      <h1 className="text-lg font-bold">{title || "حسابك"}</h1>
      <Menu size={24} />
    </header>
  );
}

export default Header;
