import React, { useContext, useEffect, useState } from "react";
import { AlertCircle } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import GeneralServices from "../services/GeneralServices";
import { AuthContext } from "../contexts/AuthContextProvider";

let UnpaidOrder = null;

const UnpaidOrderAlert = () => {
  const location = useLocation();
  const service = new GeneralServices();
  const { user } = useContext(AuthContext);
  const [isNotificationSupported, setIsNotificationSupported] = useState(false);

  useEffect(() => {
    // التحقق من دعم الإشعارات
    if ("Notification" in window) {
      setIsNotificationSupported(true);
    }

    // التحقق من دعم خدمة العامل
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log("Service Worker registered: ", registration);
        })
        .catch((error) => {
          console.log("Service Worker registration failed: ", error);
        });
    }
  }, []);

  const subscribeToPush = async (data) => {
    try {
      const res = await service.post("subscribeNotfication", data);
      console.log("subscribeToPush res", res);
      // reload page
      window.location.reload();
    } catch (error) {
      console.log("subscribeToPush error", error);
    }
  };

  const enableNotification = async () => {
    if (!isNotificationSupported) {
      console.log("Notifications are not supported on this browser");
      return;
    }

    const permission = await Notification.requestPermission();
    console.log("permission", permission);
    if (permission === "granted") {
      // التحقق من دعم pushManager
      if ("PushManager" in window) {
        navigator.serviceWorker.ready.then((sw) => {
          sw.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey:
                "BHSJvcqiami9cSYGiV-1fTFPl2h5JyqzHPf1sLj832jG_jgLBAp8Pa_MZQDT8kqgedJi7_b7t235u4RBiFEFCb8",
            })
            .then((subscription) => {
              subscribeToPush(subscription);
              console.log("Subscribed:", subscription);
            })
            .catch((error) => {
              console.error("Subscription failed:", error);
            });
        });
      } else {
        console.log("Push notifications are not supported on this browser");
      }
    }
  };

  useEffect(() => {
    if (
      user &&
      isNotificationSupported &&
      Notification.permission !== "granted"
    ) {
      enableNotification();
    }
  }, [user, isNotificationSupported]);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    });
  }, []);

  const handleInstallClick = () => {
    // hide the deferred prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      {isNotificationSupported &&
        Notification.permission !== "granted" &&
        user && (
          <div>
            <button
              onClick={enableNotification}
              className="bg-red-600 text-white p-2"
            >
              تفعيل الإشعارات
            </button>
          </div>
        )}
      {UnpaidOrder && (
        <Link
          to={`/order-details/${UnpaidOrder}`}
          className="bg-red-600 text-white p-4 shadow-md mb-[1px] flex items-center justify-between"
        >
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span className="text-sm font-semibold">يوجد طلب لم يتم دفعه.</span>
          </div>
          <p className="text-sm font-medium underline hover:text-red-200 transition-colors duration-200">
            اذهب للتفاصيل
          </p>
        </Link>
      )}
    </>
  );
};

export default UnpaidOrderAlert;

// import React, { useContext, useEffect, useState } from "react";
// import { AlertCircle } from "lucide-react";
// import { Link, useLocation } from "react-router-dom";
// import GeneralServices from "../services/GeneralServices";
// import { AuthContext } from "../contexts/AuthContextProvider";

// let UnpaidOrder = null;
// const UnpaidOrderAlert = () => {
//   const location = useLocation();
//   const service = new GeneralServices();

//   const { user } = useContext(AuthContext);

//   useEffect(() => {
//     // console.log("user", user);
//     // register service worker
//     if ("serviceWorker" in navigator) {
//       navigator.serviceWorker
//         .register("/service-worker.js")
//         .then((registration) => {
//           // console.log("Service Worker registered: ", registration);
//         })
//         .catch((error) => {
//           console.log("Service Worker registration failed: ", error);
//         });
//     }
//   }, []);

//   const subscribeToPush = async (data) => {
//     try {
//       const res = await service.post("subscribeNotfication", data);
//       console.log("subscribeToPush res", res);
//     } catch (error) {
//       console.log("subscribeToPush error", error);
//     }
//   };

//   // enableNotification function
//   const enableNotification = async () => {
//     const permission = await Notification.requestPermission();
//     console.log("permission", permission);

//     if (permission === "granted") {
//       navigator.serviceWorker.ready.then((sw) => {
//         sw.pushManager
//           .subscribe({
//             userVisibleOnly: true,
//             applicationServerKey:
//               "BHSJvcqiami9cSYGiV-1fTFPl2h5JyqzHPf1sLj832jG_jgLBAp8Pa_MZQDT8kqgedJi7_b7t235u4RBiFEFCb8", // {{ edit_1 }}: Add your VAPID key here
//           })
//           .then((subscription) => {
//             subscribeToPush(subscription);
//             // console.log("Subscribed:", subscription); // {{ edit_2 }}: Log the subscription details
//           })
//           .catch((error) => {
//             console.error("Subscription failed:", error); // {{ edit_3 }}: Handle subscription errors
//           });
//       });
//     }
//   };

//   useEffect(() => {
//     // if user is logged in and notification permission not granted call enableNotification
//     if (user && Notification.permission !== "granted") {
//       enableNotification();
//     }
//   }, []);

//   // if permission is granted return null
//   if (Notification.permission === "granted") {
//     // return null;
//   }

//   const [deferredPrompt, setDeferredPrompt] = useState(null);

//   useEffect(() => {
//     window.addEventListener("beforeinstallprompt", (event) => {
//       event.preventDefault();
//       setDeferredPrompt(event);
//     });
//   }, []);

//   const handleInstallClick = () => {
//     if (deferredPrompt) {
//       deferredPrompt.prompt();
//       deferredPrompt.userChoice.then((choiceResult) => {
//         if (choiceResult.outcome === "accepted") {
//           console.log("User accepted the install prompt");
//         } else {
//           console.log("User dismissed the install prompt");
//         }
//         setDeferredPrompt(null);
//       });
//     }
//   };

//   return (
//     <>
//       {Notification.permission !== "granted" && user && (
//         <div>
//           <button
//             onClick={enableNotification}
//             className="bg-red-600 text-white p-2"
//           >
//             تفعيل الاشعارات
//           </button>
//         </div>
//       )}
//       {/* <button onClick={handleInstallClick}>Install PWA</button>; */}
//     </>
//   );
//   return (
//     <Link
//       to={`/order-details/${UnpaidOrder}`}
//       className="bg-red-600 text-white p-4 shadow-md mb-[1px] flex items-center justify-between"
//     >
//       <div className="flex items-center">
//         <AlertCircle className="h-5 w-5 mr-2" />
//         <span className="text-sm font-semibold">يوجد طلب لم يتم دفعه.</span>
//       </div>

//       <p className="text-sm font-medium underline hover:text-red-200 transition-colors duration-200">
//         اذهب للتفاصيل
//       </p>
//     </Link>
//   );
// };

// export default UnpaidOrderAlert;
