import React, { useState } from "react";
import useFetchData from "../../../hooks/useFetchData";
import BottomSheet from "../../../components/mobile/BottomSheet";
import Input from "../../../components/Input";
import useSubmitData from "../../../hooks/useSubmitData";

const Invoices = () => {
  const { data: receipts, refetch, isLoading } = useFetchData("/receipts");
  const [showReceipt, setShowReceipt] = useState(false);

  const { submit: submitOrder, resetData } = useSubmitData("/orders/pay");

  const [order, setOrder] = useState(null);
  const [payments, setPayments] = useState([]);

  const ToggleModal = (order = null) => {
    setOrder(order);
    setShowReceipt(!showReceipt);
  };

  // get payment/receipt
  const { data: paymentReceipts, isLoading: isLoadingReceipts } =
    useFetchData("payment/receipt");

  const [paymentsList, setPaymentsList] = useState([]);

  const totalPaid = payments.reduce(
    (acc, curr) => acc + Number(curr.amount || 0),
    0
  );

  const remaining = order?.total - totalPaid;

  const isPaymentComplete = Math.abs(remaining) < 0.01; // للتعامل مع أخطاء التقريب العشري

  const getRemainingColor = () => {
    if (remaining < 0) return "text-red-500";
    if (remaining > 0) return "text-black";
    return "text-green-500";
  };

  const handlePayment = async () => {
    try {
      const res = await submitOrder();
      console.log(res);

      //   window.open(`http://test.sehoool.com/invoice/${res.id}`, "_blank");
      //   resetData();
      //   setPayments([]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" bg-black">
      {/* جدول اسم العميل الرقم البريد */}
      <div className="flex flex-col bg-white">
        <div className="flex bg-gray-50 p-2">
          <h3 className="text-lg font-bold">الفواتير</h3>
        </div>

        <table className="w-full text-start">
          <thead>
            <tr>
              <th className="py-2">الاسم</th>
              <th className="py-2">الرقم</th>
              <th className="py-2">البريد</th>
              <th className="py-2">المجموع</th>
              <th className="py-2">التاريخ</th>
              <th className="py-2">الدفع</th>
            </tr>
          </thead>
          <tbody>
            {receipts.map((receipt) => (
              <tr
                key={receipt?.id}
                className="border-b border-black hover:bg-gray-100 text-start"
              >
                <td className="py-3 px-6">{receipt?.user?.name}</td>
                <td className="py-3 px-6">{receipt?.user?.phone}</td>
                <td className="py-3 px-6">{receipt?.total.toFixed(2)} ريال</td>
                <td className="py-3 px-6">#{receipt?.id}</td>
                <td className="py-3 px-6">
                  {new Date(receipt?.created_at).toLocaleDateString()}
                </td>
                <td className="py-3 px-6">
                  <button
                    onClick={() => ToggleModal(receipt)}
                    className="bg-rose-500 text-white px-4 py-2 rounded-md"
                  >
                    دفع
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <BottomSheet
        isOpen={showReceipt}
        postion="center"
        onClose={ToggleModal}
        title="الفواتير"
      >
        <div className="flex flex-col">
          <h3 className="text-lg font-bold">الفواتير</h3>
        </div>

        <div className="flex flex-col space-y-4">
          <div className="flex gap-4 items-center justify-around font-bold text-2xl">
            <span
              className={`text-black cursor-pointer bg-gray-100 rounded-full  px-6 py-2 text-[26px] text-center`}
            >
              المجموع :
              {order?.total.toLocaleString("en-EG", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span
              className={`cursor-pointer bg-gray-100 rounded-full px-6 py-2 text-[40px] text-center ${getRemainingColor()}`}
            >
              المتبقى :{remaining.toFixed(2)}
            </span>
            <span
              className={`text-black cursor-pointer bg-gray-100 rounded-full  px-6 py-2 text-[26px] text-center`}
            >
              المدفوع :{totalPaid.toFixed(2)}
            </span>
          </div>

          <div className="flex flex-col space-y-4">
            {paymentReceipts.map((receipt) => (
              <div
                key={receipt.id}
                className="flex flex-col space-y-2 border-b border-gray-200 pb-4"
              >
                <div className="flex items-center justify-between">
                  <span className="text-gray-400 w-[100px] text-start">
                    {receipt.name}
                  </span>
                  <div className="flex items-center gap-2 flex-grow">
                    <Input
                      onChange={(event) => {
                        const newAmount = Number(event.target.value);
                        setPayments((prev) => {
                          const existingPaymentIndex = prev.findIndex(
                            (payment) => payment.id === receipt.id
                          );
                          if (existingPaymentIndex !== -1) {
                            // Update existing payment
                            const updatedList = [...prev];
                            updatedList[existingPaymentIndex].amount =
                              newAmount;
                            return updatedList;
                          } else {
                            // Add new payment
                            return [
                              ...prev,
                              { id: receipt.id, amount: newAmount },
                            ];
                          }
                        });
                      }}
                      type="number"
                      className="text-2xl"
                      value={
                        payments.find((payment) => payment.id === receipt.id)
                          ?.amount || ""
                      }
                      placeholder={`المبلغ`}
                    />
                    {/* عمل زر عند الضغط عليه يتم اضافة المبلغ المتبقى فى ال Input المقابل له فى الصف        */}
                    <button
                      disabled={
                        payments.find((payment) => payment.id === receipt.id)
                          ?.amount || ""
                      }
                      onClick={() => {
                        setPayments((prev) => {
                          const updatedList = [...prev];
                          updatedList.push({
                            id: receipt.id,
                            amount: remaining,
                          });
                          return updatedList;
                        });
                      }}
                      className={`${
                        payments.find((payment) => payment.id === receipt.id)
                          ?.amount || remaining === 0
                          ? "bg-gray-300 text-gray-500"
                          : "bg-blue-500 text-white"
                      } bg-blue-500 text-white  flex items-center justify-center py-2 text-center rounded-md`}
                    >
                      <p className=" p-1 ">
                        سداد {remaining.toFixed(2)} {receipt.name}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            className={`rounded-md px-4 py-2 h-[100px] font-bold text-2xl ${
              isPaymentComplete
                ? "bg-rose-500 text-white cursor-pointer"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            disabled={!isPaymentComplete}
            onClick={() => {
              if (isPaymentComplete) {
                // قم بتنفيذ عملية الدفع هنا
                console.log("تم الدفع");
                handlePayment();
                // ToggleModal();
              }
            }}
          >
            دفع
          </button>
          <button className="text-yellow-400" onClick={() => setPayments([])}>
            تصفير المبلغ
          </button>
        </div>
      </BottomSheet>
    </div>
  );
};

export default Invoices;
