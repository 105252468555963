import { Circle, CircleX, CloudRain, PowerOff } from 'lucide-react';
import React, { useState } from 'react';

// const BottomSheet = ({ isOpen, onClose, children, title = 'الخيارات', mb = '80', postion = 'end' }) => {
const BottomSheet = ({ isOpen, onClose, children, title = 'الخيارات', mb = '80', postion = 'end', isTitle = true }) => {
    return (
        <>
            {isOpen && (
                <div className={`fixed  ${postion === 'center' ? 'px-[5%]' : ''}  inset-0 flex items-${postion} justify-center mb-[${mb}px] z-50 `} style={{ backdropFilter: 'blur(4px)' }}>
                    <div className={`fixed inset-0 bg-black opacity-50 `} onClick={onClose}></div>
                    <div className={`bg-white w-full  rounded-t-lg ${postion === 'center' ? 'rounded-b-lg' : ''} p-4 shadow-lg transform transition-transform duration-3000 ease-in-out`}>
                        {isTitle ? <div className={`flex justify-between items-center border-b pb-1 mb-4 `}>
                            <h1 className="text-md font-medium">{title}</h1>
                            <button onClick={onClose}>اغلاق</button>
                        </div> : <div className="flex items-end ">
                            <div onClick={onClose}>
                                {/* <CircleX className='w-6 h-6 text-red-500' /> */}
                            </div>
                        </div>}
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default BottomSheet;
