import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const SideModal = ({ title, children, onClose, size = null }) => {
    const modalRef = useRef();

    const handleOutsideClick = (event) => {
        if (event.target === modalRef.current) {
            onClose();
        }
    };

    useEffect(() => {


        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgba(0, 0, 0, 0.2)', flexDirection: 'row-reverse' }} ref={modalRef}>


            <div className={'col-sm-12 col-md-3 h-full'} role="document">
                <div className="modal-content  h-full ">
                    <div className="modal-body h-full rounded-none">{children}</div>
                </div>
            </div>
        </div>
    );
};

SideModal.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.string, // Add PropTypes validation for size if needed
};

export default SideModal;
