import React, { useEffect, useState } from "react";
import { Trash2 } from "lucide-react";
import Input from "../../../components/Input";
import useFetchData from "../../../hooks/useFetchData";
import GeneralServices from "../../../services/GeneralServices";

const CartTable = ({
  cart,
  setCart,
  updateQuantity,
  updateWeight,
  removeFromCart,
  updatePrice,
  payType,
  validations,
}) => {
  const { data: cuttingTypes, isLoading } = useFetchData("cutting/types");

  const updateCuttingMethod = (productId, newMethod) => {
    setCart((prevItems) =>
      prevItems.map((item) => {
        if (item.id === productId) {
          return { ...item, cuttingMethod: newMethod };
        }
        return item;
      })
    );
  };

  return (
    <table className="w-full text-start">
      <thead>
        <tr>
          <th className="py-2">الصورة</th>
          <th className="py-2">الاسم</th>
          <th className="py-2">السعر</th>
          <th className="py-2">الكمية</th>
          {payType !== "normal" && <th className="py-2">الوزن</th>}
          <th className="py-2">المجموع</th>
        </tr>
      </thead>
      <tbody>
        {cart.map((item, index) => (
          <tr key={item.id} className="border-b relative">
            <td className="py-2 border-separate">
              <div className="relative">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-12 h-12 object-cover rounded-md"
                />
                <button
                  className="absolute -top-1 right-0 bg-red-500 text-white rounded-full p-1 transform  hover:bg-red-600 transition-colors duration-200"
                  onClick={() => removeFromCart(item.id)}
                  title="حذف"
                >
                  <Trash2 size={14} />
                </button>
              </div>
            </td>
            <td className="py-2 border-separate">
              {item.name}
              <br />
              <select
                className="w-full"
                value={item.cuttingMethod}
                onChange={(e) => updateCuttingMethod(item.id, e.target.value)}
              >
                <option value="">إختيار</option>
                {cuttingTypes.map((cuttingType) => (
                  <option key={cuttingType.id} value={cuttingType.id}>
                    {cuttingType.name}
                  </option>
                ))}
              </select>
              <br />
              <span className="text-red-500">
                {validations[`products.${index}.cuttingMethod`]}
              </span>
            </td>
            <td className="py-2 border-separate">
              <div className="w-20">
                <Input
                  alignment="center"
                  value={item.price}
                  onChange={(e) =>
                    updatePrice(item.id, parseFloat(e.target.value))
                  }
                  type="number"
                  step="0.01"
                  min="0"
                />
              </div>
            </td>
            <td className="py-2 border-separate">
              <div className="w-20">
                <Input
                  alignment="center"
                  onChange={(e) => updateQuantity(item.id, e.target.value)}
                  type="number"
                  value={item.quantity}
                />
              </div>
            </td>
            {payType !== "normal" && (
              <td className="py-2 border-separate">
                <div className="w-20">
                  <Input
                    alignment="center"
                    onChange={(e) =>
                      updateWeight(item.id, parseFloat(e.target.value))
                    }
                    type="number"
                    step="0.1"
                    min="0"
                    value={item.weight}
                  />
                </div>
              </td>
            )}
            <td className="py-2 border-separate">
              {payType !== "normal"
                ? (item.price * item.weight).toFixed(2)
                : (item.price * item.quantity).toFixed(2)}{" "}
              ريال
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CartTable;
