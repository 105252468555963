import { useState, useEffect } from 'react';

const useBreakpoint = (breakpoint = 768) => {
    const [isBelow, setIsBelow] = useState(null);

    useEffect(() => {
        const checkBreakpoint = () => {
            setIsBelow(window.innerWidth < breakpoint);
        };

        // Check on initial load
        checkBreakpoint();

        // Add event listener for window resize
        window.addEventListener('resize', checkBreakpoint);

        // Cleanup
        return () => window.removeEventListener('resize', checkBreakpoint);
    }, [breakpoint]);

    return isBelow;
};

export default useBreakpoint;