import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContextProvider";
import GeneralServices from "../../services/GeneralServices";
import Modal from "../../components/Modal";
import { showErrorToast } from "../../utils/toastUtils";

const Login = ({ onClose, setModelType }) => {
  const [phone, setPhone] = React.useState();
  const [password, setPassword] = React.useState("password");

  const { user, setUser } = useContext(AuthContext);

  const [validation, setValidation] = useState([]);
  const [loading, setLoading] = useState(false);

  const service = new GeneralServices();

  const handleLogIn = async () => {
    setValidation([]);
    setLoading(true);
    // add prfex to phone number
    const phoneWithPrefix = `966${phone}`;
    try {
      const res = await service.post("login", {
        phone: phoneWithPrefix,
        password,
      });

      // save user in local storage
      localStorage.setItem("user", JSON.stringify(res));

      if (res?.user?.otp !== null) {
        setModelType("otp");
      } else {
        onClose();
      }
      setUser(res);
    } catch (error) {
      if (error.validationData) {
        setValidation(error.validationData);
      }
      if (error.state == 401) {
        showErrorToast("عفوا بيانات التسجيل خاطئه");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal onClose={onClose}>
      <div>
        <div className="justify-center items-center flex">
          <h1 className="text-2xl font-bold">تسجيل الدخول</h1>
        </div>
        <div className="flex col my-2">
          <p className="text-gray-500 font-medium">رقم الهاتف</p>
        </div>
        <div className="flex">
          <div className="col">
            <input
              value={phone}
              placeholder="رقم الهاتف"
              type="number"
              onChange={(e) => setPhone(e.target.value)}
              className="border col p-1 rounded text-right font-medium"
            />

            <small id="helpId" class="form-text text-danger">
              {validation?.phone?.[0]}
            </small>
          </div>

          <div>
            <div className="flex items-center border rounded p-1">
              <span className="font-medium">+966</span>
              <img
                className="w-8 rounded mx-1 h-5"
                src="https://www.worldometers.info/img/flags/sa-flag.gif"
              />
            </div>
          </div>
        </div>
        {/* <div className="flex">
          <div className="col">
            <label className="text-gray-500 font-medium">كلمه السر</label>
            <input
              value={password}
              placeholder="كله السر"
              onChange={(e) => setPassword(e.target.value)}
              className="border col p-1 rounded  font-medium"
            />

            <small id="helpId" class="form-text text-danger">
              {validation?.password?.[0]}
            </small>
          </div>
        </div> */}

        <div
          onClick={() => handleLogIn()}
          className={`flex justify-center items-center my-2 border rounded p-1 cursor-pointer ${
            loading ? "bg-gray-300" : "bg-primaryColor"
          } text-white`}
        >
          <span>تسجيل الدخول</span>
        </div>
        {/* <div className="flex justify-between">
          <p
            onClick={() => setModelType("join")}
            className="text-gray-500 font-medium cursor-pointer"
          >
            تسجيل جديد
          </p>
          <p
            onClick={() => setModelType("forget-password")}
            className="text-gray-500 font-medium cursor-pointer"
          >
            نسيت كلمه السر
          </p>
        </div> */}
      </div>
    </Modal>
  );
};

export default Login;
