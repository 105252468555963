import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import { showSuccessToast } from "../../../utils/toastUtils";
import TabbyPromo from "../../../components/TabbyPromo";
import { Minimize, PowerOff } from "lucide-react";

function ProductComponent({ data, col }) {
  // ,"status_order":"active"
  const product = data;

  const { card, setCard } = useContext(AuthContext);

  const handleAddToCard = () => {
    const isProductExist =
      card.products && card.products.some((p) => p.id === product.id);

    if (isProductExist) {
      const updatedProducts = card.products.map((p) =>
        p.id === product.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    } else {
      const updatedCard = {
        ...card,
        products: card.products
          ? [...card.products, { ...product, quantity: 1 }]
          : [{ ...product, quantity: 1 }],
      };
      setCard(updatedCard);
    }

    showSuccessToast("تمت اضافة المنتج");
  };

  useEffect(() => {
    // console.log('data', data)
  }, []);

  return (
    <div class={!col ? `col-md-4 col-lg-3 col-xl-3 col-6 my-2` : `${col} my-2`}>
      {data.status_order != "active" && data.status != "active" && (
        <img className="z-10 absolute right-3" src="/imges/slod.png" />
      )}

      <div class="flex flex-col h-full items-center justify-start mx-auto w-full shadow md:rounded-[20px] rounded-[20px] overflow-hidden">
        <div class="flex flex-col gap-5 items-center justify-start w-full">
          <div class="lg:h-[274px] md:h-[200px] relative w-full">
            <img
              class=" h-[250px] m-auto object-cover md:rounded-[20px] rounded-lg w-full"
              src={data.image}
              loading="lazy"
              alt={data.name}
            />
            <p class="absolute cursor-pointer md:font-medium sm:font-light  text-white bg-rose-500    left-[0]  text-base text-center top-[0] px-2">
              {data.label}
            </p>
          </div>
          <div class="flex flex-col items-center  w-[94%] md:w-full p-2">
            <div class="flex flex-col w-full">
              <Link to={`/products-details/${data.id}`}>
                <p class="md:font-bold sm:text-2xl lg:text-[22px] text-[14px] text-black-900 ">
                  {data.name}
                </p>
              </Link>

              <p class="md:font-bold sm:text-2xl lg:text-[22px] text-[14px] text-black-900 ">
                {/* خيارات التقيسط */}
              </p>
              <TabbyPromo
                productId={data.id}
                currency="SRA"
                price={data.price.toString()}
                installmentsCount={4}
                lang="ar"
                source="product"
                publicKey="YOUR_PUBLIC_API_KEY"
                merchantCode="YOUR_MERCHANT_CODE"
              />
              <div class="flex flex-row items-end justify-between mt-[5px] w-[98%] md:w-full">
                <div class="flex flex-col items-center ">
                  <p class="font-black  sm:text-md md:text-[22px] lg:text-[24px] text-black-900">
                    <span class="text-black-900 text-left">
                      {((data.price || 0) * 1.15).toFixed(0)} ريال
                    </span>
                    <span class="text-black-900 text-left text-base">ريال</span>
                  </p>
                </div>
                <div class="flex flex-row items-center justify-evenly mb-1 mt-3 w-[15%]">
                  <p class="capitalize font-medium font-montserrat text-[13.5px] text-black-900">
                    {data.rating}
                  </p>
                  <img
                    class="h-4 rounded-[50%] w-[17px]"
                    src="https://www.svgrepo.com/show/13695/star.svg"
                    alt="signal_Two"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.status_order !== "active" ? (
        <p class="absolute cursor-pointer gap-3  border-inherit border-blue-500 border-1 bg-yellow-400 rounded-3xl xl:top-[260px] top-[240px] flex items-center justify-center lg:left-[4%] md:left-[6%] shadow-bs px-2">
          {/* <img class="md:h-[22px]  md:w-[22px] h-[15px]  w-[15px]" src="https://img.icons8.com/ios/50/ffffff/plus-math--v1.png" alt="add-to-card" /> */}
          <PowerOff className="text-white w-[15px] h-[15px] " />
          <span class="text-white font-light">نفذت الكميه</span>
        </p>
      ) : (
        <p
          onClick={() => handleAddToCard()}
          class="absolute cursor-pointer border-inherit border-blue-500 border-1 bg-rose-500 rounded-3xl xl:top-[260px]  top-[240px] flex items-center justify-center lg:left-[4%] md:left-[6%] shadow-bs px-2"
        >
          <img
            class="md:h-[22px]  md:w-[22px] h-[15px]  w-[15px]"
            src="https://img.icons8.com/ios/50/ffffff/plus-math--v1.png"
            alt="add-to-card"
          />
          <span class="text-white font-light">اضف</span>
        </p>
      )}
    </div>
  );
}

export default ProductComponent;
