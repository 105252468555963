import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContextProvider';
import Modal from '../Modal';
import Login from '../../pages/Auth/Login';
import OTP from '../../pages/Auth/OTP';
import { useNavigate } from 'react-router-dom';
import NotificationstModel from '../../pages/Orders/NotificationstModel';


function NofticationIcon() {


    const navigator = useNavigate()



    const { user, setUser } = useContext(AuthContext);
    const [modelType, setModelType] = useState(null);

    const [firstRun, setFirstRun] = useState(true)


    const handleNotification = () => {

        setModelType(null)

        if (user) {

            if (user.user.otp === null) {
                setModelType('notification');
            } else {
                setModelType('otp');
            }
        } else {
            setModelType('login');
        }



    }

    const onClose = () => {

        setModelType(null)
    }




    return (
        <>
            {modelType == 'login' && <Login setModelType={setModelType} onClose={onClose} />}
            {modelType == 'otp' && <OTP onClose={onClose} />}
            {modelType == 'notification' && <NotificationstModel onClose={onClose} />}

            <div onClick={handleNotification} className='w-10 mx-1 h-10 cursor-pointer justify-center items-center flex rounded-full bg-gray-200'>
                <img src='/icons/notfication.png' />
            </div>
        </>
    )
}

export default NofticationIcon