import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ProfileIcon from "./profile/ProfileIcon";

import { Bell, Menu, User, Video, ShoppingBag, Grid, Home } from "lucide-react";

import { useMediaQuery } from "react-responsive";

import NofticationIcon from "./profile/NofticationIcon";
import { AuthContext } from "../contexts/AuthContextProvider";
import Header from "./mobile/Header";

function Nav() {
  const { card } = useContext(AuthContext);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? (
    <>
      {/* <Header /> */}
      <MobileNav />
    </>
  ) : (
    <MdLgNav />
  );
}

const MobileNav = () => {
  const { card } = useContext(AuthContext);
  // get url path to shoing active link useHistory()
  const { pathname } = useLocation();

  return (
    <footer className=" border-t mobile-nav fixed bottom-0 bg-white left-0 w-full z-50 border-gray-200 p-4">
      <div class=" mx-auto h-full w-full justify-between flex">
        <FooterIcon
          link="/"
          icon={<Grid size={24} />}
          label="الذبائح"
          active={pathname === "/"}
        />
        <FooterIcon
          link="/list"
          icon={<Home size={24} />}
          label="العروض"
          active={pathname === "/list"}
        />
        <div>
          <div className="relative">
            {card.products.length > 0 && (
              <div className="w-4 h-4 rounded-full bg-rose-700 absolute -right-1 -top-1 text-white text-[10px] flex justify-center items-center">
                {card.products.length}
              </div>
            )}
          </div>
          <FooterIcon
            link="/card"
            icon={<ShoppingBag size={24} />}
            label="السلة"
            active={pathname === "/card"}
          />
        </div>
        <FooterIcon
          link="/videos"
          icon={<Video size={24} />}
          label="فيديو"
          active={pathname === "/videos"}
        />
        <FooterIcon
          link="/profile"
          icon={<User size={24} />}
          label="حسابك"
          active={pathname === "/profile"}
        />
      </div>
    </footer>
  );

  return (
    <>
      <div class="mobile-nav fixed bottom-0  left-0 bg-gray-800 w-full z-10">
        <div class="container mx-auto h-full flex">
          <ul class="nav-list flex justify-around items-center w-full h-full">
            <li class="nav-item">
              <Link to="/" class="nav-link flex flex-col items-center">
                <img
                  width="25"
                  height="25"
                  src="https://img.icons8.com/stickers/100/interior.png"
                  alt="Home"
                />
                <p class="text-white font-light text-center">الرئيسية</p>
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/videos" class="nav-link flex flex-col items-center">
                <img
                  width="25"
                  height="25"
                  src="/icons/video.png"
                  alt="videos"
                />
                <p class="text-white font-light text-center">الفيدوهات</p>
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/orders" class="nav-link flex flex-col items-center">
                <img
                  width="25"
                  height="25"
                  src="https://img.icons8.com/cotton/64/ffffff/invoice.png"
                  alt="Orders"
                />
                <p class="text-white font-light text-center">الطلبات</p>
              </Link>
            </li>

            <ProfileIcon screen={"sm"} />
          </ul>
        </div>
      </div>
      <div className="h-[50px]"></div>
    </>
  );
};

const FooterIcon = ({ icon, label, active, onClick, link = "/" }) => {
  const { pathname } = useLocation();

  if (pathname === link) {
    active = true;
  }
  return (
    <Link
      to={link}
      className={`flex flex-col items-center ${
        active ? "text-rose-500" : "text-gray-500"
      }`}
    >
      <div
        className={`flex flex-col items-center ${
          active ? "text-rose-500" : "text-gray-500"
        }`}
      >
        <div className="mb-1">{icon}</div>
        <span className="text-xs">{label}</span>
      </div>
    </Link>
  );
};

const MdLgNav = () => {
  const { card } = useContext(AuthContext);
  return (
    <div>
      {/* <div className='h-[50px] bg-black'>
                <p className='text-center text-white text-2xl'>سهول</p>
            </div> */}
      <nav className="text-gray-900 flex flex-row items-center justify-between px-4 py-3 container">
        <div className="flex items-center">
          <Link to="/" className="text-2xl font-bold">
            <img className="w-14 h-14" src="/imges/logo.png" alt="Logo" />
          </Link>
          <ul className="flex space-x-2 md:hidden">
            <li className="mx-4">
              <Link to="/">الرئيسية</Link>
            </li>
            <li className="mx-4">
              <Link to="/list">القائمة</Link>
            </li>
            <li className="mx-4">
              <Link to="/orders">الطلبات</Link>
            </li>
            <li className="mx-4">
              <Link to="/videos">فيديو</Link>
            </li>
            <li className="mx-4">
              <Link to="/contact-us">تواصل معنا</Link>
            </li>
            <li className="mx-4">
              <Link to="/about">عن سهول</Link>
            </li>
          </ul>
        </div>
        <ul className="hidden md:flex space-x-2">
          <li className="mx-4">
            <Link to="/">الرئيسية</Link>
          </li>
          <li className="mx-4">
            <Link to="/list">القائمة</Link>
          </li>
          <li className="mx-4">
            <Link to="/orders">الطلبات</Link>
          </li>
          <li className="mx-4">
            <Link to="/videos">فيديو</Link>
          </li>
          <li className="mx-4">
            <Link to="/contact-us">تواصل معنا</Link>
          </li>
          <li className="mx-4">
            <Link to="/about">عن سهول</Link>
          </li>
        </ul>
        <div className="flex items-center">
          <NofticationIcon />
          <Link
            to="/card"
            className="w-10 mx-1 h-10 justify-center items-center flex rounded-full bg-gray-200"
          >
            {card.products.length > 0 && (
              <div className="w-4 h-4 rounded-full bg-rose-700 absolute ml-[30px] mb-[30px] text-white text-[10px] flex justify-center items-center  ">
                {card.products.length}
              </div>
            )}
            <img src="/icons/card.png" alt="Card" />
          </Link>
          <ProfileIcon />
        </div>
      </nav>
    </div>
  );
};

export default Nav;
