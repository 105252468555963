import React, { useEffect, useRef } from "react";
const TabbyPromo = ({
  currency,
  price,
  installmentsCount,
  lang,
  source,
  publicKey,
  merchantCode,
  productId,
  status = false,
}) => {
  const tabbyRef = useRef(null);
  const tabbyPromoId = `TabbyPromo-${productId}`;

  useEffect(() => {
    // Load Tabby script if it's not already loaded
    if (!window.TabbyPromo) {
      const script = document.createElement("script");
      script.src = "https://checkout.tabby.ai/tabby-promo.js";
      script.async = true;
      script.onload = initTabbyPromo;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      initTabbyPromo();
    }
  }, [
    currency,
    price,
    installmentsCount,
    lang,
    source,
    publicKey,
    merchantCode,
    productId,
  ]);

  const initTabbyPromo = () => {
    if (window.TabbyPromo) {
      new window.TabbyPromo({
        selector: `#${tabbyPromoId}`,
        currency,
        price,
        installmentsCount,
        lang,
        source,
        publicKey: "pk_test_0190961d-8a82-b770-bfc2-87e082eed86e",
        merchantCode: "SEHOOOLsau",
      });
    }
  };

  if (!status) {
    return null;
  }

  return null;
  return <div id={tabbyPromoId} ref={tabbyRef}></div>;
};

export default TabbyPromo;
