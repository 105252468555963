// routes/routes.js

import React from "react";
import { Routes, Route } from "react-router-dom";

import NotFound from "../components/NotFound";

import Login from "../pages/Auth/Login";
import HomeLayout from "../layoutes/HomeLayout";
import HomePgae from "../pages/Home/HomePgae";
import AboutPage from "../pages/About/AboutPage";
import OrdersPage from "../pages/Orders/OrdersPage";
import ProductsList from "../pages/List/ProductsList";
import ShoppingCard from "../pages/shopping_card/ShoppingCard";
import ProfileLayout from "../layoutes/ProfileLayout";
import ProfilePage from "../pages/profile/ProfilePage";
import AddressPage from "../pages/address/AddressPage";
import OrderDetails from "../pages/Orders/OrderDetails";
import VideosPage from "../pages/videos/VideosPage";
import ContactUsPage from "../pages/contactUs/ContactUsPage";
import CatogeryPage from "../pages/catogery/CatogeryPage";
import AddressCreate from "../pages/address/AddressCreate";
import WalletPage from "../pages/wallet/WalletPage";
import ProductDetails from "../pages/product-details/ProductDetails";
import PosScreen from "../pages/pos/PosScreen";
import Print from "../pages/Orders/Print";
import PaymentFailedPage from "../pages/Payments/PaymentFailedPage";
import PaymentSuccessPage from "../pages/Payments/PaymentSuccessPage";
import PrivacyPage from "../pages/PrivacyPage";
import NFCReader from "../pages/NFCReader";
import VisaCardReader from "../pages/VisaCardReader";
import AhmedHmed from "../pages/AhmedHmed";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/ahmed-hmed" Component={AhmedHmed} />
      <Route path="/print/:id" Component={Print} />
      <Route Component={HomeLayout}>
        <Route path="/payment-failed/:id?" Component={PaymentFailedPage} />
        <Route path="/payment-success/:id" Component={PaymentSuccessPage} />
        <Route path="/list" Component={HomePgae} />
        <Route path="/videos" Component={VideosPage} />
        <Route path="/about" Component={AboutPage} />
        <Route path="/" Component={ProductsList} />
        <Route path="/orders" Component={OrdersPage} />
        <Route path="/order-details/:id" Component={OrderDetails} />
        <Route path="/contact-us" Component={ContactUsPage} />

        <Route path="/card" Component={ShoppingCard} />
        <Route path="/catogery/:id" Component={CatogeryPage} />

        <Route path="/products-details/:id" Component={ProductDetails} />
        <Route path="/privacy" Component={PrivacyPage} />
      </Route>
      <Route Component={ProfileLayout}>
        <Route path="/profile" Component={ProfilePage} />
        <Route path="/address" Component={AddressPage} />
        <Route path="/address/create" Component={AddressCreate} />
        <Route path="/wallet" Component={WalletPage} />
      </Route>

      <Route path="*" element={<NotFound />} />
      <Route path="/pos" Component={PosScreen} />
      <Route path="/login" Component={Login} />
      <Route path="/nfc" Component={NFCReader} />
      <Route path="/visaCard" Component={VisaCardReader} />
      {/* <Route path="/chat" Component={Chat} /> */}
    </Routes>
  );
};

export default AppRoutes;
