import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSubmitData from "../../../hooks/useSubmitData";
import Payments from "./Payments,";

function PaymentButton({ data, setValidations, resetData }) {
  const [payments, setPayments] = useState([]);
  const [orderData, setOrderData] = useState({});

  const {
    data: order,
    setData,
    errors,
    isLoading,
    submit: submitOrder,
  } = useSubmitData(data, "/pos/checkout");

  useEffect(() => {
    setData({ ...data, payments: payments });
  }, [data, payments]);

  const navigate = useNavigate();

  const handlePayment = async () => {
    setValidations([]);

    try {
      const res = await submitOrder();

      window.open(`http://test.sehoool.com/invoice/${res.id}`, "_blank");
      resetData();
      setPayments([]);
    } catch (error) {
      if (error.validationData) {
        setValidations(error.validationData);
      }
    }
  };
  useEffect(() => {
    console.log("payments", payments);
  }, [payments]);

  return (
    <div className="flex justify-between gap-4">
      <Payments
        isLoading={isLoading}
        total={data.total}
        handlePayment={handlePayment}
        payments={payments}
        setPayments={setPayments}
      />
      <button
        onClick={handlePayment}
        disabled={isLoading}
        className={`w-full bg-blue-500 h-[100px] text-white text-2xl font-bold py-3 rounded-md mt-6 hover:bg-blue-600 transition duration-200 ${
          isLoading ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        اجل
      </button>
    </div>
  );
}

export default PaymentButton;
