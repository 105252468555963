import React from 'react';
import { XCircle, RotateCw, MessageCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import OrderSuccessPage from './PaymentSuccessPage';

const PaymentFailedPage = () => {

    const orderId = '12345';
    const errorMessage = null;
    const defaultMessage = encodeURIComponent(`السلام عليكم،

        أواجه مشكلة في إتمام عملية الدفع لطلبي. تفاصيل المشكلة:
        
        • رقم الطلب: ${orderId || '[رقم الطلب غير متوفر]'}
        • تاريخ المحاولة: ${new Date().toLocaleDateString('ar-SA')}
        • نوع الخطأ: فشل في عملية الدفع
        
        أرجو المساعدة في حل هذه المشكلة. شكراً لكم.`);

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
                <div className="text-center">
                    <div className='flex justify-center items-center'>
                        <p><XCircle className="mx-auto h-16 w-16 text-red-500" /></p>
                    </div>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">عذراً، فشلت عملية الدفع</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        {errorMessage || 'حدث خطأ أثناء معالجة الدفع الخاص بك. لم يتم خصم أي مبلغ من حسابك.'}
                    </p>
                </div>

                {orderId && (
                    <div className="mt-4">
                        <p className="text-sm text-gray-700">رقم الطلب: <span className="font-medium">{orderId}</span></p>
                    </div>
                )}

                <div className="mt-8 space-y-4">
                    <Link
                        to="/checkout"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <RotateCw className="h-5 w-5 mr-2" />
                        حاول الدفع مرة أخرى
                    </Link>

                    <a
                        href={`https://api.whatsapp.com/send?phone=249915903708&text=${defaultMessage}`}
                        className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <MessageCircle className="h-5 w-5 mr-2" />
                        تواصل مع الدعم
                    </a>
                </div>

                <div className="mt-6 text-center">
                    <Link to="/" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                        العودة إلى الصفحة الرئيسية
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PaymentFailedPage;