import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import { showSuccessToast } from "../../../utils/toastUtils";
import { Link } from "react-router-dom";

const ProductHomeLg = ({ data }) => {
  const { card, setCard } = useContext(AuthContext);

  const handleAddToCard = () => {
    const isProductExist =
      card.products && card.products.some((product) => product.id === data.id);

    if (isProductExist) {
      const updatedProducts = card.products.map((product) =>
        product.id === data.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
    } else {
      const updatedCard = {
        ...card,
        products: card.products
          ? [...card.products, { ...data, quantity: 1 }]
          : [{ ...data, quantity: 1 }],
      };
      setCard(updatedCard);
    }

    showSuccessToast("تمت اضافة المنتج");
  };
  const productInCard = card.products.find((product) => product.id === data.id);

  // useEffect(() => {

  //     const productInCard = card.products.find((product) => product.id === data.id);

  // }, [card])

  return (
    <div className={`col-12 p-4 `}>
      <div className=" bg-white h-[800px] inset-[0] justify-center m-auto rounded-[10px] w-full shadow overflow-hidden">
        <div className="flex flex-col gap-4 h-full items-center justify-start mx-auto w-full">
          <div className="h-[600px] relative w-full">
            <img
              className="h-[600px] m-auto object-cover rounded-[10px] w-full"
              // src="/imges/slide-4.jpg"
              src={data?.image ?? "/imges/slide-4.jpg"}
              alt={data.name}
            />
          </div>
          <div className="md:h-32 h-[140px]  w-full p-2">
            <div className=" flex flex-col h-full inset-y-[0]  ">
              <Link
                to={`/products-details/${data?.id}`}
                className="font-bold font-tajawal sm:text-2xl md:text-[26px] text-[28px] text-black-900 "
              >
                {data?.name}
              </Link>
              <p className="font-tajawal mr-0.5 mt-[11px] text-base text-indigo-200  w-full">
                {data?.description.substr(0, 100) +
                  (data?.description.length > 100 ? "..." : "")}
              </p>

              <div className="flex items-baseline justify-between mt-[5px] w-full">
                <p className="capitalize font-black  text-[28px] text-black-900">
                  <span className="text-black-900 text-left">
                    {((data.price || 0) * 1.15).toFixed(0)}
                  </span>
                  <span className="text-black-900 text-left text-base">
                    ريال
                  </span>
                </p>
                <div className=" bottom-[3%] flex flex-row items-center justify-evenly left-[0] w-[13%] ">
                  <p className=" font-medium font-montserrat text-[13.5px] text-black-900">
                    {data?.rating}
                  </p>
                  <img
                    className="h-4 rounded-[50%] w-[17px]"
                    src="/icons/star_rating.png"
                    alt="star_rating"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          className="absolute top-[600px] bg-rose-500 flex h-[60px] cursor-pointer items-center justify-center left-[5%] rounded-[50%] shadow-bs1 w-[60px]"
          onClick={() => handleAddToCard()}
        >
          <p className="font-medium font-tajawal text-white text-center text-3xl">
            +
          </p>
        </p>
      </div>
      <p className="absolute cursor-pointer text-white font-medium font-tajawal h-[38px] leading-[normal] left-[0] rounded-bl-[3px] rounded-br-[3px] rounded-tl-[12px] rounded-tr-[3px] text-base text-center top-[0] w-[76px]">
        7-9 كيلو
      </p>
    </div>
  );
};

ProductHomeLg.defaultProps = {};

export default ProductHomeLg;
