import React, { useState, useEffect } from 'react';

function SlideshowAhmed() {
    const [slideIndex, setSlideIndex] = useState(1);
    const [images, setImages] = useState([
        { src: 'src="https://www.w3schools.com/howto/img_nature_wide.jpg', alt: 'Nature' },
        { src: 'src="https://www.w3schools.com/howto/img_snow_wide.jpg', alt: 'Snow' },
        { src: 'src="https://www.w3schools.com/howto/img_mountains_wide.jpg', alt: 'Mountains' },
    ]);

    useEffect(() => {
        const showSlides = () => {
            let i;
            const slides = document.getElementsByClassName('mySlides');
            const dots = document.getElementsByClassName('dot');

            if (slideIndex > images.length) {
                setSlideIndex(1);
            }
            if (slideIndex < 1) {
                setSlideIndex(images.length);
            }
            if (slides && slides.length > 0) { // Check if slides exist and have elements
                slides[slideIndex - 1].style.display = 'block';
                dots[slideIndex - 1].className += ' active';
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = 'none';
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(' active', '');
            }

            slides[slideIndex - 1].style.display = 'block';
            dots[slideIndex - 1].className += ' active';
        };

        const intervalId = setInterval(showSlides, 1500); // Adjust interval as needed

        return () => clearInterval(intervalId); // Cleanup function for the interval
    }, [slideIndex, images.length]); // Re-run useEffect on slideIndex or images change

    const plusSlides = (n) => {
        setSlideIndex((prevSlideIndex) => (prevSlideIndex + n + images.length) % images.length);
    };

    const currentSlide = (n) => {
        setSlideIndex(n);
    };

    return (
        <div className="slideshow-container">
            {images.map((image, index) => (
                <div key={index} className={`mySlides fade ${slideIndex === index + 1 ? 'active' : ''}`}>
                    <div className="numbertext">{index + 1} / {images.length}</div>
                    <img src={image.src} alt={image.alt} style={{ width: '100%' }} />
                    <div className="text">{image.alt}</div>
                </div>
            ))}

            <a className="prev" onClick={() => plusSlides(-1)}>❮</a>
            <a className="next" onClick={() => plusSlides(1)}>❯</a>

            <div style={{ textAlign: 'center' }}>
                {images.map((image, index) => (
                    <span key={index} className={`dot ${slideIndex === index + 1 ? 'active' : ''}`} onClick={() => currentSlide(index + 1)} />
                ))}
            </div>
        </div>
    );
};


export default SlideshowAhmed;
