import React, { useState, useEffect } from "react";

const NFCReader = () => {
  const [nfcSupported, setNfcSupported] = useState(false);
  const [nfcEnabled, setNfcEnabled] = useState(false);
  const [nfcMessage, setNfcMessage] = useState("");

  useEffect(() => {
    // Check if the browser supports NFC
    if ("NDEFReader" in window) {
      setNfcSupported(true);
    }
  }, []);

  const startNFCReader = async () => {
    if (!nfcSupported) return;

    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      setNfcEnabled(true);
      setNfcMessage(
        "NFC Reader started. Bring an NFC tag close to your device."
      );

      ndef.addEventListener("reading", ({ message, serialNumber }) => {
        for (const record of message.records) {
          setNfcMessage(
            `NFC tag read! Serial: ${serialNumber}, Message: ${record.data}`
          );
        }
      });
    } catch (error) {
      setNfcMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="nfc-reader">
      <h1>NFC Reader</h1>
      {!nfcSupported && <p>NFC is not supported on this device.</p>}
      {nfcSupported && !nfcEnabled && (
        <button onClick={startNFCReader}>Start NFC Reader</button>
      )}
      {nfcEnabled && <p>NFC Reader is active. Scan a tag.</p>}
      <p>{nfcMessage}</p>
    </div>
  );
};

export default NFCReader;
