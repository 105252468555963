class BaseService {
    handleRequestError = (error) => {
        let customError = {
            ExceptionType: 'UnknownError',
            type: 'error',
            state: 'unknown',
            message: 'An unknown error occurred',
        };

        if (error.response) {
            // The request was made and the server responded with a status code
            const status = error.response.status;
            customError.state = status;

            switch (status) {
                case 400:
                    customError.ExceptionType = 'BadRequestError';
                    customError.message = error.response.data.message || 'Bad request';
                    break;
                case 401:
                    customError.ExceptionType = 'UnauthorizedError';
                    customError.message = error.response.data.message || 'Unauthorized';
                    break;
                case 404:
                    customError.ExceptionType = 'NotFoundError';
                    customError.message = 'Data not found';
                    break;
                case 409:
                    customError.ExceptionType = 'ConflictError';
                    customError.message = error.response.data.message || 'Conflict occurred';
                    break;
                case 422:
                    customError.ExceptionType = 'ValidationError';
                    customError.message = 'Validation failed';
                    customError.validationData = error.response.data.errors;
                    break;
                case 500:
                    customError.ExceptionType = 'ServerError';
                    customError.message = error.response.data.message || 'Internal server error occurred';
                    break;
                default:
                    customError.ExceptionType = 'ServerError';
                    customError.message = `Server error with status ${status}`;
            }
        } else if (error.request) {
            // The request was made but no response was received
            customError.ExceptionType = 'NoResponseError';
            customError.message = 'No response received from server';
        } else {
            // Something happened in setting up the request that triggered an error
            customError.ExceptionType = 'RequestSetupError';
            customError.message = error.message || 'Error setting up the request';
        }


        throw customError;
    };
}

export default BaseService;

// class BaseService {
//     handleRequestError = (error) => {
//         if (error.response) {
//             // The request was made and the server responded with a status code
//             // Handle specific status codes here
//             if (error.response.status === 404) {
//                 const customError = {
//                     ExceptionType: 'NotFoundError',
//                     type: 'error',
//                     state: error.response.status,
//                     message: 'Data not found',
//                 };
//                 throw customError;
//             } else if (error.response.status === 422) {
//                 const validationData = error.response.data.errors; // Example: Access validation data
//                 const customError = {
//                     ExceptionType: 'ValidationError',
//                     type: 'error',
//                     state: error.response.status,
//                     message: 'Validation failed',
//                     validationData: validationData, // Include validation data in the error object
//                 };
//                 throw customError;
//             } else if (error.response.status === 401) {
//                 const customError = {
//                     ExceptionType: 'ValidationError',
//                     type: 'error',
//                     state: error.response.status,
//                     message: error.response.data.message,
//                 };
//                 throw customError;
//             }
//             else if (error.response.status === 409) {
//                 const customError = {
//                     ExceptionType: 'Conflict',
//                     type: 'error',
//                     state: error.response.status,
//                     message: error.response.data.message,
//                 };
//                 throw customError;
//             }
//             else if (error.response.status === 400) {
//                 const customError = {
//                     ExceptionType: 'ValidationError',
//                     type: 'error',
//                     state: error.response.status,
//                     message: error.response.data.message,
//                 };
//                 throw customError;
//             }
//             else {
//                 const customError = {
//                     ExceptionType: 'ServerError',
//                     type: 'error',
//                     state: error.response.status,
//                     message: "Server Error",
//                 };
//                 throw customError;
//             }
//         } else if (error.request) {
//             // The request was made but no response was received
//             const customError = {
//                 ExceptionType: 'NoResponseError',
//                 type: 'error',
//                 state: error.response.status,
//                 message: 'No response from server',
//             };
//             throw customError;
//         } else {
//             // Something happened in setting up the request that triggered an error
//             const customError = {
//                 ExceptionType: 'UnknownError',
//                 type: 'error',
//                 state: error.response.status,
//                 message: 'Error occurred',
//             };
//             throw customError;
//         }
//     };

// }

// export default BaseService;