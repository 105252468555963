import React from 'react'

function NotificationstModel({ children, onClose }) {
    const close = () => {
        const model = document.getElementById('model');
        if (model) {
            model.style.display = 'none';
        }
        onClose()
    }

    return (

        <div id='model' className="flex items-center bg-opacity-40 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 bg-black outline-none focus:outline-none">
            <div className="col-md-3 bg-white flex-1 h-screen p-3 overflow-y-scroll">
                <div className='row justify-between items-center '>

                    <h1 className='text-2xl font-black'>الاشعارات</h1>
                    <div onClick={() => close()} className='h-6 w-6 rounded-lg p-1 cursor-pointer bg-gray-600'>
                        <img src='/icons/Close.png' />
                    </div>
                </div>

                <div className='h-52 col rounded row'>
                    <img className='col-4 h-32 rounded-xl' src='https://www.allrecipes.com/thmb/zN81HQ86Fa2rHnkH-KUvUf6pBZE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Cuts-of-Beef-3x2-1-a557f31f8b13462185b4f2c17ab5b746.png' />
                    <div className='col flex-col justify-between'>
                        <h1>اشعار جديد</h1>
                        <p className='text-[#9299c3]'>مرحبا تم ارسال الفيديو الى المكونات الرئيسية</p>
                        <div className='flex items-end'>
                            <p className='text-[#9299c3] text-sm'>قبل 3 ساعات</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );

}

export default NotificationstModel