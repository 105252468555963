import React, { useEffect, useState } from "react";
import GeneralServices from "../../services/GeneralServices";
import PaymentComponent from "../../components/payments/PaymentComponent";
import MoyasarPaymentForm from "../../components/payments/MoyasarPaymentForm";
import { showSuccessToast } from "../../utils/toastUtils";
import { useNavigate } from "react-router-dom";
import MapComponent from "../../components/MapComponent";

function AddressCreate() {
  const navigate = useNavigate();

  const [validation, setValidation] = useState([]);

  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState({
    name: "",
    description: "",
    geolocation: "",
    isDefault: 0,
    location: [],
  });

  const riyadhDivisions = [
    "شمال الرياض",
    "جنوب الرياض",
    "شرق الرياض",
    "غرب الرياض",
  ];

  const service = new GeneralServices();

  const handleSaveAddress = async () => {
    setValidation([]);
    setLoading(true);
    try {
      const res = await service.post("create/address", address);
      showSuccessToast("تم اضافة العنوان بنجاح");
      navigate("/address");
    } catch (error) {
      console.log("error", error);
      if (error?.validationData) {
        setValidation(error.validationData);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAddress({
      ...address,
      location: {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      },
    });
  }, [coordinates]);

  useEffect(() => {
    document.title = "سهول - إضافة عنوان";
  }, []);

  return (
    <div className="max-w-3xl mx-auto my-8 bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="bg-rose-500 text-white p-4">
        <h1 className="text-2xl font-bold">إضافة عنوان جديد</h1>
      </div>

      <div className="p-6">
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="address-name"
          >
            اسم العنوان
          </label>
          <div className="flex items-center border rounded-lg overflow-hidden focus-within:ring-2 focus-within:ring-rose-500">
            <div className="p-2 bg-gray-100">
              <img
                src="/icons/location-icon.png"
                alt="Location"
                className="w-6 h-6"
              />
            </div>
            <input
              id="address-name"
              type="text"
              onChange={(e) => setAddress({ ...address, name: e.target.value })}
              className="w-full p-2 outline-none"
              placeholder="قم بكتابة العنوان"
            />
          </div>
          {validation?.name && (
            <p className="text-red-500 text-xs mt-1">{validation.name[0]}</p>
          )}
        </div>

        <div className="mb-6">
          <div className="flex items-center justify-between bg-gray-100 p-3 rounded-lg">
            <span className="text-gray-700 font-medium">
              ضبط كعنوان افتراضي
            </span>
            <button
              onClick={() =>
                setAddress({
                  ...address,
                  isDefault: address.isDefault === 0 ? 1 : 0,
                })
              }
              className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${
                address.isDefault === 1 ? "bg-rose-500" : ""
              }`}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                  address.isDefault === 1 ? "-translate-x-6" : "translate-x-0"
                }`}
              ></div>
            </button>
          </div>
        </div>

        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="address-description"
          >
            الوصف
          </label>
          <textarea
            id="address-description"
            onChange={(e) =>
              setAddress({ ...address, description: e.target.value })
            }
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500"
            placeholder="أدخل وصفاً للعنوان"
            rows="3"
          >
            {address.description}
          </textarea>
          {validation?.description && (
            <p className="text-red-500 text-xs mt-1">
              {validation.description[0]}
            </p>
          )}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            المنطقة
          </label>
          <div className="grid grid-cols-2 gap-4">
            {riyadhDivisions.map((item) => (
              <div
                key={item}
                onClick={() => setAddress({ ...address, geolocation: item })}
                className={`p-3 cursor-pointer rounded-lg border ${
                  address.geolocation === item
                    ? "bg-rose-100 border-rose-500"
                    : "hover:bg-gray-50"
                }`}
              >
                <div className="flex items-center">
                  <div
                    className={`w-4 h-4 rounded-full mr-2 ${
                      address.geolocation === item
                        ? "bg-rose-500"
                        : "border-2 border-gray-300"
                    }`}
                  />
                  <span>{item}</span>
                </div>
              </div>
            ))}
          </div>
          {validation?.geolocation && (
            <p className="text-red-500 text-xs mt-1">
              {validation.geolocation[0]}
            </p>
          )}
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            الموقع على الخريطة
          </label>
          <div className="border rounded-lg overflow-hidden">
            <MapComponent setCoordinates={setCoordinates} />
          </div>
          {/* إضافة رسالة توضيحية */}
          <p className="text-sm text-gray-600 mt-2">
            يمكنك تحديد موقعك الحالي بدقة باستخدام الزر اعلاه أو يمكنك تحديد
            الموقع يدوياً على الخريطة. بعد تحديد الموقع قم بحفظ العنوان
          </p>
        </div>

        <div className="flex justify-center mt-8">
          <button
            onClick={handleSaveAddress}
            disabled={loading}
            className="w-full max-w-xs px-6 py-3 bg-gradient-to-r from-rose-500 to-pink-500 text-white text-lg font-bold rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 ease-in-out focus:outline-none focus:ring-4 focus:ring-rose-300 disabled:opacity-50 disabled:cursor-not-allowed relative overflow-hidden"
          >
            <span className="relative z-10">
              {loading ? "جارٍ الحفظ..." : "حفظ العنوان"}
            </span>
            <span className="absolute inset-0 h-full w-full bg-white transform scale-x-0 origin-left transition-transform duration-300 ease-out group-hover:scale-x-100"></span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddressCreate;
