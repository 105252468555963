import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import GeneralServices from "../../../services/GeneralServices";
import DefaultAddress from "../components/DefaultAddress";
import PaymentMethods from "../PaymentMethods";
import Coupon from "../components/Coupon";
import {
  calculateTotals,
  formatCurrency,
  resetCard,
} from "../../../utils/utility";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../../utils/toastUtils";
import Button from "../../../components/Button";
import BankTransferPayment from "../components/BankTransferPayment";

function ShoppingCardDesktop() {
  const navigate = useNavigate();
  const { card, setCard } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [cuttingTypes, setCuttingTypes] = useState([]);
  const [validation, setValidation] = useState([]);

  const service = new GeneralServices();

  const fetchOptions = async () => {
    try {
      const res = await service.get("additional/options");
      setOptions(res);
    } catch (error) {}
  };

  const fetchDeliveryTypes = async () => {
    try {
      const res = await service.get("delivery/types");
      setDeliveryTypes(res);
    } catch (error) {}
  };

  const fetchCuttingTypes = async () => {
    try {
      const res = await service.get("cutting/types");
      // push({ id: 0, name: 'Select Cutting Type' })

      setCuttingTypes([{ id: null, name: "اختر طريقة التقطيع" }, ...res]);
    } catch (error) {}
  };

  const ToggleOption = (item) => {
    const isOptionExist =
      card.options && card.options.some((option) => option.id === item.id);

    if (isOptionExist) {
      const updatedOptions = card.options.filter(
        (option) => option.id !== item.id
      );
      setCard((prevCard) => ({ ...prevCard, options: updatedOptions }));
    } else {
      const updatedCard = {
        ...card,
        options: card.options ? [...card.options, item] : [item],
      };
      setCard(updatedCard);
    }
  };

  const handleChoseDeliveryType = (type) => {
    // const updatedCard = { ...card, deliveryType: type };
    // setCard(updatedCard);
    // console.log("type", type);
    // console.log("card.paymentMethod", card.paymentMethod);

    let updatedCard = { ...card, deliveryType: type };
    if (type.id === 6 && card?.paymentMethod == 1) {
      updatedCard.paymentMethod = null;
    }
    setCard(updatedCard);
  };

  // const dispatchOrder = async () => {
  //     setValidation([])
  //     try {
  //         const res = await service.post('shopping/cart', card)
  //         console.log('res', (res.order.payment_data))

  //         if (res.order?.payment_data?.payment_method_id == 4) {
  //             console.log('data', res.order.payment_data)
  //         } else {

  //             console.log('data', res)
  //         }

  //         setCard({
  //             products: [],
  //             options: [],
  //             idDelivery: false,
  //             deliveryTime: null,
  //             deliveryDate: null,
  //             paymentMethod: null,
  //             deliveryType: null,
  //             coupon: null,
  //             total: 0,
  //             optionsTotal: 0,
  //         })
  //         if (res.payment_method == 'credit') {
  //             console.log('data', res.data.source.transaction_url)

  //             // open url in new tab
  //             window.open(res.data.source.transaction_url, '_blank');

  //         }

  //         else if (res.status == 'success') {
  //             showSuccessToast('تم الطلب بنجاح')

  //             const orderId = res.order.id; // Assuming orderId is in the response data

  //             // Navigate to the order details page with the orderId parameter
  //             navigate(`/order-details/${orderId}`);

  //         }
  //         else if (res.status == 'failed') {
  //         }

  //     } catch (error) {
  //         console.log('error', error)
  //         if (error.validationData) {
  //             setValidation(error.validationData)
  //             showErrorToast('يوجد خطاء')
  //         }

  //         if (error.state == 401) {
  //             showErrorToast('يجب عليك تسجيل الدخول')
  //         } else if (error.state == 400) {
  //             showErrorToast(error.message)
  //         }
  //     }
  // }

  const dispatchOrder = async () => {
    setValidation([]);
    setIsLoading(true);

    try {
      const res = await service.post("shopping/cart", card);

      if (isPaymentMethodFour(res.order)) {
        handlePaymentMethodFourResponse(res.order);
      } else if (
        res.order.payment_method_id === 2 ||
        res.order.payment_method_id === 6
      ) {
        handleSuccessfulPayment(res.order.id, res.transaction_url);
      } else {
        setCard(resetCard());
        showSuccessToast(res.massage || "تم الطلب بنجاح");
      }

      setCard(resetCard());

      showSuccessToast("تم الطلب بنجاح");
    } catch (error) {
      console.log("error", error);
      if (error.validationData) {
        setValidation(error.validationData);

        const validationData = error.validationData;

        if (validationData?.deliveryType) {
          showErrorToast(validationData.deliveryType?.[0]);
        } else if (validationData?.paymentMethod) {
          showErrorToast(validationData.paymentMethod?.[0]);
        } else if (validationData?.deliveryDate) {
          showErrorToast(validationData.deliveryDate?.[0]);
        } else if (validationData["products.0.cuttingType"]) {
          showErrorToast(validationData?.["products.0.cuttingType"][0]);
        } else {
          showErrorToast("يوجد خطاء");
        }
      }

      if (error.state == 401) {
        showErrorToast("يجب عليك تسجيل الدخول");
      } else if (error.state == 400) {
        showErrorToast(error.message);
      } else {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isPaymentMethodFour = (order) => {
    return order?.payment_method_id === 4;
  };

  const handlePaymentMethodFourResponse = (order) => {
    const paymentData = order.payment_data;
    if (paymentData) {
      if (paymentData.status === "rejected") {
        handleRejectedPayment(paymentData);
      } else {
        const webUrl = findWebUrl(paymentData);
        if (webUrl) {
          handleSuccessfulPayment(order.id, webUrl);
        } else {
          showWarnings(paymentData.warnings);
        }
      }
    } else {
      showErrorToast("حدث خطأ في معالجة الدفع");
    }
  };

  const findWebUrl = (paymentData) => {
    if (
      paymentData.configuration &&
      paymentData.configuration.available_products &&
      paymentData.configuration.available_products.installments &&
      paymentData.configuration.available_products.installments.length > 0
    ) {
      return paymentData.configuration.available_products.installments[0]
        .web_url;
    }
    return null;
  };

  const handleSuccessfulPayment = (orderId, webUrl) => {
    localStorage.setItem("unpaidOrder", orderId);
    window.open(webUrl, "_blank");
  };

  const handleRejectedPayment = (paymentData) => {
    const rejectionReason =
      paymentData.rejection_reason_code || "unknown_reason";
    const rejectionMessages = {
      not_enough_limit:
        "عذراً، ما عندك حد ائتماني كافي لإتمام هالشراء. ياليت تختار طريقة دفع ثانية أو تقلل مبلغ الطلب. شكراً لتفهمك!",
      order_amount_too_high:
        "المبلغ المطلوب في الطلب مرتفع جداً. ياليت تقلل مبلغ الطلب أو تختار طريقة دفع ثانية. شكراً لتفهمك!",
      // Add more rejection reasons as needed
      unknown_reason:
        "حدث خطأ غير معروف. ياليت تحاول مرة ثانية أو تتواصل مع الدعم. شكراً لتفهمك!",
    };
    showErrorToast(rejectionMessages[rejectionReason]);
  };

  const showWarnings = (warnings) => {
    if (warnings && warnings.length > 0) {
      const warningMessages = warnings.map((warning) => {
        if (warning.name === "phone") {
          return "الرجاء إدخال رقم الهاتف";
        } else if (warning.name === "email") {
          return "الرجاء إدخال البريد الإلكتروني";
        }
        return warning.message || "تحذير غير معروف";
      });
      showWarningToast(warningMessages.join(". "));
    } else {
      showWarningToast("هناك بعض المشاكل في معلومات الطلب");
    }
  };

  const [coponStatus, setCoponStatus] = useState(null);
  const [copon, setCopon] = useState(null);

  const handleCopon = async () => {
    try {
      const res = await service.post("copon");
      if (res) {
        setCoponStatus("seccess");
      } else {
        setCoponStatus("failed");
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDeliveryTypes();
    fetchOptions();
    fetchCuttingTypes();

    document.title = "سهول - سله المشتريات ";
  }, []);

  useEffect(() => {
    const { productTotal, optionsTotal, total, tax, grandTotal } =
      // calculateTotals(
      //   card.products,
      //   card.options,
      //   card.discountAmount,
      //   card.deliveryType,
      //   card
      // );

      calculateTotals(
        card.products,
        card.options,
        card.discountAmount,
        card.deliveryType,
        card.coupon,
        card
      );
    setCard((prevCart) => ({
      ...prevCart,
      productTotal: productTotal,
      optionsTotal: optionsTotal,
      total: total,
      tax: tax,
      grandTotal: grandTotal,
    }));
  }, [
    card.products,
    card.options,
    card.discountAmount,
    card.deliveryType,
    card.paymentMethod,
  ]);

  if (card.products && card.products.length == 0)
    return (
      <div className="flex flex-col justify-center items-center h-[60vh]">
        <img
          src={"https://cdn-icons-png.flaticon.com/512/11329/11329060.png"}
          alt="empty cart"
          className="w-[200px]"
        />
        <p className="text-3xl text-center font-bold mt-5">
          لاتوجد منتجات قم باضافة منتجات للمشتريات ...
        </p>
      </div>
    );

  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-md-12 p-5">
          <DefaultAddress />
          <div className="items-center mb-4">
            <h1 className="font-semibold text-2xl">
              السله
              <span className="font-light text-sm mx-2 text-stone-400">
                {card.products.length} منتج
              </span>
            </h1>
            <br />
            <span className="text-sm text-red-500">
              {validation?.products?.[0]}
            </span>
          </div>

          {/* products */}
          {card.products.map((product, index) => (
            <ProductCard
              key={index}
              validation={validation[`products.${index}.cuttingType`]}
              data={{ product, card, setCard, cuttingTypes }}
            />
          ))}

          <div className="mt-4  p-3 rounded-2xl shadow">
            <h1 className=" font-semibold text-stone-700">خيارات اضافيه</h1>
            <div>
              {options.map((item, index) => (
                <div className="row justify-between items-center p-2">
                  <h1 className="font-medium text-stone-500">{item.name}</h1>
                  <div
                    onClick={() => ToggleOption(item)}
                    className={`w-6 h-6 rounded border-2 ${
                      card.options &&
                      card.options.some((option) => option.id === item.id)
                        ? "bg-rose-500"
                        : ""
                    } border-rose-500 cursor-pointer`}
                  ></div>
                </div>
              ))}
            </div>
          </div>

          <Coupon />

          {/* <div className=" flex flex-col font-tajawal items-center justify-start mt-[43px] outline outline-[1px] outline-blue_gray-50_02 p-[15px] rounded-[15px] w-full">
                        <div className="flex flex-col items-end justify-start mb-4 w-full">
                            <div className="flex flex-row items-center justify-between w-full">

                                <p className="font-semibold text-gray-900 ">خصم خاص بالجمعيات الخيرية</p>
                            </div>
                            <p className="font-medium  mt-[5px] text-blue_gray-600 text-sm  w-[89%] sm:w-full">
                                سوف يتم توصيل هذا الطلب بمعرفتنا الى (جمعية البر الخيرية) وستصلك رسالة من الجمعية بتأكيد استلام
                                الطلب بعد التوصيل
                            </p>
                        </div>
                    </div> */}
        </div>

        <div className="col-md-12 p-5">
          {/* address */}

          {/* <div onClick={() => setCard({ ...card, idDelivery: !card.idDelivery })} className='row items-center mt-4 cursor-pointer'>


                        <div className='w-6 h-6  rounded'>
                            {card.idDelivery ? <img width="26" height="26" src="https://img.icons8.com/metro/26/check-all.png" alt="checked-2" /> : <img style={{ filter: 'invert(1) sepia(1) saturate(0) hue-rotate(330deg) brightness(0) contrast(100)' }} width="26" height="26" src="https://img.icons8.com/metro/26/uncheck-all.png" alt="checked-2" />}

                        </div>
                        <p className='mx-2 font-medium'>استلام من المحل </p>
                    </div> */}

          <div className="my-3">
            <div>
              <h1 className="font-semibold">خيارات التوصيل</h1>
            </div>

            <div className="row py-2 col-12">
              {deliveryTypes &&
                deliveryTypes.map((type, index) => (
                  <div
                    className={`row items-center my-1 col-md-12 cursor-pointer p-2 ${
                      card?.deliveryType?.id === type.id
                        ? "bg-gray-100 overflow-hidden rounded-lg"
                        : "border rounded"
                    }`}
                    onClick={() => handleChoseDeliveryType(type)}
                    key={index}
                  >
                    <div className="flex row  py-2 col-12">
                      <div>
                        <img className="w-8 h-8 mx-1" src={type.icon} />
                      </div>
                      <div className="col">
                        <p>
                          {type.name}
                          {type.price > 0 && (
                            <span className="mx-1 bg-orange-100 text-orange-500 p-2 rounded-full text-xs">
                              {" "}
                              {type.price} ريال
                            </span>
                          )}
                        </p>
                      </div>
                      {card?.deliveryType?.id === type.id ? (
                        <img
                          className="w-5 h-5 absolute top-[1rem] left-1"
                          src="icons/checkmark.png"
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="my-3">
            <h1 className="font-semibold">تاريخ التوصيل</h1>

            <div className="row py-2">
              <div className="row my-2 col-md-6">
                <img src="icons/calendar.png" />
                {/* <p className='font-medium text-gray-600 mx-1'> 12-12-2023</p> */}

                <input
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    setCard({ ...card, deliveryDate: e.target.value })
                  }
                />
              </div>
              {/* <div className='flex my-2 col-md-6 items-center justify-center'>

                                <div className=''><img src='icons/clock.png' /></div>

                                <SelectFormGroup
                                    options={[
                                        {
                                            id: null,
                                            name: 'اختر زمن التوصيل',
                                        },
                                        {
                                            id: 1,
                                            name: 'الفتره الصباحيه من 8 الى الساعه 1',
                                        },
                                        {
                                            id: 2,
                                            name: 'الفتره الصباحيه من 8 الى الساعه 1',
                                        },
                                    ]}
                                    onChange={(e) => setCard({ ...card, deliveryTime: e.target.value })}
                                    error={validation?.deliveryTime?.[0]}
                                    col={11}
                                />
                            </div> */}
            </div>
          </div>

          <PaymentMethods validation={validation} />

          <div className="my-3">
            <textarea
              placeholder="ملاحظات على الطلب"
              onChange={(e) => setCard({ ...card, note: e.target.value })}
              className="w-full border rounded my-2"
            >
              {card.note}
            </textarea>
          </div>

          <div className=" shadow rounded-2xl p-3 mt-[57px] space-y-3 divide-solid">
            <div className="flex justify-between w-full">
              <p className="font-medium text-stone-500 sm:text-xl">المنتجات</p>
              <p className="font-medium text-2xl md:text-[22px] text-pink-400 sm:text-xl">
                <span className="md:text-[26px] sm:text-2xl text-pink-500   text-[28px] font-bold">
                  {card?.productTotal}
                </span>
                <span className="text-pink-500 mx-1  text-sm font-extralight">
                  ريال
                </span>
              </p>
            </div>

            <div className="flex justify-between w-full">
              <p className="font-medium text-stone-500 sm:text-xl">اضافات</p>
              <p className="font-medium text-2xl md:text-[22px] text-pink-400 sm:text-xl">
                <span className="md:text-[26px] sm:text-2xl text-pink-500   text-[28px] font-bold">
                  {card?.optionsTotal}
                </span>
                <span className="text-pink-500 mx-1  text-sm font-extralight">
                  ريال
                </span>
              </p>
            </div>

            <div className="flex justify-between w-full">
              <p className="font-medium text-stone-500 sm:text-xl">الشحن</p>
              <p className="font-medium text-2xl md:text-[22px] text-pink-400 sm:text-xl">
                <span className="md:text-[26px] sm:text-2xl text-pink-500   text-[28px] font-bold">
                  {card?.deliveryType?.price ?? 0}
                </span>
                <span className="text-pink-500 mx-1  text-sm font-extralight">
                  ريال
                </span>
              </p>
            </div>

            {card.discountAmount > 0 && (
              <div className="flex justify-between w-full">
                <p className="font-medium text-stone-500">الخصومات</p>
                <p className="font-medium text-2xl md:text-[22px] text-pink-400 sm:text-xl">
                  <span className="md:text-[26px] sm:text-2xl text-pink-500   text-[28px] font-bold">
                    {card.discountAmount}
                  </span>
                  <span className="text-pink-500 mx-1  text-sm font-extralight">
                    ريال
                  </span>
                </p>
              </div>
            )}

            {card?.paymentMethodData?.price > 0 && (
              <>
                <div className="flex justify-between w-full">
                  <p className="font-medium text-stone-500 sm:text-xl">
                    رسوم الدفع عند الاستلام
                  </p>
                  <p className="font-medium text-2xl md:text-[22px] text-pink-400 sm:text-xl">
                    <span className="md:text-[26px] sm:text-2xl text-pink-500   text-[28px] font-bold">
                      {card?.paymentMethodData?.price || 0}
                    </span>
                    <span className="text-pink-500 mx-1  text-sm font-extralight">
                      ريال
                    </span>
                  </p>
                </div>
              </>
            )}

            <div className="flex justify-between w-full">
              <p className="font-medium text-stone-500 sm:text-xl">الضريبه</p>
              <p className="font-medium text-2xl md:text-[22px] text-pink-400 sm:text-xl">
                <span className="md:text-[26px] sm:text-2xl text-pink-500   text-[28px] font-bold">
                  {card.tax}
                </span>
                <span className="text-pink-500 mx-1  text-sm font-extralight">
                  ريال
                </span>
              </p>
            </div>

            <div className="flex justify-between w-full border-t-2 py-3 mt-3">
              <p className="capitalize font-bold text-2xl md:text-[22px] text-black-900 sm:text-xl">
                المجموع
              </p>
              <p className="font-medium text-2xl md:text-[22px] text-pink-400 sm:text-xl">
                <span className="md:text-[26px] sm:text-2xl text-pink-500   text-[28px] font-bold">
                  {card.grandTotal}
                </span>
              </p>
            </div>
          </div>

          <BankTransferPayment card={card} />
          <div className="my-3">
            <Button
              onClick={dispatchOrder}
              text={"تأكيد الطلب"}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const RadioButtons2 = () => {
  const [recipient, setRecipient] = useState("me");

  return (
    <div className="flex items-center space-x-4">
      <input
        type="radio"
        id="recipientMe"
        name="recipient"
        value="me"
        className="hidden"
        checked={recipient === "me"}
        onChange={() => setRecipient("me")}
      />
      <label
        htmlFor="recipientMe"
        className={`cursor-pointer ${
          recipient === "me"
            ? "text-rose-500 bg-rose-100"
            : "text-blue-500 bg-blue-100 hover:bg-blue-200"
        } p-2 rounded-full`}
      >
        أنا
      </label>

      <input
        type="radio"
        id="recipientOther"
        name="recipient"
        value="other"
        className="hidden"
        checked={recipient === "other"}
        onChange={() => setRecipient("other")}
      />
      <label
        htmlFor="recipientOther"
        className={`cursor-pointer ${
          recipient === "other"
            ? "text-rose-500 bg-rose-100"
            : "text-blue-500 bg-blue-100 hover:bg-blue-200"
        } p-2 rounded-full`}
      >
        شخص آخر
      </label>
    </div>
  );
};

const RadioButtons = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="flex items-center space-x-4">
      <input
        type="radio"
        id="radio1"
        name="radio-group"
        className="hidden"
        checked={selectedOption === "option1"}
        onChange={() => handleOptionChange("option1")}
      />
      <label
        htmlFor="radio1"
        className={`cursor-pointer ${
          selectedOption === "option1"
            ? "text-rose-500 bg-rose-100"
            : "text-blue-500 bg-blue-100 hover:bg-blue-200"
        } p-2 rounded-full`}
      ></label>

      <input
        type="radio"
        id="radio2"
        name="radio-group"
        className="hidden"
        checked={selectedOption === "option2"}
        onChange={() => handleOptionChange("option2")}
      />
      <label
        htmlFor="radio2"
        className={`cursor-pointer ${
          selectedOption === "option2"
            ? "text-rose-500 bg-rose-100"
            : "text-green-500 bg-green-100 hover:bg-green-200"
        } p-2 rounded-full`}
      >
        Option 2
      </label>
    </div>
  );
};

const ProductCard = ({ data, validation }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that effect runs only once after initial render

  const { product, cuttingTypes, card, setCard } = data;

  const handleIncres = () => {
    const updatedProducts = card.products.map((item) =>
      item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
    );

    setCard({ ...card, products: updatedProducts });
  };

  const handleDecrement = () => {
    const updatedProducts = card.products
      .map((item) =>
        item.id === product.id
          ? { ...item, quantity: Math.max(0, item.quantity - 1) }
          : item
      )
      .filter((item) => item.quantity > 0);

    setCard({ ...card, products: updatedProducts });
  };

  const handleCuttingTypes = (event) => {
    const cuttingType = cuttingTypes.find(
      (type) => type.id == event.target.value
    );

    const updatedProducts = card.products.map((item) =>
      item.id === product.id ? { ...item, cuttingType: cuttingType } : item
    );

    setCard({ ...card, products: updatedProducts });
  };

  const handleDeleteFromCard = () => {
    const productIdToRemove = product.id;

    const updatedProducts = card.products.filter(
      (item) => item.id !== productIdToRemove
    );
    setCard({ ...card, products: updatedProducts });
  };

  return (
    <div
      className={`p-3 my-3 rounded-lg shadow bg-white ${
        validation && "border-rose-500 border-2"
      }`}
    >
      <div className="row">
        {/* 476 */}
        <img
          className={
            windowWidth <= 475 ? "w-full mb-2 rounded" : "h-32 w-36  rounded"
          }
          // src='https://images.unsplash.com/photo-1448907503123-67254d59ca4f?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          src={
            product?.image ??
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQ5BVHgJRJeqFMG_LvNuo3W_xmPgtmNRZ49qOSI4gxsA&s"
          }
        />
        <div className="mx-3 flex-col justify-around col">
          <div className="flex justify-between">
            <h1 className="font-bold">{product.name}</h1>
            <img
              onClick={handleDeleteFromCard}
              class="cursor-pointer"
              src="icons/delete.png"
            />
          </div>
          <div className="flex-1 flex-col h-20" />
          <div className="flex justify-between w-full">
            <h1 className="font-bold text-rose-500">{product.price} ريال</h1>

            <div>
              <button
                onClick={handleDecrement}
                className="w-8 h-8 font-semibold bg-rose-100 rounded text-rose-500"
              >
                -
              </button>
              <input
                className="w-8 h-8 mx-2 rounded border font-semibold text-center"
                value={product.quantity}
              />
              <button
                onClick={handleIncres}
                className="w-8 h-8 font-semibold bg-gray-100 rounded text-gray-500"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <select
        onChange={handleCuttingTypes}
        className="w-full my-2 p-1 border rounded font-medium text-stone-400 bg-white"
      >
        {cuttingTypes.map((item, index) => (
          <option
            className="text-stone-400 hover:text-stone-400"
            selected={product?.cuttingType?.id == item.id}
            value={item.id}
            key={item.id}
          >
            {item.name}
          </option>
        ))}
      </select>
      <small class="form-text text-danger">{validation}</small>
    </div>
  );
};

export default ShoppingCardDesktop;
