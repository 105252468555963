import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import { Link } from "react-router-dom";


const ProductHome = ({ data }) => {

    const { card, setCard } = useContext(AuthContext)




    const handleAddToCard = () => {
        const isProductExist = card.products && card.products.some((product) => product.id === data.id);

        if (isProductExist) {
            const updatedProducts = card.products.map((product) =>
                product.id === data.id ? { ...product, quantity: (product.quantity || 1) + 1 } : product
            );
            setCard((prevCard) => ({ ...prevCard, products: updatedProducts }));
        } else {
            const updatedCard = {
                ...card,
                products: card.products ? [...card.products, { ...data, quantity: 1 }] : [{ ...data, quantity: 1 }],
            };
            setCard(updatedCard);
        }
    };
    const productInCard = card.products.find((product) => product.id === data.id);

    useEffect(() => {

        const productInCard = card.products.find((product) => product.id === data.id);

    }, [card])

    return (
        <div className={`col-md-6 col-sm-12 my-6 p-4 overflow-hidden`}>
            <div className=" bg-white-A700 md:h-[380px] h-[374px] inset-[0] justify-center m-auto rounded-[10px] w-full shadow overflow-hidden">
                <div className="flex flex-col gap-4 h-full items-center justify-start mx-auto w-full">
                    <div className="h-[206px] relative w-full">
                        <img
                            className="h-[206px] m-auto object-cover rounded-[10px] w-full"
                            // src="/imges/slide-4.jpg"
                            src={data.image ?? '/imges/slide-4.jpg'}
                            alt="rectangleTwentyFour"
                        />

                    </div>
                    <div className="md:h-32 h-[140px] relative w-full p-2">

                        <div className="absolute flex flex-col h-full w-full inset-y-[0]  ">
                            <Link to={`/products-details/${data.id}`} className="font-bold font-tajawal sm:text-2xl md:text-[26px] text-[28px] text-black-900 ">
                                {data?.name}
                            </Link>
                            <div className="p-2">
                                <p className="mr-0.5 mt-[11px] text-base text-indigo-200  w-full">

                                    {data?.description.substr(0, 70) + (data?.description.length > 100 ? '...' : '')}
                                </p>
                            </div>


                            <div className="col row  items-baseline justify-between  mt-[5px] w-full">
                                <p className="capitalize font-black flex sm:text-2xl md:text-[26px] text-[28px] text-black-900">
                                    <span className="text-black-900 text-left">{data?.price} </span>
                                    <span className="text-black-900 text-left text-base">ريال</span>
                                </p>
                                <div className="col" />
                                <div className="flex items-center justify-evenly left-[0] w-[13%]">
                                    <p className="capitalize font-medium font-montserrat text-[13.5px] text-black-900">{data.rating}</p>
                                    <img className="h-4 rounded-[50%] w-[17px]" src="/icons/star_rating.png" alt="star_rating" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <button
                    onClick={() => handleAddToCard()}
                    className="absolute bottom-[37%] bg-rose-500 flex h-[60px] items-center justify-center left-[10%] rounded-[50%] shadow-bs1 w-[60px]"

                >
                    <p className="font-medium font-tajawal text-white text-center text-3xl">+</p>
                    {/* <p className="font-medium font-tajawal text-white text-center text-3xl">+</p> */}

                </button>
            </div>
            <p className="absolute cursor-pointer text-white bg-black font-medium font-tajawal leading-[normal] right-[10]  rounded-bl-[10px] px-2  text-base top-6 w-max"

            >
                {data?.label}
            </p>
        </div>

    );
};

ProductHome.defaultProps = {};

export default ProductHome;
